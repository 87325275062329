import { connect } from 'react-redux';
import { ProfilePage } from 'realtor/components/pages/ProfilePages/ProfilePage/ProfilePage';
import { updateClientProfile } from 'realtor/actions';
import { logout } from '../../../allAccess/actions/authorization';

const mapStateToProps = ({ user }) => ({
  userProfile: user.profile,
  updateInProgress: user.updateInProgress,
});

const mapDispatchToProps = {
  updateClientProfile,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
