import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClientListParamsBody from 'realtor/components/layears/ClientCard/ClientListParam/ClientListParamsBody/ClientListParamsBody';
import ClientListParamsHeader from './ClientListParamsHeader/ClientListParamsHeader';

const ClientListParam = ({ classes, filter, paramsList, clientId, onDeleteFilter, key, isOwnerList }) => (
  <Accordion key={key} className={classes.clientParamsBox}>
    <AccordionSummary
      className={classes.clientParamsHeader}
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <ClientListParamsHeader
        classes={classes}
        filter={filter}
        paramsList={paramsList}
        clientId={clientId}
        onDeleteFilter={onDeleteFilter}
        isOwnerList={isOwnerList}
      />
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <ClientListParamsBody classes={classes} filter={filter} paramsList={paramsList} />
    </AccordionDetails>
  </Accordion>
);

ClientListParam.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  paramsList: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  onDeleteFilter: PropTypes.func.isRequired,
  key: PropTypes.number,
  isOwnerList: PropTypes.bool.isRequired,
};

export default ClientListParam;
