import React from 'react';
import PropTypes from 'prop-types';
import RangeTextInput from 'realtor/components/common/RangeTextInput/RangeTextInput';
import i18n from '../../../../../common/i18n';

export const FlooringRangeFilter = ({ onSelect, filter, errors }) => (
  <RangeTextInput
    valueFrom={filter.flooringFrom}
    valueTo={filter.flooringTo}
    nameFrom="flooringFrom"
    nameTo="flooringTo"
    onFieldChange={onSelect}
    label={i18n.t('FLOORING')}
    error={errors.flooring}
    errorFrom={errors.flooringFrom}
    errorTo={errors.flooringTo}
  />
);

FlooringRangeFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
