import React from 'react';
import { Link, Typography } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import badMoodImage from '../../../../../../public/images/bad_mood_smile.png';
import i18n from '../../../../i18n';
import styles from './styles';

const NotFoundPage = ({ classes }) => (
  <div className={classes.emptyStateBox}>
    <div className={classes.emptyStateMarker}>
      <img width="100%" src={badMoodImage} />
    </div>
    <Typography className={classes.notFoundCode}>
      <strong>404</strong>
    </Typography>
    <Typography className={classes.notFoundText}>{i18n.t('NOT_FOUND')}</Typography>
    <Link className={classes.backToMainPage} to="/" component={RouterLink}>
      {i18n.t('GO_TO_MAIN_PAGE')}
    </Link>
  </div>
);

NotFoundPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFoundPage);
