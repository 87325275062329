import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN } from '../../../Config';
var ExchangeRatesNamesEnum;
(function (ExchangeRatesNamesEnum) {
    ExchangeRatesNamesEnum["UAH"] = "UAH";
    ExchangeRatesNamesEnum["USD"] = "USH";
})(ExchangeRatesNamesEnum || (ExchangeRatesNamesEnum = {}));
export var ServerErrorCodesEnum;
(function (ServerErrorCodesEnum) {
    ServerErrorCodesEnum["BLOCKED"] = "BLOCKED";
    ServerErrorCodesEnum["CLIENT_WITH_THIS_MORE_TELEPHONE_NUMBERS_ALREADY_EXIST"] = "CLIENT_WITH_THIS_MORE_TELEPHONE_NUMBERS_ALREADY_EXIST";
    ServerErrorCodesEnum["CLIENT_WITH_THIS_TELEPHONE_NUMBER_ALREADY_EXIST"] = "CLIENT_WITH_THIS_TELEPHONE_NUMBER_ALREADY_EXIST";
    ServerErrorCodesEnum["EMAIL_ALREADY_EXIST"] = "EMAIL_ALREADY_EXIST";
    ServerErrorCodesEnum["INVALID_CONFIRMATION_CODE"] = "INVALID_CONFIRMATION_CODE";
    ServerErrorCodesEnum["INVALID_CONFIRMATION_TOKEN"] = "INVALID_CONFIRMATION_TOKEN";
    ServerErrorCodesEnum["INVALID_CREDENTIALS"] = "INVALID_CREDENTIALS";
    ServerErrorCodesEnum["INVALID_PASSWORD"] = "INVALID_PASSWORD";
    ServerErrorCodesEnum["NOT_APPROVED"] = "NOT_APPROVED";
    ServerErrorCodesEnum["NOT_CONFIRMED"] = "NOT_CONFIRMED";
    ServerErrorCodesEnum["TELEPHONE_NUMBERS_ALREADY_EXIST"] = "TELEPHONE_NUMBERS_ALREADY_EXIST";
    ServerErrorCodesEnum["TELEPHONE_NUMBER_ALREADY_EXIST"] = "TELEPHONE_NUMBER_ALREADY_EXIST";
    ServerErrorCodesEnum["USER_ALREADY_CONFIRMED"] = "USER_ALREADY_CONFIRMED";
    ServerErrorCodesEnum["USER_WITH_THIS_TELEPHONE_NUMBER_NOT_FOUND"] = "USER_WITH_THIS_TELEPHONE_NUMBER_NOT_FOUND";
})(ServerErrorCodesEnum || (ServerErrorCodesEnum = {}));
export const appConfigurationApi = createApi({
    reducerPath: 'appConfiguration',
    baseQuery: fetchBaseQuery({ baseUrl: `${DOMAIN}/v1/app/` }),
    endpoints: (builder) => ({
        getAppConfiguration: builder.query({
            query: () => `params`,
            transformResponse(baseQueryReturnValue) {
                return {
                    errorCodes: baseQueryReturnValue.errorCodes,
                    exchangeRates: baseQueryReturnValue.exchangeRates,
                    citiesList: baseQueryReturnValue.locations,
                    objectFilters: baseQueryReturnValue.objectFilters,
                    isMobile: window.innerWidth < 600,
                };
            },
        }),
    }),
});
export const { useGetAppConfigurationQuery } = appConfigurationApi;
