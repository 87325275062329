import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ClientsList from 'realtor/components/common/ClientsList/ClientList';
import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';
import styles from './styles';
import i18n from '../../../../../../common/i18n';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';
import { FullScreenLoader } from '../../../../../components/FullScreenLoader/FullScreenLoader';

const ORDER_LIST = {
  FROM_NEWEST_TO_OLDER: {
    order: 'created_at',
    dir: 'DESC',
  },
  FROM_OLDER_TO_NEWEST: {
    order: 'created_at',
    dir: 'ASC',
  },
  MY_FIRST: {
    order: 'my_first',
    dir: 'DESC',
  },
};

const ORDER_KEYS = Object.keys(ORDER_LIST);

export const SearchClientPage = (props) => {
  const {
    fetchAllTotalAndList,
    fetchAllClients,
    addToFavorite,
    removeFromFavorite,
    classes,
    getTotalCount,
    inProgress,
    totalCount,
    dropTotalCount,
    favoriteActionInProgress,
    ...otherProps
  } = props;

  const pageTitle = i18n.t('SEARCH_CLIENT_PAGE_TITLE');

  useEffect(
    () => () => {
      dropTotalCount();
    },
    [],
  );

  useEffect(() => {
    setPageTitle(pageTitle);
    getTotalCount();
  }, []);

  if (inProgress || totalCount === null) {
    return <CircularProgressBox />;
  }

  const handleFetchClients = (params) => {
    fetchAllClients(params);
  };

  return (
    <div>
      {favoriteActionInProgress && <FullScreenLoader />}
      <ClientsList
        classes={classes}
        {...otherProps}
        fetchTotalAndList={fetchAllTotalAndList}
        fetchClients={handleFetchClients}
        pageTitle={pageTitle}
        isCreateButtonDisabled
        isOwnerList={false}
        onRemoveFromFavorite={removeFromFavorite}
        onAddToFavorite={addToFavorite}
        orderList={ORDER_LIST}
        orderKeys={ORDER_KEYS}
        totalCount={totalCount}
      />
    </div>
  );
};

SearchClientPage.propTypes = {
  classes: PropTypes.object,
  clientList: PropTypes.array,
  tagsList: PropTypes.array,
  fetchAllClients: PropTypes.func,
  fetchAllTotalAndList: PropTypes.func,
  getTotalCount: PropTypes.func,
  totalListCount: PropTypes.number,
  totalCount: PropTypes.number,
  inProgress: PropTypes.bool,
  listInProgress: PropTypes.bool,
  favoriteActionInProgress: PropTypes.bool,
  onDelete: PropTypes.func,
  onDeleteClientFilter: PropTypes.func,
  paramsList: PropTypes.object,
  fetchTagsList: PropTypes.string.isRequired,
  addToFavorite: PropTypes.func.isRequired,
  removeFromFavorite: PropTypes.func.isRequired,
  dropTotalCount: PropTypes.func.isRequired,
};

export default withStyles(styles)(SearchClientPage);
