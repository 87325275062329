import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import i18n from '../../../../../common/i18n';
import styles from './styles';

export const SimpleSearchTags = ({ onLoadTags, list, isLoading, classes, onSelectTag }) => {
  const renderExistTagsList = () => (
    <InfiniteScroll
      className={classes.tagsInfiniteScrollBox}
      next={onLoadTags}
      hasMore
      dataLength={list.length}
      scrollableTarget="scrollableDiv"
      loader={
        isLoading && (
          <div className="loader" key="loader">
            Loading ...
          </div>
        )
      }
    >
      {list.map((tag, index) => {
        const handleTagSelect = (e) => {
          onSelectTag(tag);
          e.preventDefault();
        };

        const countOfUseView = tag.countOfUse ? `(${tag.countOfUse})` : '(0)';
        const visibleTagTitle = `${tag.title}${countOfUseView}`;

        return (
          <MenuItem onClick={handleTagSelect} key={index}>
            {visibleTagTitle}
          </MenuItem>
        );
      })}
    </InfiniteScroll>
  );

  const renderNotFound = () => (
    <div className={classes.tagsNotFoundBox}>
      <div className={classes.tagsNotFoundItem}>
        <span>{i18n.t('NOT_FOUND_RESULT')}</span>
      </div>
    </div>
  );

  return (
    <div id="scrollableDiv" className={classes.tagsInfiniteScroll}>
      {list.length ? renderExistTagsList() : renderNotFound()}
    </div>
  );
};

export default withStyles(styles)(SimpleSearchTags);

SimpleSearchTags.propTypes = {
  isLoading: PropTypes.object.isRequired,
  onLoadTags: PropTypes.func.isRequired,
  onSelectTag: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};
