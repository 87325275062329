import { CircularProgress } from '@material-ui/core';
import React from 'react';

const loaderBoxStyle = {
  overflow: 'hidden',
  display: 'flex',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
};

export const CircularProgressBox = () => (
  <div style={loaderBoxStyle}>
    <CircularProgress />
  </div>
);
