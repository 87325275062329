import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { Slide } from '@material-ui/core';
import PropTypes from 'prop-types';
import i18n from '../../../../../common/i18n';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const DeleteRealEstateObjectDialog = (props) => {
  const realEstateObjectTitle =
    props.realEstateObject && props.realEstateObject.title ? ` ${props.realEstateObject.title}` : '';
  const confirmationText = (
    <span>
      {i18n.t('DELETE_REAL_ESTATE_OBJECT_CONFIRM_TEXT')}
      <b>{realEstateObjectTitle}</b>?
    </span>
  );

  return (
    <Dialog
      open={props.isDeleteDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onDialogClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{confirmationText}</DialogTitle>
      <DialogActions>
        <Button variant="contained" onClick={props.onDeleteConfirm} color="secondary">
          {i18n.t('DELETE')}
        </Button>
        <Button variant="contained" onClick={props.onDialogClose} color="primary">
          {i18n.t('CANCEL')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteRealEstateObjectDialog.propTypes = {
  onDialogClose: PropTypes.func,
  onDeleteConfirm: PropTypes.func,
  isDeleteDialogOpen: PropTypes.bool,
  realEstateObject: PropTypes.object,
};

export default DeleteRealEstateObjectDialog;
