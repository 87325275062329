import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  tags: {
    margin: '10px 0',
    fontSize: '16px',
  },
});

export const RealEstateObjectDetailsTags = ({ classes, tags }) => (
  <div className={classes.tags}>
    {tags.map((tag) => (
      <span key={tag.title}>{`#${tag.title} `}</span>
    ))}
  </div>
);

RealEstateObjectDetailsTags.propTypes = {
  tags: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RealEstateObjectDetailsTags);
