import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import i18n from '../../../../i18n';
import TelephoneNumberField from '../../../../../common/components/fields/TelephoneNumberField/TelephoneNumberField';
const DEFAULT_NUMBERS_COUNT = 2;
const TELEPHONE_NUMBER_MASK_VALUE = '+38(0__) ___ __ __';
const MoreTelephoneNumbers = ({ moreTelephoneNumbers, classes, maxCountOfNumbers = DEFAULT_NUMBERS_COUNT, errors, onNumbersChange, }) => {
    const handleAddMoreTelephoneNumber = () => onNumbersChange([...moreTelephoneNumbers, { telephoneNumber: '', id: null }]);
    const handleMoreTelephoneNumberChange = (index) => (event) => {
        const { value } = event.target;
        const moreTelephoneNumbersList = [...moreTelephoneNumbers];
        moreTelephoneNumbersList[index] = {
            id: moreTelephoneNumbersList[index].id,
            telephoneNumber: value !== TELEPHONE_NUMBER_MASK_VALUE ? value : '',
        };
        return onNumbersChange(moreTelephoneNumbersList);
    };
    const renderMoreTelephoneNumbers = () => moreTelephoneNumbers.map((telephoneNumber, key) => {
        const error = errors.moreTelephoneNumbers ? errors.moreTelephoneNumbers[key] : null;
        return (_jsx(TelephoneNumberField, { value: telephoneNumber.telephoneNumber, onFieldChange: handleMoreTelephoneNumberChange(key), error: error }, key));
    });
    return (_jsxs(_Fragment, { children: [moreTelephoneNumbers.length < maxCountOfNumbers && (_jsx(Link, { className: classes.addMoreTelephoneNumber, onClick: handleAddMoreTelephoneNumber, children: i18n.t('ADD_MORE_TELEPHONE_NUMBER') })), renderMoreTelephoneNumbers()] }));
};
export default withStyles(styles)(MoreTelephoneNumbers);
