export default (theme) => ({
  paginationBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: `${theme.spacing(2)}px 0px`,
  },
  paginationContent: {
    paddingRight: theme.spacing(3),
  },
  paginationBottom: {
    '@media (max-width: 900px)': {
      marginBottom: '100px',
    },
  },
  paginationCounterBox: {
    display: 'flex',
    alignItems: 'center',
    '& *': {
      fontSize: '1.1em',
    },
  },
  showedText: {
    color: '#767676',
    fontWeight: 700,
  },
  paginationNavButton: {
    margin: `0px ${theme.spacing(0.5)}px`,
  },
  '@media (max-width: 599px)': {
    paginationBox: {
      display: 'block',
    },
  },
});
