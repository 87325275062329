import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import ConfirmUserProfileForm from '../../../forms/ConfirmUserProfileForm/ConfirmUserProfileForm';
import ConfirmPageLayer from '../../../layears/ConfirmPageLayer/ConfirmPageLayer';
import FormHeaderLayer from '../../../../../../common/components/layers/FormHeaderLayer/FormHeaderLayer';
import i18n from '../../../../../i18n';
import { mapProfileConfirmationErrors } from '../../../../helpers/errorsMapper';
import { useGetAppConfigurationQuery } from '../../../../../allAccess/services/appConfigurationService';

export const ConfirmProfilePage = ({
  confirmRealtorProfile,
  fetchOrganizationsList,
  clearOrganizationsList,
  profile,
  organizationsList,
  dropRealtorProfileErrors,
  error,
  inProgress,
}) => {
  const { data: appConfigurations } = useGetAppConfigurationQuery();
  const [localErrors, setLocalErrors] = useState({});

  useEffect(() => {
    const errorCode = get(error, 'code');
    const validationErrors = errorCode ? mapProfileConfirmationErrors(appConfigurations.errorCodes, error) : {};

    setLocalErrors(validationErrors);
  }, [error]);

  const renderHeader = () => (
    <FormHeaderLayer title={i18n.t('CONFIRM_USER_PROFILE_TITLE')}>
      {`${i18n.t('CONFIRM_USER_PROFILE_SUB_TITLE')} `}
    </FormHeaderLayer>
  );

  return (
    <ConfirmPageLayer>
      {renderHeader()}
      <ConfirmUserProfileForm
        onConfirm={confirmRealtorProfile}
        profile={profile}
        onFetchOrganizationsList={fetchOrganizationsList}
        organizationsList={organizationsList}
        onClearOrganizationsList={clearOrganizationsList}
        onDropErrors={dropRealtorProfileErrors}
        errors={localErrors}
        inProgress={inProgress}
      />
    </ConfirmPageLayer>
  );
};

ConfirmProfilePage.propTypes = {
  confirmRealtorProfile: PropTypes.func.isRequired,
  fetchOrganizationsList: PropTypes.func.isRequired,
  clearOrganizationsList: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  organizationsList: PropTypes.array,
  dropRealtorProfileErrors: PropTypes.func.isRequired,
  error: PropTypes.object,
  inProgress: PropTypes.bool,
};
