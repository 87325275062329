import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-s-alert/dist/s-alert-default.css';
import RealEstateObjectContent from 'realtor/components/layears/RealEstateObjectContent/RealEstateObjectContent';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';
import i18n from '../../../../../../common/i18n';
import DeleteObjectDialog from '../../../../../components/Dialogs/DeleteObjectDialog/DeleteObjectDialog';
import ShareObjectsDialog from '../../../../../components/Dialogs/ShareObjectsDialog/ShareObjectsDialog';
import { ShareTokenHelper } from '../../../../../helpers/ShareTokenHelper';
import { FullScreenLoader } from '../../../../../components/FullScreenLoader/FullScreenLoader';
import { useGetAppConfigurationQuery } from '../../../../../allAccess/services/appConfigurationService';

export const RealEstateObjectDetailsPage = ({
  fetchObject,
  realEstateObjectId,
  realEstateObject,
  deleteObject,
  inProgress,
  addRealEstateToFavorite,
  removeRealEstateFromFavorite,
  objectMutationInProgress,
}) => {
  const { data: appConfigurations } = useGetAppConfigurationQuery();

  useEffect(() => {
    fetchObject(realEstateObjectId, appConfigurations);
  }, [realEstateObjectId]);

  useEffect(() => {
    if (realEstateObject && realEstateObject.title) {
      setPageTitle(i18n.t('SHOW_REAL_ESTATE_OBJECT_WINDOW_TITLES', { realEstateObjectTitle: realEstateObject.title }));
    }
  }, [realEstateObject]);

  const [isShareObjectsDialogOpen, setIsShareObjectsDialogOpen] = useState(false);
  const [idItemForDelete, setIdItemForDelete] = useState(null);

  const handleDeleteDialogConfirm = () => {
    deleteObject(idItemForDelete);
    location.href = '/';
  };

  // TODO: Add delete object functionality
  // const openDeleteDialog = (idItemForDelete) => {
  //   setIdItemForDelete(idItemForDelete);
  // };

  const handleDeleteDialogClose = () => {
    setIdItemForDelete(null);
  };

  const handleShareObjectsDialogClose = () => {
    setIsShareObjectsDialogOpen(false);
  };

  const handleShareObjectsDialogOpen = () => {
    setIsShareObjectsDialogOpen(true);
  };

  return (
    realEstateObject && (
      <>
        {objectMutationInProgress && <FullScreenLoader />}
        <RealEstateObjectContent
          realEstateObject={realEstateObject}
          filters={appConfigurations.objectFilters}
          inProgress={inProgress}
          onShareObjectsDialogOpen={handleShareObjectsDialogOpen}
          onAddRealEstateToFavorite={addRealEstateToFavorite}
          onRemoveRealEstateFromFavorite={removeRealEstateFromFavorite}
        />
        <DeleteObjectDialog
          isDeleteDialogOpen={Boolean(idItemForDelete)}
          onDialogClose={handleDeleteDialogClose}
          onDeleteConfirm={handleDeleteDialogConfirm}
        />
        <ShareObjectsDialog
          onDialogClose={handleShareObjectsDialogClose}
          isDialogOpen={isShareObjectsDialogOpen}
          shareLink={ShareTokenHelper.buildShareLinkForSingleObject(realEstateObject.shareToken)}
        />
      </>
    )
  );
};

export default RealEstateObjectDetailsPage;

RealEstateObjectDetailsPage.propTypes = {
  realEstateObjectId: PropTypes.number.isRequired,
  fetchObject: PropTypes.func.isRequired,
  addRealEstateToFavorite: PropTypes.func.isRequired,
  removeRealEstateFromFavorite: PropTypes.func.isRequired,
  deleteObject: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  objectMutationInProgress: PropTypes.bool.isRequired,
  realEstateObject: PropTypes.object,
};
