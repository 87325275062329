import { FormValidation } from '../../../../common/helpers/FormValidation';

export class RealtorProfileValidation {
  constructor(realtorProfile) {
    this.realtorProfile = realtorProfile;
  }

  validateNameString(propertyName, value, errors) {
    // eslint-disable-next-line no-param-reassign
    errors[propertyName] = FormValidation.validateName(value);

    // eslint-disable-next-line no-param-reassign
    if (!errors[propertyName]) errors[propertyName] = FormValidation.validateLength(value, 3, 30);
  }

  // eslint-disable-next-line max-statements
  validate() {
    const { firstName, lastName, organization, moreTelephoneNumbers } = this.realtorProfile;
    const errors = {};

    if (firstName) {
      this.validateNameString('firstName', firstName, errors);
    } else {
      errors.firstName = FormValidation.validateRequired(firstName);
    }

    if (organization) {
      errors.organization = FormValidation.validateLength(organization, 3, 30);

      if (!errors.organization) {
        errors.organization = FormValidation.validateOrganization(organization);
      }
    }

    if (lastName) {
      this.validateNameString('lastName', lastName, errors);
    } else {
      errors.lastName = FormValidation.validateRequired(lastName);
    }

    // if (organization.title) {
    //   errors.organization = FormValidation.validateLength(organization.title, 3, 20);
    // }

    errors.moreTelephoneNumbers = [];

    moreTelephoneNumbers.forEach(({ telephoneNumber }, index) => {
      if (telephoneNumber) {
        errors.moreTelephoneNumbers[index] = FormValidation.validateTelephoneNumber(telephoneNumber);
      }
    });

    return {
      isRealtorProfileFormValid: FormValidation.isFormValid(errors),
      realtorProfileErrors: errors,
    };
  }
}
