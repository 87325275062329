import { BaseValidator } from './BaseValidator';

export class ApartmentValidator extends BaseValidator {
  /* eslint-disable max-statements */
  validate() {
    const errors = this.initBaseValidation();

    errors.squarePrice = this.validateSquarePrice();

    errors.floor = this.validateFloorRange();

    errors.livingArea = this.validateLivingArea();

    errors.kitchenArea = this.validateKitchenArea();

    if (!errors.squarePrice) {
      errors.squarePriceFrom = this.validatePriceWithCurrency(this.realEstateFilter.squarePriceFrom);
      errors.squarePriceTo = this.validatePriceWithCurrency(this.realEstateFilter.squarePriceTo);
    }

    if (!errors.floor) {
      errors.floorFrom = this.validateMaxValue(this.realEstateFilter.floorFrom);
      errors.floorTo = this.validateMaxValue(this.realEstateFilter.floorTo);
    }

    if (!errors.livingArea) {
      errors.livingAreaFrom = this.validateMaxValue(this.realEstateFilter.livingAreaFrom);
      errors.livingAreaTo = this.validateMaxValue(this.realEstateFilter.livingAreaTo);
    }

    if (!errors.kitchenArea) {
      errors.kitchenAreaFrom = this.validateMaxValue(this.realEstateFilter.kitchenAreaFrom);
      errors.kitchenAreaTo = this.validateMaxValue(this.realEstateFilter.kitchenAreaTo);
    }

    return errors;
  }
}
