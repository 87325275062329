import {
  FETCH_TAGS_START,
  FETCH_TAGS_FAIL,
  FETCH_TAGS_SUCCESS,
  CREATE_TAGS_SUCCESS,
  DROP_CREATED_TAG,
} from '../actions';

const initialState = {
  inProgress: false,
  tagsList: [],
  createdTag: null,
};

// eslint-disable-next-line default-param-last
export const tag = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAGS_START:
      return {
        ...state,
        inProgress: true,
      };
    case FETCH_TAGS_FAIL:
      return {
        ...state,
        inProgress: false,
      };
    case CREATE_TAGS_SUCCESS:
      return {
        ...state,
        createdTag: action.tag,
      };
    case DROP_CREATED_TAG:
      return {
        ...state,
        createdTag: null,
      };
    case FETCH_TAGS_SUCCESS:
      const tagsList = [...action.tagsList];
      return {
        ...state,
        inProgress: false,
        tagsList,
      };
    default:
      return state;
  }
};
