import Alert from 'react-s-alert';

import {
  deleteClientFilterError,
  deleteClientFilterStart,
  deleteClientFilterSuccess,
} from 'realtor/actions/clientFilterActions';
import ClientFilterResource from 'realtor/resourses/ClientFilter.resource';
import ClientResource from '../resourses/Client.resource';
import { deleteClientFail, deleteClientStart, deleteClientSuccess } from './clientActions';
import i18n from '../../../common/i18n';
import { Client, serverFilterToDomainFactory, TelephoneNumberBuilder } from '../domain';

export const FETCH_CLIENTS_START = 'FETCH_CLIENTS_START';
export const FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';

export const FETCH_TOTAL_START = 'FETCH_TOTAL_START';
export const FETCH_TOTAL_FAIL = 'FETCH_TOTAL_FAIL';
export const FETCH_TOTAL_SUCCESS = 'FETCH_TOTAL_SUCCESS';

const fetchTotalStart = () => ({ type: FETCH_TOTAL_START });

const fetchTotalFail = (error) => ({ type: FETCH_TOTAL_FAIL, error });

const fetchTotalSuccess = (data) => ({ type: FETCH_TOTAL_SUCCESS, data });

const fetchClientsStart = () => ({ type: FETCH_CLIENTS_START });

const fetchClientsFail = (error) => ({ type: FETCH_CLIENTS_FAIL, error });

const fetchClientsSuccess = (data, types) => {
  const clientsDomainList = data.list.map(
    (client) =>
      new Client({
        ...client,
        telephoneNumber: TelephoneNumberBuilder.buildFromServerData(client.telephoneNumber),
        filters: client.filters.map((filter) => serverFilterToDomainFactory(filter, types)),
      }),
  );

  return {
    type: FETCH_CLIENTS_SUCCESS,
    data: {
      ...data,
      list: clientsDomainList,
    },
  };
};

export const fetchClients = (params, appParams) => (dispatch) => {
  dispatch(fetchClientsStart());
  ClientResource.getList(params)
    .then(async (data) => {
      await dispatch(fetchClientsSuccess(data, appParams.objectFilters.objectType));
    })
    .catch((error) => dispatch(fetchClientsFail(error)));
};

export const fetchMyTotal = () => (dispatch) => {
  dispatch(fetchTotalStart());
  ClientResource.getTotal()
    .then((data) => {
      dispatch(fetchTotalSuccess(data));
    })
    .catch((error) => dispatch(fetchTotalFail(error)));
};

export const fetchTotalAndList = (params) => (dispatch, getState) => {
  dispatch(fetchTotalStart());
  dispatch(fetchClientsStart());
  ClientResource.getTotal()
    .then((data) => {
      dispatch(fetchTotalSuccess(data));
      if (data.total) fetchClients(params)(dispatch, getState);
    })
    .catch((error) => dispatch(fetchTotalFail(error)));
};

export const fetchAllClients = (params) => (dispatch, getState) => {
  const state = getState();

  dispatch(fetchClientsStart());
  ClientResource.getAllList(params)
    .then((data) => dispatch(fetchClientsSuccess(data, state.appConfigurations.objectFilters.objectType)))
    .catch((error) => dispatch(fetchClientsFail(error)));
};

export const getTotalCount = () => (dispatch) => {
  dispatch(fetchTotalStart());
  ClientResource.getAllTotal()
    .then((data) => {
      dispatch(fetchTotalSuccess(data));
    })
    .catch((error) => dispatch(fetchTotalFail(error)));
};

export const fetchAllTotalAndList = (params) => (dispatch, getState) => {
  dispatch(fetchTotalStart());
  dispatch(fetchClientsStart());
  ClientResource.getAllTotal()
    .then((data) => {
      dispatch(fetchTotalSuccess(data));
      if (data.total) fetchAllClients(params)(dispatch, getState);
    })
    .catch((error) => dispatch(fetchTotalFail(error)));
};

export const deleteAndFetchList = (client, reloadParams) => (dispatch, getState) => {
  dispatch(deleteClientStart());
  ClientResource.deleteClient(client.id)
    .then(() => {
      dispatch(deleteClientSuccess());
      Alert.success(i18n.t('CLIENT_SUCCESSFULLY_DELETED', { fullName: client.fullName }), {
        position: 'top-right',
        effect: 'slide',
        timeout: 3000,
      });
      fetchClients(reloadParams)(dispatch, getState);
    })
    .catch((error) => {
      dispatch(deleteClientFail(error));
    });
};

export const deleteClientFilterAndFetchList = (clientId, filterId, reloadParams) => (dispatch, getState) => {
  dispatch(deleteClientFilterStart());
  ClientFilterResource.deleteSingleClientFilter(clientId, filterId)
    .then(() => {
      dispatch(deleteClientFilterSuccess());
      Alert.success(i18n.t('CLIENT_FILTER_SUCCESSFULLY_DELETED'), {
        position: 'top-right',
        effect: 'slide',
        timeout: 3000,
      });
      fetchClients(reloadParams)(dispatch, getState);
    })
    .catch((error) => {
      dispatch(deleteClientFilterError(error));
    });
};

export const dropTotalCount = () => (dispatch) => dispatch(fetchTotalSuccess({ total: null }));
