import {
  FETCH_MY_OBJECTS_SUCCESS,
  FETCH_MY_OBJECTS_FAIL,
  FETCH_MY_OBJECTS_START,
  FETCH_MY_OBJECTS_TOTAL_START,
  FETCH_MY_OBJECTS_TOTAL_SUCCESS,
  FETCH_MY_OBJECTS_TOTAL_FAIL,
  DELETE_OBJECT_START,
  DELETE_OBJECT_FAIL,
  DELETE_OBJECT_SUCCESS,
} from '../actions';

const initialState = {
  actionInProgress: false,
  listInProgress: false,
  inProgress: false,
  error: null,
  list: [],
  totalCount: null,
};

// eslint-disable-next-line default-param-last
export const myObjectsList = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_OBJECT_START:
      return {
        ...state,
        error: null,
        actionInProgress: true,
      };
    case DELETE_OBJECT_FAIL:
      return {
        ...state,
        error: action.error,
        actionInProgress: false,
      };
    case DELETE_OBJECT_SUCCESS:
      return {
        ...state,
        error: null,
        actionInProgress: false,
      };
    case FETCH_MY_OBJECTS_START:
      return {
        ...state,
        error: null,
        listInProgress: true,
      };
    case FETCH_MY_OBJECTS_TOTAL_START:
      return {
        ...state,
        error: null,
        inProgress: true,
      };

    case FETCH_MY_OBJECTS_FAIL:
      return {
        ...state,
        error: action.error,
        listInProgress: false,
      };
    case FETCH_MY_OBJECTS_TOTAL_FAIL:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };

    case FETCH_MY_OBJECTS_SUCCESS:
      return {
        ...state,
        error: null,
        list: action.data.result,
        totalCount: action.data.totalCount,
        listInProgress: false,
      };
    case FETCH_MY_OBJECTS_TOTAL_SUCCESS:
      return {
        ...state,
        error: null,
        totalCount: action.data.total,
        inProgress: false,
      };
    default:
      return state;
  }
};
