import { findKey, partial, isEqual } from 'lodash';

export default class ObjectHelper {
  static getObjectValues(object) {
    const values = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const objectKey in object) {
      values.push(object[objectKey]);
    }
    return values;
  }

  static getKeyByValue(object, value) {
    return findKey(object, partial(isEqual, value));
  }
}
