import { FETCH_USER_INFO_START, FETCH_USER_INFO_FAIL, FETCH_USER_INFO_SUCCESS } from '../../actions/user';
import { CONFIRM_PROFILE_FAIL, CONFIRM_PROFILE_START, CONFIRM_PROFILE_SUCCESS, UPDATE_PROFILE_START } from '../actions';

const initialState = {
  inProgress: false,
  updateInProgress: false,
  error: null,
  profile: {},
};

// eslint-disable-next-line default-param-last
export const user = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_START:
      return {
        ...state,
        error: null,
        updateInProgress: true,
      };
    case FETCH_USER_INFO_START:
    case CONFIRM_PROFILE_START:
      return {
        ...state,
        error: null,
        inProgress: true,
      };
    case FETCH_USER_INFO_FAIL:
    case CONFIRM_PROFILE_FAIL:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };

    case FETCH_USER_INFO_SUCCESS:
    case CONFIRM_PROFILE_SUCCESS:
      return {
        ...state,
        error: action.error,
        inProgress: false,
        updateInProgress: false,
        profile: action.profile,
      };
    default:
      return state;
  }
};
