import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { isEmpty } from 'lodash';
import { TextField, FormHelperText, OutlinedInput, FormControl, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FullScreenLoader } from '../../../../../../../components/FullScreenLoader/FullScreenLoader';
import { FormValidation } from '../../../../../../../../common/helpers/FormValidation';
import i18n from '../../../../../../../../common/i18n';
import styles from './styles';
import PasswordField from '../../../../../../components/fields/PasswordField/PasswordField';
const RegistrationFormComponent = ({ classes, odRegistration, onDropErrors, errors, inProgress, }) => {
    const [localErrors, setLocalErrors] = useState({});
    const [values, setValues] = useState({
        password: '',
        confirmPassword: '',
        telephoneNumber: '',
        email: '',
    });
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    useEffect(() => {
        if (!isEmpty(errors)) {
            return setLocalErrors(errors);
        }
    }, [errors]);
    const passwordValidation = () => {
        const { password } = values;
        if (!password) {
            return i18n.t('FIELD_REQUIRED');
        }
        if (password.length < 8) {
            return i18n.t('INVALID_PASSWORD_LENGTH');
        }
        const re = /(?=.*\d)(?=.*[A-ZА-ЯҐЄІЇЎЁ]).{8,}/;
        if (!re.test(password)) {
            return i18n.t('INVALID_PASSWORD');
        }
        return null;
    };
    const passwordConfirmValidation = () => {
        const { password, confirmPassword } = values;
        if (!confirmPassword) {
            return i18n.t('FIELD_REQUIRED');
        }
        if (confirmPassword !== password) {
            return i18n.t('PASSWORDS_DO_NOT_MATCH');
        }
        return null;
    };
    const validateForm = () => {
        const errors = {};
        const { telephoneNumber, email } = values;
        errors.password = passwordValidation();
        errors.confirmPassword = passwordConfirmValidation();
        errors.telephoneNumber = FormValidation.validateRequired(telephoneNumber);
        errors.email = FormValidation.validateRequired(email);
        if (email) {
            errors.email = FormValidation.emailValidation(email);
        }
        if (telephoneNumber) {
            errors.telephoneNumber = FormValidation.validateTelephoneNumber(telephoneNumber);
        }
        setLocalErrors(errors);
        return FormValidation.isFormValid(errors);
    };
    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        return setValues({
            ...values,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const isFromValid = validateForm();
        if (isFromValid) {
            odRegistration(values);
        }
        else {
            onDropErrors();
        }
        return false;
    };
    return (_jsxs(_Fragment, { children: [inProgress && _jsx(FullScreenLoader, {}), _jsxs("form", { className: classes.form, noValidate: true, onSubmit: handleSubmit, method: "POST", children: [_jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx("label", { htmlFor: "outlined-adornment-telephoneNumber", children: i18n.t('TELEPHONE_NUMBER') }), _jsx(InputMask, { mask: "+38(099) 999 99 99", onChange: handleFieldChange, value: values.telephoneNumber, alwaysShowMask: true, children: () => (_jsx(TextField, { variant: "outlined", margin: "none", required: true, fullWidth: true, name: "telephoneNumber", type: "test", id: "telephoneNumber", helperText: localErrors.telephoneNumber, error: !!localErrors.telephoneNumber })) })] }), _jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx("label", { htmlFor: "outlined-adornment-email", children: i18n.t('EMAIL_ADDRESS') }), _jsx(TextField, { onChange: handleFieldChange, variant: "outlined", required: true, fullWidth: true, id: "email", name: "email", autoComplete: "email", autoFocus: true, value: values.email, error: !!localErrors.email }), !!localErrors.email && _jsx(FormHelperText, { error: true, children: localErrors.email })] }), _jsx(PasswordField, { onFieldChange: handleFieldChange, error: localErrors.password, value: values.password, onIsPasswordVisibleChange: setIsPasswordVisible }), _jsxs(FormControl, { fullWidth: true, margin: "normal", variant: "outlined", children: [_jsx("label", { htmlFor: "outlined-adornment-confirmPassword", children: i18n.t('CONFIRM_PASSWORD') }), _jsx(OutlinedInput
                            /* @ts-ignore */
                            , { 
                                /* @ts-ignore */
                                variant: "outlined", required: true, name: "confirmPassword", type: isPasswordVisible ? 'text' : 'password', id: "confirmPassword", onChange: handleFieldChange, value: values.confirmPassword, error: !!localErrors.confirmPassword }), !!localErrors.confirmPassword && _jsx(FormHelperText, { error: true, children: localErrors.confirmPassword })] }), _jsx(Button, { type: "submit", fullWidth: true, variant: "contained", color: "primary", className: classes.submit, disabled: inProgress, children: i18n.t('CONFIRM_REGISTRATION') })] })] }));
};
export const RegistrationForm = withStyles(styles)(RegistrationFormComponent);
