import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Select, MenuItem } from '@material-ui/core';
import cn from 'classnames';

import PropTypes from 'prop-types';
import styles from './styles';

export const Pagination = ({
  onLimitChange,
  classes,
  onPaginationBack,
  onPaginationForward,
  limit,
  offset,
  total,
  limitsList,
  isBottom,
}) => {
  const handleLimitChange = ({ target }) => {
    onLimitChange(target.value);
  };

  const renderCountOfPagination = () => {
    const boxClasses = cn(classes.paginationCounterBox, classes.paginationContent);

    const finishCount = total - offset - limit > 0 ? offset + limit : total;
    return (
      <div className={boxClasses}>
        <span className={classes.showedText}>
          <b>Показано:</b>
        </span>
        <IconButton disabled={!offset} onClick={onPaginationBack} className={classes.paginationNavButton}>
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="KeyboardArrowLeftIcon"
          >
            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
          </svg>
        </IconButton>
        <span>{`${offset + 1}-${finishCount} з ${total}`}</span>
        <IconButton
          disabled={offset + limit >= total}
          onClick={onPaginationForward}
          className={classes.paginationNavButton}
        >
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="KeyboardArrowRightIcon"
          >
            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
          </svg>
        </IconButton>
      </div>
    );
  };

  const renderSelectLimit = () => (
    <div className={classes.paginationContent}>
      <Select
        variant="outlined"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={limit}
        onChange={handleLimitChange}
      >
        {limitsList.map((limit, key) => (
          <MenuItem key={key} value={limit}>
            {limit}
          </MenuItem>
        ))}
      </Select>
    </div>
  );

  const boxName = cn({ [classes.paginationBottom]: isBottom }, classes.paginationBox);
  return (
    <div className={boxName}>
      {renderCountOfPagination()}
      {renderSelectLimit()}
    </div>
  );
};

export default withStyles(styles)(Pagination);

Pagination.propTypes = {
  classes: PropTypes.object.isRequired,
  isBottom: PropTypes.object,
  onLimitChange: PropTypes.func,
  onPaginationBack: PropTypes.func,
  onPaginationForward: PropTypes.func,
  limitsList: PropTypes.array,
  limit: PropTypes.number,
  offset: PropTypes.number,
  total: PropTypes.number,
};
