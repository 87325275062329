export default (theme) => ({
  submit: {
    margin: `${theme.spacing(3)}px 0px`,
    padding: `${theme.spacing(1.5)}px 0px`,
    background: '#F5841F',
    borderRadius: theme.spacing(3),
  },
  errorText: {
    color: '#f44336',
  },
  description: {
    fontSize: '16px',
  },
  descriptionAreaError: {
    fontSize: '16px',
    borderColor: '#f44336',
  },
  addMoreTelephoneNumber: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
