import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// @ts-ignore
import cn from 'classnames';
import i18n from '../../../../i18n';
import styles from './styles';
const RangeTextInput = ({ onFieldChange, nameFrom, nameTo, valueFrom, valueTo, classes, placeholderFrom, placeholderTo, label, error, errorFrom, errorTo, }) => {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        return onFieldChange(name, value);
    };
    const fieldErrorClasses = cn(classes.errorText, classes.fieldErrorText);
    return (_jsxs("div", { className: classes.rangeTextInputBox, children: [_jsx("label", { children: label }), _jsxs("div", { className: classes.rangeTextField, children: [_jsx("span", { children: i18n.t('FROM') }), _jsxs(FormControl, { className: classes.rangeTextFieldTextInput, margin: "none", error: !!errorFrom || !!error, children: [_jsx(TextField, { placeholder: placeholderFrom, variant: "outlined", margin: "none", required: true, fullWidth: true, id: nameFrom, name: nameFrom, onChange: handleInputChange, type: "number", value: valueFrom, inputProps: {
                                    min: 0,
                                }, error: !!errorFrom || !!error }), _jsx(FormHelperText, { className: fieldErrorClasses, children: errorFrom })] }), _jsx("div", { className: classes.dash }), _jsx("span", { children: i18n.t('TO') }), _jsxs(FormControl, { className: classes.rangeTextFieldTextInput, margin: "none", error: !!errorTo || !!error, children: [_jsx(TextField, { placeholder: placeholderTo, variant: "outlined", margin: "none", required: true, fullWidth: true, id: nameTo, name: nameTo, onChange: handleInputChange, type: "number", value: valueTo, inputProps: {
                                    min: 0,
                                }, error: !!errorTo || !!error }), _jsx(FormHelperText, { className: fieldErrorClasses, children: errorTo })] })] }), _jsx(FormControl, { children: _jsx(FormHelperText, { className: classes.errorText, children: error }) })] }));
};
export default withStyles(styles)(RangeTextInput);
