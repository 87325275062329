import { connect } from 'react-redux';
import qs from 'qs';
import ShareObjectPage from '../../components/SharePages/ShareObjectPage/ShareObjectPage';
import { fetchObjectByHash } from '../../actions/object';

const mapStateToProps = ({ sharedSingleRealEstateObject, appConfigurations }, props) => {
  const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  if (!queryParams.token) location.href = '/';

  return {
    ...sharedSingleRealEstateObject,
    realEstateObject: sharedSingleRealEstateObject.objectData,
    objectHash: queryParams.token,
    filters: appConfigurations.objectFilters,
  };
};

const mapDispatchToProps = {
  fetchObjectByHash,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareObjectPage);
