import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import i18n from '../../../../../../../common/i18n';

const styles = () => ({
  description: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
  },
  rowLabel: {
    color: '#C1C1C1',
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: '0',
  },
});

export const RealEstateObjectDetailsCreatedAt = ({ classes, createdAt }) => {
  const dateToShow = moment(createdAt).format('DD.MM.YYYY');

  return (
    <div className={classes.description}>
      <p className={classes.rowLabel}>{i18n.t('CREATED_AD')}</p>
      <Typography>{dateToShow}</Typography>
    </div>
  );
};

RealEstateObjectDetailsCreatedAt.propTypes = {
  createdAt: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RealEstateObjectDetailsCreatedAt);
