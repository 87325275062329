import { BaseValidator } from './BaseValidator';

export class LandPlotFilterValidator extends BaseValidator {
  // eslint-disable-next-line  max-statements
  validate() {
    const errors = {};

    errors.area = this.validateArea();

    errors.landAreaPrice = this.validateLandAreaPrice();

    errors.totalPrice = this.validateTotalPrice();

    return errors;
  }
}
