import { BaseFilter } from './BaseFilter';

export class GarageParkingFilter extends BaseFilter {
  get objectPartialServiceType() {
    return this.props.objectPartialServiceType;
  }

  set objectPartialServiceType(objectPartialServiceType) {
    this.props.objectPartialServiceType = objectPartialServiceType;
  }

  get objectSubtype() {
    return this.props.objectSubtype;
  }

  set objectSubtype(objectSubtype) {
    this.props.objectSubtype = objectSubtype;
  }

  toServerEntity() {
    return {
      ...this.toBaseServerEntity(),
      realEstateSubtype: this.props.objectSubtype ? Array.from(this.props.objectSubtype) : undefined,
      realEstateServiceType: this.props.objectPartialServiceType ? Number(this.props.objectPartialServiceType) : null,
    };
  }
}
