import { BaseValidator } from './BaseValidator';

export class ApartmentFilterValidator extends BaseValidator {
  validate() {
    const errors = {};

    errors.area = this.validateArea();

    errors.kitchenArea = this.validateKitchenArea();

    errors.floor = this.validateFloor();

    errors.squarePrice = this.validateSquarePrice();

    errors.totalPrice = this.validateTotalPrice();

    errors.livingArea = this.validateLivingArea();

    return errors;
  }
}
