export class Client {
  constructor(props) {
    this.props = props;
  }

  get id() {
    return this.props.id;
  }

  set id(id) {
    this.props.id = id;
  }

  get telephoneNumber() {
    return this.props.telephoneNumber;
  }

  set telephoneNumber(telephoneNumber) {
    this.props.telephoneNumber = telephoneNumber;
  }

  get moreTelephoneNumbers() {
    return this.props.moreTelephoneNumbers;
  }

  set moreTelephoneNumbers(moreTelephoneNumbers) {
    this.props.moreTelephoneNumbers = moreTelephoneNumbers;
  }

  get fullName() {
    return this.props.fullName;
  }

  set fullName(fullName) {
    this.props.fullName = fullName;
  }

  get description() {
    return this.props.description;
  }

  set description(description) {
    this.props.description = description;
  }

  get filters() {
    return this.props.filters;
  }

  set filters(filters) {
    this.props.filters = filters;
  }

  get tags() {
    return this.props.tags || [];
  }

  set tags(tags) {
    this.props.tags = tags;
  }

  get isFavorite() {
    return this.props.isFavorite;
  }

  set isFavorite(isFavorite) {
    this.props.isFavorite = isFavorite;
  }

  get isClientMy() {
    return this.props.isClientMy;
  }

  set isClientMy(isClientMy) {
    this.props.isClientMy = isClientMy;
  }

  toServerEntity() {
    return {
      fullName: this.props.fullName,
      description: this.props.description,
      telephoneNumber: this.props.telephoneNumber.getTelephoneNumberFromContract(),
      moreTelephoneNumbers: this.props.moreTelephoneNumbers.map((moreTelephoneNumber) =>
        moreTelephoneNumber.getFullProps(),
      ),
      tags: this.props.tags.map((tag) => tag.toServerEntity()),
    };
  }

  toFormEntity() {
    return {
      ...this.props,
      moreTelephoneNumbers: this.props.moreTelephoneNumbers.map((moreTelephoneNumber) =>
        moreTelephoneNumber.getTelephoneNumberObjectForForm(),
      ),
      telephoneNumber: this.props.telephoneNumber.getTelephoneNumberStringForForm(),
      filters: this.props.filters ? this.props.filters.map((filter) => filter.toFormEntity()) : [],
      tags: this.props.tags ? this.props.tags.map((tag) => tag.toFormEntity()) : [],
    };
  }
}
