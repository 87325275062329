import React from 'react';
import PropTypes from 'prop-types';
import RangeTextInput from 'realtor/components/common/RangeTextInput/RangeTextInput';
import i18n from '../../../../../common/i18n';

export const LivingAreaRangeFilter = ({ onSelect, filter, errors }) => (
  <RangeTextInput
    valueFrom={filter.livingAreaFrom}
    valueTo={filter.livingAreaTo}
    nameFrom="livingAreaFrom"
    nameTo="livingAreaTo"
    onFieldChange={onSelect}
    placeholderFrom={i18n.t('AREA_PLACEHOLDER')}
    placeholderTo={i18n.t('AREA_PLACEHOLDER')}
    label={i18n.t('LIVING_AREA')}
    error={errors.livingArea}
    errorFrom={errors.livingAreaFrom}
    errorTo={errors.livingAreaTo}
  />
);

LivingAreaRangeFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
