import { ON_REDIRECT } from '../actions/redirect';

const initialState = {
  path: null,
};

// eslint-disable-next-line default-param-last
export default function redirect(state = initialState, action) {
  switch (action.type) {
    case ON_REDIRECT: {
      return {
        ...state,
        path: action.path,
      };
    }
    default:
      return state;
  }
}
