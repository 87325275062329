import { Button, FormControl, Grid, TextField } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from 'realtor/components/forms/ConfirmUserProfileForm/styles';
import PropTypes from 'prop-types';
import { UpdateProfileConfirmDialog } from 'realtor/components/dialogs';
import { RealtorProfileValidation } from 'realtor/validation/realtorProfile/realtorProfileValidation';
import i18n from '../../../../i18n';

export const UpdateProfileForm = ({ onCloseEdit, profile, classes, onSubmit, errors = {} }) => {
  const [profileValues, setProfileValues] = useState({
    ...profile,
  });
  const [profileErrors, setProfileErrors] = useState({
    ...errors,
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const validateForm = (profileData) => {
    const validator = new RealtorProfileValidation(profileData);

    return validator.validate();
  };

  const handleFieldChange = ({ target }) => {
    setProfileValues({
      ...profileValues,
      [target.name]: target.value,
    });
    setIsSubmitDisabled(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isRealtorProfileFormValid, realtorProfileErrors } = validateForm(profileValues);
    if (isRealtorProfileFormValid) {
      setProfileErrors({});
      return setIsConfirmDialogOpen(true);
    }

    setProfileErrors(realtorProfileErrors);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
    onCloseEdit();
  };

  const handleUpdateProfileConfirm = () => {
    onSubmit(profileValues);
    setIsConfirmDialogOpen(false);
  };

  return (
    <>
      <UpdateProfileConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        onDialogClose={handleConfirmDialogClose}
        onConfirm={handleUpdateProfileConfirm}
      />
      <form noValidate onSubmit={handleSubmit} method="POST" name="confirm-validation-form" className={classes.form}>
        <FormControl fullWidth margin="normal">
          <label htmlFor="outlined-adornment-first-name">{i18n.t('FIRST_NAME')}</label>
          <TextField
            placeholder={i18n.t('FIRST_NAME_PLACEHOLDER')}
            variant="outlined"
            margin="none"
            required
            fullWidth
            id="firstName"
            name="firstName"
            autoFocus
            onChange={handleFieldChange}
            value={profileValues.firstName}
            helperText={profileErrors.firstName}
            error={!!profileErrors.firstName}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <label htmlFor="outlined-adornment-last-name">{i18n.t('LAST_NAME')}</label>
          <TextField
            placeholder={i18n.t('LAST_NAME_PLACEHOLDER')}
            variant="outlined"
            margin="none"
            required
            fullWidth
            id="lastName"
            name="lastName"
            onChange={handleFieldChange}
            value={profileValues.lastName}
            helperText={profileErrors.lastName}
            error={!!profileErrors.lastName}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <label htmlFor="outlined-adornment-organization">{i18n.t('ORGANIZATION')}</label>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="organization"
            name="organization"
            value={profileValues.organization}
            disabled
            onChange={handleFieldChange}
            helperText={profileErrors.organization}
            error={!!profileErrors.organization}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <label htmlFor="outlined-adornment-telephone-number">{i18n.t('TELEPHONE_NUMBER')}</label>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="telephone-number"
            name="telephone-number"
            disabled
            value={profileValues.telephoneNumber.getTelephoneNumberView()}
          />
        </FormControl>
        {profileValues.moreTelephoneNumbers.map((telephoneNumber, key) => (
          <FormControl fullWidth key={key} margin="normal">
            <label htmlFor="outlined-adornment-telephone-number">{`${i18n.t('TELEPHONE_NUMBER')} ${key + 1}`}</label>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="telephone-number"
              name="telephone-number"
              disabled
              autoFocus
              value={telephoneNumber.getTelephoneNumberView()}
            />
          </FormControl>
        ))}
        {/* <FormControl fullWidth margin={'normal'}> */}
        {/*  <label htmlFor="outlined-adornment-email" >{i18n.t('EMAIL_ADDRESS')}</label> */}
        {/*  <TextField */}
        {/*    variant="outlined" */}
        {/*    required */}
        {/*    fullWidth */}
        {/*    id="email" */}
        {/*    name="email" */}
        {/*    autoComplete="email" */}
        {/*    autoFocus */}
        {/*    value={profileValues.email} */}
        {/*    disabled={true} */}
        {/*  /> */}
        {/* </FormControl> */}
        <div>
          <Grid container spacing={2} className={classes.controlButtons}>
            <Grid item xs={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitDisabled}
              >
                {i18n.t('SAVE')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" color="default" className={classes.cancel} onClick={onCloseEdit}>
                {i18n.t('CANCEL')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};

export default withStyles(styles)(UpdateProfileForm);

UpdateProfileForm.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object,
  profile: PropTypes.object,
  onCloseEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
