import { jsx as _jsx } from "react/jsx-runtime";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
/* @ts-ignore */
import { AppLoaderContainer } from './containers/AppLoaderContainer';
// @ts-ignore
import { i18nLoader } from '../common/i18n/i18nLoader';
import { store } from './configureStore';
const APP_ID = 'app';
const render = (Component, store) => {
    const container = document.getElementById(APP_ID);
    const root = ReactDOM.createRoot(container);
    root.render(_jsx(Provider, { store: store, children: _jsx(Component, {}) }));
};
const start = (AppLoaderContainer, store) => {
    i18nLoader((lng) => import(`../common/i18n/translations/${lng}.yml`)).then(() => render(AppLoaderContainer, store));
};
start(AppLoaderContainer, store);
