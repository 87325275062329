import { Client, TelephoneNumberBuilder } from 'realtor/domain';
import ObjectResource from '../resourses/Object.resource';

export const FETCH_MY_OBJECTS_START = 'FETCH_MY_OBJECTS_START';
export const FETCH_MY_OBJECTS_FAIL = 'FETCH_MY_OBJECTS_FAIL';
export const FETCH_MY_OBJECTS_SUCCESS = 'FETCH_MY_OBJECTS_SUCCESS';

export const FETCH_MY_OBJECTS_TOTAL_START = 'FETCH_MY_OBJECTS_TOTAL_START';
export const FETCH_MY_OBJECTS_TOTAL_FAIL = 'FETCH_MY_OBJECTS_TOTAL_FAIL';
export const FETCH_MY_OBJECTS_TOTAL_SUCCESS = 'FETCH_MY_OBJECTS_TOTAL_SUCCESS';

export const DELETE_OBJECT_START = 'DELETE_OBJECT_START';
export const DELETE_OBJECT_SUCCESS = 'DELETE_OBJECT_SUCCESS';
export const DELETE_OBJECT_FAIL = 'DELETE_OBJECT_FAIL';

export const FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_START = 'FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_START';
export const FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_SUCCESS = 'FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_SUCCESS';
export const FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_FAIL = 'FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_FAIL';

const deleteObjectStart = () => ({ type: DELETE_OBJECT_START });

const deleteObjectSuccess = () => ({ type: DELETE_OBJECT_SUCCESS });

const deleteObjectFail = (error) => ({
  type: DELETE_OBJECT_FAIL,
  error,
});

const fetchMyObjectsTotalStart = () => ({ type: FETCH_MY_OBJECTS_TOTAL_START });

const fetchMyObjectsTotalFail = (error) => ({ type: FETCH_MY_OBJECTS_TOTAL_FAIL, error });

const fetchMyObjectsTotalSuccess = (data) => ({ type: FETCH_MY_OBJECTS_TOTAL_SUCCESS, data });

const fetchMyObjectsStart = () => ({ type: FETCH_MY_OBJECTS_START });

const fetchMyObjectsFail = (error) => ({ type: FETCH_MY_OBJECTS_FAIL, error });

export const fetchRealEstateObjectTotalCountStart = () => ({ type: FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_START });

export const fetchRealEstateObjectTotalCountFail = () => ({ type: FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_FAIL });

export const fetchRealEstateObjectTotalCountSuccess = (data) => ({
  type: FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_SUCCESS,
  data,
});

const fetchMyObjectsSuccess = (data) => ({
  type: FETCH_MY_OBJECTS_SUCCESS,
  data: {
    ...data,
    result: data.result.map((realEstateObject) => {
      const client = realEstateObject.client
        ? new Client({
            ...realEstateObject.client,
            telephoneNumber: TelephoneNumberBuilder.buildFromServerData(realEstateObject.client.telephoneNumber),
          })
        : null;
      return {
        ...realEstateObject,
        client,
      };
    }),
  },
});

export const fetchMyObjects = (params) => (dispatch) => {
  dispatch(fetchMyObjectsStart());
  ObjectResource.getObjectsList(params)
    .then((response) => dispatch(fetchMyObjectsSuccess(response)))
    .catch((error) => dispatch(fetchMyObjectsFail(error)));
};

export const fetchMyObjectsTotal = (params) => (dispatch, getState) => {
  dispatch(fetchMyObjectsTotalStart());
  dispatch(fetchMyObjectsStart());
  ObjectResource.getObjectsTotal()
    .then((data) => {
      dispatch(fetchMyObjectsTotalSuccess(data));
      if (data.total) fetchMyObjects(params)(dispatch, getState);
    })
    .catch((error) => dispatch(fetchMyObjectsTotalFail(error)));
};

export const deleteObject = (realEstateObject, params) => (dispatch) => {
  dispatch(deleteObjectStart());
  ObjectResource.deleteObject(realEstateObject.id)
    .then(() => {
      dispatch(deleteObjectSuccess());
      dispatch(fetchMyObjects(params));
    })
    .catch((err) => {
      dispatch(deleteObjectFail(err));
    });
};

export const getMyRealEstateObjectTotalCount = () => (dispatch) => {
  dispatch(fetchRealEstateObjectTotalCountStart());
  ObjectResource.getMyObjectsTotal()
    .then((data) => {
      dispatch(fetchRealEstateObjectTotalCountSuccess(data));
    })
    .catch((err) => {
      dispatch(fetchRealEstateObjectTotalCountFail(err));
    });
};

export const dropMyRealEstateObjectsTotalCount = () => (dispatch) =>
  dispatch(fetchRealEstateObjectTotalCountSuccess({ totalCount: null }));
