import {
  buildRealEstateObjectFromServer,
  realEstateObjectToDomainFactory,
} from 'realtor/domain/factories/realEstateObjectToDomainFactory';
import RealEstateObjectResource from 'realtor/resourses/RealEstateObject.resource';
import { CreateRealEstateRequestDataBuilder } from 'realtor/helpers/requestDataBuildes';
import Alert from 'react-s-alert';
import { TelephoneNumberBuilder, Client } from 'realtor/domain';
import { get } from 'lodash';
import { UpdateRealEstateRequestDataBuilder } from 'realtor/helpers/requestDataBuildes/UpdateRealEstateRequestDataBuilder';
import i18n from '../../../common/i18n';
import { onRedirect } from '../../actions/redirect';
import { FormDataHelper } from '../../helpers/FormDataHelper';

export const DELETE_OBJECT_IMAGE_START = 'DELETE_OBJECT_IMAGE_START';
export const DELETE_OBJECT_IMAGE_SUCCESS = 'DELETE_OBJECT_IMAGE_SUCCESS';
export const DELETE_OBJECT_IMAGE_FAIL = 'DELETE_OBJECT_IMAGE_FAIL';
export const CREATE_REAL_ESTATE_OBJECT_START = 'CREATE_REAL_ESTATE_OBJECT_START';
export const CREATE_REAL_ESTATE_OBJECT_FAIL = 'CREATE_REAL_ESTATE_OBJECT_FAIL';
export const CREATE_REAL_ESTATE_OBJECT_SUCCESS = 'CREATE_REAL_ESTATE_OBJECT_SUCCESS';
export const UPDATE_REAL_ESTATE_OBJECT_START = 'UPDATE_REAL_ESTATE_OBJECT_START';
export const UPDATE_REAL_ESTATE_OBJECT_FAIL = 'UPDATE_REAL_ESTATE_OBJECT_FAIL';
export const UPDATE_REAL_ESTATE_OBJECT_SUCCESS = 'UPDATE_REAL_ESTATE_OBJECT_SUCCESS';
export const FETCH_MY_REAL_ESTATE_OBJECT_START = 'FETCH_MY_REAL_ESTATE_OBJECT_START';
export const FETCH_MY_REAL_ESTATE_OBJECT_FAIL = 'FETCH_MY_REAL_ESTATE_OBJECT_FAIL';
export const FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS = 'FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS';
export const ADD_OBJECT_IMAGES_START = 'ADD_OBJECT_IMAGES_START';
export const ADD_OBJECT_IMAGES_SUCCESS = 'ADD_OBJECT_IMAGES_SUCCESS';
export const ADD_OBJECT_IMAGES_FAIL = 'ADD_OBJECT_IMAGES_FAIL';

const deleteImageObjectStart = () => ({ type: DELETE_OBJECT_IMAGE_START });

const deleteImageObjectFail = (error) => ({
  type: DELETE_OBJECT_IMAGE_FAIL,
  error,
});

const deleteImageObjectSuccess = (id) => ({
  type: DELETE_OBJECT_IMAGE_SUCCESS,
  id,
});

const updateRealEstateObjectSuccess = () => ({ type: UPDATE_REAL_ESTATE_OBJECT_SUCCESS });

const updateRealEstateObjectStart = () => ({ type: UPDATE_REAL_ESTATE_OBJECT_START });

const updateRealEstateObjectFail = () => ({ type: UPDATE_REAL_ESTATE_OBJECT_FAIL });

const createRealEstateObjectFail = () => ({ type: CREATE_REAL_ESTATE_OBJECT_FAIL });

const createRealEstateObjectStart = () => ({ type: CREATE_REAL_ESTATE_OBJECT_START });

const createRealEstateObjectSuccess = () => ({ type: CREATE_REAL_ESTATE_OBJECT_SUCCESS });

const getMyRealEstateObjectStart = () => ({ type: FETCH_MY_REAL_ESTATE_OBJECT_START });

const getMyRealEstateObjectError = (error) => ({ type: FETCH_MY_REAL_ESTATE_OBJECT_FAIL, error });

const getMyRealEstateObjectSuccess = (realEstateObject) => ({
  type: FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS,
  realEstateObject,
});

const addImageStart = () => ({ type: ADD_OBJECT_IMAGES_START });

const addImageFail = (error) => ({ type: ADD_OBJECT_IMAGES_FAIL, error });

const addImageSuccess = (photos) => ({ type: ADD_OBJECT_IMAGES_SUCCESS, payload: photos });

// TODO: Add possibility to load photos throw single request
export const createRealEstateObject = (realEstateObject, appConfigurations) => (dispatch) => {
  const realEstateObjectDomain = realEstateObjectToDomainFactory(
    realEstateObject,
    appConfigurations.objectFilters.objectType,
  );

  const requestData = CreateRealEstateRequestDataBuilder.build({ realEstateObjectDomain });

  dispatch(createRealEstateObjectStart());

  RealEstateObjectResource.create(requestData)
    .then((response) => {
      const formData = FormDataHelper.buildFormDataForSaveObjectPhotos(realEstateObject.photos);
      RealEstateObjectResource.addObjectPhotos(response.id, formData).finally(() => {
        dispatch(createRealEstateObjectSuccess());
        Alert.success(i18n.t('REAL_ESTATE_OBJECT_SUCCESSFULLY_CREATED'), {
          position: 'top-right',
          effect: 'slide',
          timeout: 3000,
        });
        return dispatch(onRedirect(`/real-estate-object/${response.id}`));
      });
    })
    .catch((e) => {
      Alert.error(e, { position: 'top-right', effect: 'slide', timeout: 3000 });
      dispatch(createRealEstateObjectFail());
    });
};

export const updateRealEstateObject = (realEstateObjectId, realEstateObject, appConfigurations) => (dispatch) => {
  const realEstateObjectDomain = realEstateObjectToDomainFactory(
    realEstateObject,
    appConfigurations.objectFilters.objectType,
  );

  const requestData = UpdateRealEstateRequestDataBuilder.build({ realEstateObjectDomain });

  dispatch(updateRealEstateObjectStart());

  RealEstateObjectResource.update(realEstateObjectId, requestData)
    .then(() => {
      Alert.success(i18n.t('REAL_ESTATE_OBJECT_SUCCESSFULLY_UPDATED'), {
        position: 'top-right',
        effect: 'slide',
        timeout: 3000,
      });

      dispatch(updateRealEstateObjectSuccess());

      return dispatch(onRedirect(`/real-estate-object/${realEstateObjectId}`));
    })
    .catch((e) => {
      Alert.error(e, { position: 'top-right', effect: 'slide', timeout: 3000 });
      dispatch(updateRealEstateObjectFail());
    });
};

export const getRealEstateObjectDetails = (id, appConfigurations) => (dispatch) => {
  dispatch(getMyRealEstateObjectStart());

  RealEstateObjectResource.getRealEstateObjectDetails(id)
    .then(({ client, ...realEstateObject }) => {
      let clientDomain = null;
      if (client) {
        clientDomain = new Client({
          ...client,
          telephoneNumber: TelephoneNumberBuilder.buildFromServerData(client.telephoneNumber),
        });
      }

      const realEstateObjectDomain = buildRealEstateObjectFromServer(
        {
          ...realEstateObject,
          client: clientDomain,
        },
        appConfigurations.objectFilters.objectType,
      );

      dispatch(getMyRealEstateObjectSuccess(realEstateObjectDomain.props));
    })
    .catch(getMyRealEstateObjectError);
};

export const getMyRealEstateObject = (id, appConfigurations) => (dispatch) => {
  dispatch(getMyRealEstateObjectStart());

  RealEstateObjectResource.getMyOne(id)
    .then(({ client, ...realEstateObject }) => {
      let clientDomain = null;
      if (client) {
        clientDomain = new Client({
          ...client,
          telephoneNumber: TelephoneNumberBuilder.buildFromServerData(client.telephoneNumber),
          moreTelephoneNumbers: client.moreTelephoneNumbers.map((moreTelephoneNumber) =>
            TelephoneNumberBuilder.buildFromServerData(moreTelephoneNumber),
          ),
        });
      }

      const realEstateObjectDomain = buildRealEstateObjectFromServer(
        {
          ...realEstateObject,
          client: clientDomain,
        },
        appConfigurations.objectFilters.objectType,
      );

      dispatch(getMyRealEstateObjectSuccess(realEstateObjectDomain.props));
    })
    .catch(getMyRealEstateObjectError);
};

export const addObjectPhotos = (id, photos) => (dispatch) => {
  const formData = FormDataHelper.buildFormDataForSaveObjectPhotos(photos);
  dispatch(addImageStart());
  RealEstateObjectResource.addObjectPhotos(id, formData)
    .then((photos) => dispatch(addImageSuccess(photos)))
    .catch((err) => {
      dispatch(addImageFail(get(err, 'response.data.error', null)));
    });
};

export const deleteObjectPhoto = (objectId, imageId) => (dispatch) => {
  dispatch(deleteImageObjectStart());
  RealEstateObjectResource.deleteObjectImage(objectId, imageId)
    .then((response) => {
      dispatch(deleteImageObjectSuccess(Number(response.id)));
    })
    .catch((err) => {
      dispatch(deleteImageObjectFail(get(err, 'response.data.error', null)));
    });
};

export const dropFiltersCount = (objectId) => () => {
  RealEstateObjectResource.dropFiltersCount(objectId);
};
