import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN } from '../../../Config';
export const appConfigurationApi = createApi({
    reducerPath: 'appConfiguration',
    baseQuery: fetchBaseQuery({ baseUrl: `${DOMAIN}/v1/app/` }),
    endpoints: (builder) => ({
        getAppConfiguration: builder.query({
            query: () => `params`,
            transformResponse(baseQueryReturnValue) {
                return {
                    ...baseQueryReturnValue,
                    isMobile: window.innerWidth < 600,
                };
            },
        }),
    }),
});
export const { useGetAppConfigurationQuery } = appConfigurationApi;
