import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  RoomsCountFilter,
  CurrencyFilter,
  TotalPriceFilter,
  AreaFilter,
  CitiesFilter,
  PartialServiceTypeFilter,
  ServiceTypeFilter,
  MaterialFilter,
  KitchenAreaFilter,
  LivingAreaFilter,
  LandAreaFilter,
  ConditionFilter,
  FlooringFilter,
  MortgageFilter,
  LastFloorFilter,
  SquarePriceFilter,
  SubtypeFilter,
  FloorFilter,
  LandAreaPriceFilter,
} from '../Filters';
import i18n from '../../../../../common/i18n';

const MAX_NUMBER_VALUE = 9999999999;

// eslint-disable-next-line max-statements
export const FiltersByType = ({
  onInputChange,
  filtersList,
  filter,
  onSelectButtonChange,
  onFilterChange,
  filterErrors,
  citiesList,
  onCityChange,
  onDistrictChange,
}) => {
  const handleNumberParamsChange = ({ target }) => {
    const valueAsNumber = Number(target.value);
    const data = {
      name: target.name,
      value: valueAsNumber > 0 ? (Number(valueAsNumber) > MAX_NUMBER_VALUE ? MAX_NUMBER_VALUE : target.value) : null,
    };

    onInputChange({ target: data });
  };

  const renderServiceTypeFilter = (
    <ServiceTypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />
  );

  const renderSubtypeFilter = (
    <SubtypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />
  );

  const renderMortgageFilter = <MortgageFilter filter={filter} onFilterChange={onFilterChange} />;

  const renderLastFloorFilter = <LastFloorFilter filter={filter} onFilterChange={onFilterChange} />;

  const renderPartialServiceTypeFilter = (
    <PartialServiceTypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />
  );

  const renderRoomsCount = (
    <RoomsCountFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />
  );

  const renderMaterial = (
    <MaterialFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />
  );

  const renderCondition = (
    <ConditionFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />
  );

  const renderCurrency = <CurrencyFilter filtersList={filtersList} filter={filter} onFilterChange={onFilterChange} />;

  const renderTotalPriceFilter = (
    <TotalPriceFilter filter={filter} filterErrors={filterErrors} onInputChange={handleNumberParamsChange} />
  );

  const renderSquarePriceFilter = (
    <SquarePriceFilter filter={filter} filterErrors={filterErrors} onInputChange={handleNumberParamsChange} />
  );

  const renderLandAreaPriceFilter = (
    <LandAreaPriceFilter filter={filter} filterErrors={filterErrors} onInputChange={handleNumberParamsChange} />
  );

  const renderAreaFilter = (placeholder) => (
    <AreaFilter
      filter={filter}
      filterErrors={filterErrors}
      onInputChange={handleNumberParamsChange}
      placeholder={placeholder}
    />
  );

  const renderFlooringFilter = (
    <FlooringFilter filter={filter} filterErrors={filterErrors} onInputChange={handleNumberParamsChange} />
  );

  const renderFloorFilter = (
    <FloorFilter filter={filter} filterErrors={filterErrors} onInputChange={handleNumberParamsChange} />
  );

  const renderLandAreaFilter = (
    <LandAreaFilter filter={filter} filterErrors={filterErrors} onInputChange={handleNumberParamsChange} />
  );

  const renderKitchenAreaFilter = (
    <KitchenAreaFilter filter={filter} filterErrors={filterErrors} onInputChange={handleNumberParamsChange} />
  );

  const renderLivingAreaFilter = (
    <LivingAreaFilter filter={filter} filterErrors={filterErrors} onInputChange={handleNumberParamsChange} />
  );

  const renderCitiesFilter = (
    <CitiesFilter
      citiesList={citiesList}
      filter={filter}
      onCityChange={onCityChange}
      onDistrictChange={onDistrictChange}
    />
  );

  const isMortgageVisible = filter.objectServiceType && filter.objectServiceType === filtersList.objectServiceType.BUY;

  switch (filter.objectType) {
    case filtersList.objectType.APARTMENT:
      return (
        <>
          {renderServiceTypeFilter}
          {renderSubtypeFilter}
          {isMortgageVisible && renderMortgageFilter}
          {renderLastFloorFilter}
          {renderFloorFilter}
          {renderRoomsCount}
          {renderCurrency}
          {renderTotalPriceFilter}
          {renderSquarePriceFilter}
          {renderAreaFilter()}
          {renderKitchenAreaFilter}
          {renderLivingAreaFilter}
          {renderCondition}
          {renderCitiesFilter}
          {renderMaterial}
        </>
      );
    case filtersList.objectType.HOUSE_OR_COUNTRY_HOUSE:
      return (
        <>
          {renderServiceTypeFilter}
          {renderSubtypeFilter}
          {isMortgageVisible && renderMortgageFilter}
          {renderFlooringFilter}
          {renderRoomsCount}
          {renderCurrency}
          {renderTotalPriceFilter}
          {renderSquarePriceFilter}
          {renderAreaFilter()}
          {renderKitchenAreaFilter}
          {renderLivingAreaFilter}
          {renderLandAreaFilter}
          {renderCitiesFilter}
          {renderMaterial}
          {renderCondition}
        </>
      );
    case filtersList.objectType.COMMERCIAL:
      return (
        <>
          {renderPartialServiceTypeFilter}
          {renderSubtypeFilter}
          {renderLastFloorFilter}
          {renderFloorFilter}
          {renderCurrency}
          {renderTotalPriceFilter}
          {renderSquarePriceFilter}
          {renderAreaFilter()}
          {renderCitiesFilter}
          {renderMaterial}
          {renderCondition}
        </>
      );
    case filtersList.objectType.LAND_PLOT:
      return (
        <>
          {renderPartialServiceTypeFilter}
          {renderCurrency}
          {renderTotalPriceFilter}
          {renderLandAreaPriceFilter}
          {renderAreaFilter(i18n.t('LAND_AREA_PLACEHOLDER'))}
          {renderCitiesFilter}
        </>
      );
    case filtersList.objectType.GARAGE_PARKING:
      return (
        <>
          {renderPartialServiceTypeFilter}
          {renderSubtypeFilter}
          {renderCurrency}
          {renderTotalPriceFilter}
          {renderCitiesFilter}
        </>
      );
    default:
      return <></>;
  }
};

export default FiltersByType;

FiltersByType.propTypes = {
  filtersList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  filterErrors: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onSelectButtonChange: PropTypes.func.isRequired,
  citiesList: PropTypes.array.isRequired,
};
