import { combineReducers } from 'redux';
import {
  user,
  organization,
  client,
  clientList,
  myObjectsList,
  objectsList,
  clientFilter,
  tag,
  realEstateObject,
  realEstateObjectsTotalCount,
} from 'realtor/reducers';
import myObjects from './myObjects';
import redirect from './redirect';

import { login, confirmRegistration, sharedSingleRealEstateObject } from '../allAccess/reducers';

import { appConfigurationApi } from '../allAccess/services/appConfigurationService';
import { registrationApi } from '../allAccess/modules/Authorization/store/services/registrationApi';

const allReducers = combineReducers({
  login,
  myObjects,
  confirmRegistration,
  user,
  organization,
  client,
  clientList,
  myObjectsList,
  objectsList,
  redirect,
  clientFilter,
  tag,
  realEstateObject,
  sharedSingleRealEstateObject,
  realEstateObjectsTotalCount,
  [appConfigurationApi.reducerPath]: appConfigurationApi.reducer,
  [registrationApi.reducerPath]: registrationApi.reducer,
});

export default allReducers;
