import React from 'react';
import PropTypes from 'prop-types';
import RangeTextInput from 'realtor/components/common/RangeTextInput/RangeTextInput';
import i18n from '../../../../../common/i18n';

export const LandAreaRangeFilter = ({ onSelect, filter, errors }) => (
  <RangeTextInput
    valueFrom={filter.landAreaFrom}
    valueTo={filter.landAreaTo}
    nameFrom="landAreaFrom"
    nameTo="landAreaTo"
    onFieldChange={onSelect}
    placeholderFrom={i18n.t('LAND_AREA_PLACEHOLDER')}
    placeholderTo={i18n.t('LAND_AREA_PLACEHOLDER')}
    label={i18n.t('LAND_AREA')}
    error={errors.landArea}
    errorFrom={errors.landAreaFrom}
    errorTo={errors.landAreaTo}
  />
);

LandAreaRangeFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
