import User from '../resourses/User.resource';
import { TelephoneNumberBuilder, UserProfile } from '../realtor/domain';

export const FETCH_USER_INFO_START = 'FETCH_USER_INFO_START';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_FAIL = 'FETCH_USER_INFO_FAIL';

const fetchUserInfoStart = () => ({ type: FETCH_USER_INFO_START });

const fetchUserInfoFail = (err) => ({
  type: FETCH_USER_INFO_FAIL,
  err,
});

export const fetchUserInfoSuccess = (profile) => ({
  type: FETCH_USER_INFO_SUCCESS,
  profile: new UserProfile({
    ...profile,
    telephoneNumber: TelephoneNumberBuilder.buildFromFormData(profile.telephoneNumber),
    moreTelephoneNumbers: profile.moreTelephoneNumbers
      ? profile.moreTelephoneNumbers.map((telephoneNumber) =>
          TelephoneNumberBuilder.buildFromFormData(telephoneNumber.telephoneNumber, telephoneNumber.id),
        )
      : [],
  }),
});

export const fetchUserInfo = (data) => (dispatch) => {
  dispatch(fetchUserInfoStart());
  User.fetchUserInfo(data)
    .then((profile) => {
      dispatch(fetchUserInfoSuccess(profile));
    })
    .catch((e) => {
      dispatch(fetchUserInfoFail(e));
    });
};
