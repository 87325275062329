import { isArray } from 'lodash';
import i18n from '../../app/i18n';

const OPERATOR_CODES = [
  '050',
  '066',
  '095',
  '099',
  '067',
  '068',
  '096',
  '097',
  '098',
  '063',
  '093',
  '073',
  '094',
  '089',
  '092',
];

export class FormValidation {
  static isFormValid(errors) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in errors) {
      const error = errors[key];
      const isFormHasError = isArray(error) ? Boolean(error.filter((e) => Boolean(e)).length) : Boolean(error);

      if (isFormHasError) return false;
    }

    return true;
  }

  static validateRequired = (value) => (!value ? i18n.t('FIELD_REQUIRED') : null);

  static validateTelephoneNumber = (telephoneNumber) => {
    const operatorCode = telephoneNumber.split('(')[1].split(')')[0];
    if (!OPERATOR_CODES.includes(operatorCode)) {
      return i18n.t('INVALID_OPERATOR_CODE');
    }

    const numbers = telephoneNumber.match(/\d/g) || [];
    if (numbers.join('').length < 12) {
      return i18n.t('INVALID_TELEPHONE_NUMBER');
    }

    return null;
  };

  static validateConfirmationCode = (confirmationCode) => {
    const numbers = confirmationCode.match(/\d/g) || [];
    if (numbers.join('').length < 7) {
      return i18n.t('INVALID_CONFIRMATION_CODE');
    }

    return null;
  };

  static emailValidation = (email) => {
    if (!email) {
      return i18n.t('FIELD_REQUIRED');
    }

    const re =
      /* eslint-disable-next-line */
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      return i18n.t('INVALID_EMAIL');
    }

    return null;
  };

  static validateLength(value, minLength, maxLength) {
    if (!value) return null;

    if (maxLength && maxLength < value.length) {
      return i18n.t('INVALID_MAX_LENGTH', { maxLength });
    }

    if (minLength && minLength > value.length) {
      return i18n.t('INVALID_MIN_LENGTH', { minLength });
    }

    return null;
  }

  // eslint-disable-next-line consistent-return
  static validateName(value) {
    const regex = /^[A-Za-zА-Яа-яёЁїЇґҐІіЄєʼ]+(?:\s[A-Za-zА-Яа-яёЁїЇґҐІіЄєʼ]+){0,3}$/;

    if (!regex.test(value)) {
      return i18n.t('INVALID_CHARACTERS_NAME');
    }
  }

  // eslint-disable-next-line consistent-return
  static validateNameWithSpaces(value) {
    const regex = /^[A-Za-zА-Яа-яёЁїЇґҐІіЄєʼ]+(?:[ -][A-Za-zА-Яа-яёЁїЇґҐІіЄєʼ]+){0,3}$/;

    if (!regex.test(value)) {
      return i18n.t('INVALID_CHARACTERS_NAME');
    }
  }

  // eslint-disable-next-line consistent-return
  static validateOrganization(value) {
    const regex = /^(?!.*[-_!?&@'\s]{2,})[A-Za-zА-Яа-яёЁїЇґҐІіЄє0-9ʼ_!\-?&@'\s]+$/;

    if (!regex.test(value)) {
      return i18n.t('INVALID_CHARACTERS_NAME');
    }
  }
}
