import { Box } from '@material-ui/core';
import React from 'react';
import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';

const styles = {
  position: 'absolute',
  width: '100%',
  display: 'block',
  zIndex: 999999,
  top: '0px',
  background: 'rgba(0, 0, 0, 0.5)',
  left: '0px',
  right: '0px',
  bottom: '0px',
};

export const FullScreenLoader = () => (
  <Box style={styles}>
    <CircularProgressBox />
  </Box>
);
