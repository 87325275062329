import Alert from 'react-s-alert';
import { get } from 'lodash';
import { Client, TelephoneNumberBuilder } from 'realtor/domain';
import { buildRealEstateObjectFromServer } from 'realtor/domain/factories/realEstateObjectToDomainFactory';
import ObjectResource from '../resourses/Object.resource';
import { FormDataHelper } from '../helpers/FormDataHelper';
import i18n from '../../common/i18n';

export const FETCH_OBJECT_START = 'FETCH_OBJECT_START';
export const FETCH_OBJECT_SUCCESS = 'FETCH_OBJECT_SUCCESS';
export const FETCH_OBJECT_FAIL = 'FETCH_OBJECT_FAIL';

export const DELETE_OBJECT_START = 'DELETE_OBJECT_START';
export const DELETE_OBJECT_SUCCESS = 'DELETE_OBJECT_SUCCESS';
export const DELETE_OBJECT_FAIL = 'DELETE_OBJECT_FAIL';

export const CREATE_OBJECT_START = 'DELETE_OBJECT_START';
export const CREATE_OBJECT_SUCCESS = 'CREATE_OBJECT_SUCCESS';
export const CREATE_OBJECT_FAIL = 'CREATE_OBJECT_FAIL';

export const UPDATE_OBJECT_START = 'UPDATE_OBJECT_START';
export const UPDATE_OBJECT_SUCCESS = 'UPDATE_OBJECT_SUCCESS';
export const UPDATE_OBJECT_FAIL = 'UPDATE_OBJECT_FAIL';

export const DELETE_OBJECT_IMAGE_START = 'DELETE_OBJECT_IMAGE_START';
export const DELETE_OBJECT_IMAGE_SUCCESS = 'DELETE_OBJECT_IMAGE_SUCCESS';
export const DELETE_OBJECT_IMAGE_FAIL = 'DELETE_OBJECT_IMAGE_FAIL';

export const ADD_OBJECT_IMAGES_START = 'ADD_OBJECT_IMAGES_START';
export const ADD_OBJECT_IMAGES_SUCCESS = 'DELETE_OBJECT_IMAGE_SUCCESS';
export const ADD_OBJECT_IMAGES_FAIL = 'ADD_OBJECT_IMAGES_FAIL';

const fetchObjectStart = () => ({ type: FETCH_OBJECT_START });

const fetchObjectFail = (error) => ({
  type: FETCH_OBJECT_FAIL,
  error,
});

const fetchObjectSuccess = (data) => ({
  type: FETCH_OBJECT_SUCCESS,
  data,
});

const addImageStart = () => ({ type: ADD_OBJECT_IMAGES_START });

const addImageFail = (error) => ({ type: ADD_OBJECT_IMAGES_FAIL, error });

const addImageSuccess = () => ({ type: ADD_OBJECT_IMAGES_SUCCESS });

const deleteObjectStart = () => ({ type: DELETE_OBJECT_START });

const deleteObjectFail = (error) => ({
  type: DELETE_OBJECT_FAIL,
  error,
});

const deleteObjectSuccess = () => {
  location.href = '/';
  return {
    type: DELETE_OBJECT_SUCCESS,
  };
};

const createObjectStart = () => ({ type: CREATE_OBJECT_START });

const createObjectFail = (error) => ({
  type: CREATE_OBJECT_FAIL,
  error,
});

const createObjectSuccess = () => {
  location.href = '/';
  return {
    type: CREATE_OBJECT_SUCCESS,
  };
};

const updateObjectStart = () => ({ type: UPDATE_OBJECT_START });

const updateObjectFail = (error) => ({
  type: UPDATE_OBJECT_FAIL,
  error,
});

const updateObjectSuccess = () => ({
  type: UPDATE_OBJECT_SUCCESS,
});

const deleteImageObjectStart = () => ({ type: DELETE_OBJECT_IMAGE_START });

const deleteImageObjectFail = (error) => ({
  type: DELETE_OBJECT_IMAGE_FAIL,
  error,
});

const deleteImageObjectSuccess = (id) => ({
  type: DELETE_OBJECT_IMAGE_SUCCESS,
  id,
});

export const fetchObject = (id) => (dispatch) => {
  dispatch(fetchObjectStart());
  ObjectResource.fetchObject(id)
    .then((response) => {
      dispatch(fetchObjectSuccess(response));
    })
    .catch((err) => {
      dispatch(fetchObjectFail(get(err, 'response.data.error', null)));
    });
};

export const fetchPublicObject = (id) => (dispatch) => {
  dispatch(fetchObjectStart());
  ObjectResource.fetchPublicObject(id)
    .then((response) => {
      dispatch(fetchObjectSuccess(response));
    })
    .catch((err) => {
      dispatch(fetchObjectFail(get(err, 'response.data.error', null)));
    });
};

export const fetchObjectByHash = (id) => (dispatch, getState) => {
  const state = getState();

  dispatch(fetchObjectStart());
  ObjectResource.fetchObjectByHash(id)
    .then(({ client, ...realEstateObject }) => {
      let clientDomain = null;
      if (client) {
        clientDomain = new Client({
          ...client,
          telephoneNumber: TelephoneNumberBuilder.buildFromServerData(client.telephoneNumber),
          moreTelephoneNumbers: [],
        });
      }

      const realEstateObjectDomain = buildRealEstateObjectFromServer(
        {
          ...realEstateObject,
          client: clientDomain,
        },
        state.appConfigurations.objectFilters.objectType,
      );
      dispatch(fetchObjectSuccess(realEstateObjectDomain));
    })
    .catch((err) => {
      dispatch(fetchObjectFail(get(err, 'response.data.error', null)));
    });
};

export const deleteObject = (id) => (dispatch) => {
  dispatch(deleteObjectStart());
  ObjectResource.deleteObject(id)
    .then(() => {
      dispatch(deleteObjectSuccess());
    })
    .catch((err) => {
      dispatch(deleteObjectFail(err));
    });
};

export const saveNewObject = (data) => (dispatch) => {
  dispatch(createObjectStart());
  ObjectResource.saveNewObject(data)
    .then(() => {
      dispatch(createObjectSuccess());
    })
    .catch((err) => {
      dispatch(createObjectFail(err));
    });
};

export const updateObject = (id, data) => (dispatch) => {
  dispatch(updateObjectStart());
  ObjectResource.updateObject(id, data)
    .then(() => {
      Alert.success(i18n.t('OBJECT_UPDATED_SUCCESS'), { position: 'top', effect: 'slide', timeout: 3000 });
      dispatch(updateObjectSuccess());
    })
    .catch((err) => {
      dispatch(updateObjectFail(err));
    })
    .finally(() => fetchObject(id)(dispatch));
};

export const deleteObjectPhoto = (objectId, imageId) => (dispatch) => {
  dispatch(deleteImageObjectStart());
  ObjectResource.deleteObjectImage(objectId, imageId)
    .then((response) => {
      dispatch(deleteImageObjectSuccess(Number(response.id)));
    })
    .catch((err) => {
      dispatch(deleteImageObjectFail(get(err, 'response.data.error', null)));
    });
};

export const addObjectPhotos = (id, photos) => (dispatch) => {
  const formData = FormDataHelper.buildFormDataForSaveObjectPhotos(photos);
  dispatch(addImageStart());
  ObjectResource.addObjectPhotos(id, formData)
    .then(() => dispatch(addImageSuccess()))
    .catch((err) => {
      dispatch(addImageFail(get(err, 'response.data.error', null)));
    })
    .finally(() => fetchObject(id)(dispatch));
};
