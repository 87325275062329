import React from 'react';
import PropTypes from 'prop-types';
import RangeTextInput from 'realtor/components/common/RangeTextInput/RangeTextInput';
import i18n from '../../../../../common/i18n';

export const AreaRangeFilter = ({ onSelect, filter, errors }) => (
  <RangeTextInput
    valueFrom={filter.areaFrom}
    valueTo={filter.areaTo}
    nameFrom="areaFrom"
    nameTo="areaTo"
    onFieldChange={onSelect}
    placeholderFrom={i18n.t('AREA_PLACEHOLDER')}
    placeholderTo={i18n.t('AREA_PLACEHOLDER')}
    label={i18n.t('TOTAL_AREA')}
    error={errors.area}
    errorFrom={errors.areaFrom}
    errorTo={errors.areaTo}
  />
);

AreaRangeFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
