import axios from '../../../common/axios';

class OrganizationResource {
  /**
   * Get organization list
   */
  getList(params) {
    return axios.get('/v1/realtor/organizations', params).then((res) => res.data);
  }
}

export default new OrganizationResource();
