import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import NoObjectsFound from 'realtor/components/common/ObjectListContent/layouts/NoObjectsFound/NoObjectsFound';
import ObjectsPageHeader from 'realtor/components/pages/RealEstateObjectPages/MyObjectsPage/layouts/ObjectsPageHeader/ObjectsPageHeader';
import ObjectsList from 'realtor/components/layears/ObjectsList/ObjectsList';
import ObjectFiltersBar from 'realtor/components/layears/ObjectFiltersBar/ObjectFiltersBar';
import DeleteRealEstateObjectDialog from 'realtor/components/dialogs/DeleteRealEstateObjectDialog/DeleteRealEstateObjectDialog';
import CustomSwitch from 'realtor/components/common/CustomSwitch/CustomSwitch';
import DeleteRealEstateFromFavoriteDialog from 'realtor/components/dialogs/DeleteRealEstateFromFavoriteDialog/DeleteRealEstateFromFavoriteDialog';
import { useHistory, useLocation } from 'react-router-dom';
import { getClientFilterParamsFromQuery } from 'realtor/components/layears/ClipClientFilterObjectsCount/helpers/searchUrl';
import { OrderKeys } from 'realtor/components/common/types';
import i18n from '../../../../../common/i18n';
import ObjectsControlPanel from '../../layears/ObjectsControlPanel/ObjectsControlPanel';
import { CircularProgressBox } from '../../layears/CircularProgressBox/CircularProgressBox';
import Pagination from '../../layears/Pagination/Pagination';
import { useGetAppConfigurationQuery } from '../../../../allAccess/services/appConfigurationService';
const DEFAULT_LIMITS = [10, 30];
const DEFAULT_OFFSET = 0;
const DEFAULT_PAGINATION = {
    limit: DEFAULT_LIMITS[0],
    offset: DEFAULT_OFFSET,
};
const DEFAULT_FILTERS = {
    search: '',
    showOnlyFavorites: false,
};
// eslint-disable-next-line max-statements
const ObjectList = ({ orderKeys, onDelete, isOwnerList, fetchObjects, removeRealEstateFromFavorite, classes, pageTitle, isCreateButtonDisabled, objectsWithoutFiltersTotalCount, objectList, listInProgress, totalCount, tagsList, fetchTagsList, orderList, inProgress, addRealEstateToFavorite, }) => {
    const { data: appConfigurations } = useGetAppConfigurationQuery();
    const history = useHistory();
    const { search } = useLocation();
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [filterBarState, setFilterBarState] = useState({});
    const [listFetchParams, setListFetchParams] = useState({
        pagination: DEFAULT_PAGINATION,
        filters: DEFAULT_FILTERS,
        paramsFilter: {},
        order: OrderKeys.FROM_NEWEST_TO_OLDER,
    });
    const handleFilterChange = (filters) => {
        setIsFiltersOpen(false);
        setListFetchParams({
            ...listFetchParams,
            paramsFilter: filters,
            pagination: {
                ...listFetchParams.pagination,
                offset: DEFAULT_OFFSET,
            },
        });
    };
    const getFetchParams = (params) => {
        const tagId = params.filters.tag ? params.filters.tag.id : undefined;
        const { tag, ...filters } = params.filters;
        return {
            ...params.pagination,
            ...filters,
            ...params.paramsFilter,
            ...orderList[params.order],
            tagId,
        };
    };
    const renderProgress = () => _jsx(CircularProgressBox, {});
    useEffect(() => {
        if (!isOwnerList && search) {
            const params = getClientFilterParamsFromQuery(search);
            setFilterBarState(params);
            if (search) {
                history.replace({
                    pathname: window.location.pathname,
                    state: history.location.state,
                });
            }
            setListFetchParams({
                ...listFetchParams,
                paramsFilter: params,
            });
            handleFilterChange(params);
            fetchObjects(getFetchParams({
                ...listFetchParams,
                paramsFilter: params,
            }));
        }
    }, [search]);
    useEffect(() => {
        if (!search) {
            fetchObjects(getFetchParams(listFetchParams));
        }
    }, [listFetchParams]);
    const [realEstateObjectDeleteDialog, setRealEstateObjectDeleteDialog] = useState({
        realEstateObjectToDelete: null,
        isDeleteDialogOpen: false,
    });
    const [realEstateObjectDeleteFromFavouriteDialog, setRealEstateObjectDeleteFromFavouriteDialog] = useState({
        realEstateObjectDeleteFromFavourite: null,
        isDeleteFromFavouriteDialogOpen: false,
    });
    const handleFiltersToggle = () => {
        setIsFiltersOpen(!isFiltersOpen);
    };
    const handleDeleteObjectCancel = () => {
        setRealEstateObjectDeleteDialog({
            realEstateObjectToDelete: null,
            isDeleteDialogOpen: false,
        });
    };
    const handleDeleteObjectConfirm = () => {
        const data = {
            realEstateObjectToDelete: null,
            isDeleteDialogOpen: false,
        };
        onDelete(realEstateObjectDeleteDialog.realEstateObjectToDelete, getFetchParams(listFetchParams));
        setRealEstateObjectDeleteDialog(data);
    };
    const handleLimitChange = (limit) => {
        const pagination = {
            limit,
            offset: 0,
        };
        setListFetchParams({
            ...listFetchParams,
            pagination,
        });
    };
    const handlePaginationBack = () => {
        const { pagination: { offset, limit }, } = listFetchParams;
        const pagination = {
            ...listFetchParams.pagination,
            offset: offset - limit,
        };
        setListFetchParams({
            ...listFetchParams,
            pagination,
        });
    };
    const handlePaginationForward = () => {
        const { pagination: { offset, limit }, } = listFetchParams;
        const pagination = {
            ...listFetchParams.pagination,
            offset: offset + limit,
        };
        setListFetchParams({
            ...listFetchParams,
            pagination,
        });
    };
    const handleSearchChange = (params) => {
        setListFetchParams({
            ...listFetchParams,
            pagination: {
                ...listFetchParams.pagination,
                offset: DEFAULT_OFFSET,
            },
            filters: {
                ...listFetchParams.filters,
                search: params.search,
                tag: get(params, 'tag', null),
            },
        });
    };
    const handleFilterReset = () => {
        setIsFiltersOpen(false);
        setListFetchParams({
            ...listFetchParams,
            paramsFilter: {},
            pagination: {
                ...listFetchParams.pagination,
                offset: DEFAULT_OFFSET,
            },
        });
    };
    const handleOrderChange = (order) => {
        setIsFiltersOpen(false);
        setListFetchParams({
            ...listFetchParams,
            order,
        });
    };
    const handleShowOnlyFavoritesChange = () => {
        const showOnlyFavorites = !listFetchParams.filters.showOnlyFavorites;
        const filters = {
            ...listFetchParams.filters,
            showOnlyFavorites,
        };
        setIsFiltersOpen(false);
        setListFetchParams({
            ...listFetchParams,
            pagination: {
                ...listFetchParams.pagination,
                offset: DEFAULT_OFFSET,
            },
            filters,
        });
    };
    const handleDeleteObject = (realEstateObject) => {
        setRealEstateObjectDeleteDialog({
            realEstateObjectToDelete: realEstateObject,
            isDeleteDialogOpen: true,
        });
    };
    const handleDeleteRealEstateFromFavoriteCancel = () => {
        setRealEstateObjectDeleteFromFavouriteDialog({
            realEstateObjectDeleteFromFavourite: null,
            isDeleteFromFavouriteDialogOpen: false,
        });
    };
    const handleDeleteRealEstateFromFavoriteConfirm = () => {
        if (removeRealEstateFromFavorite) {
            removeRealEstateFromFavorite(realEstateObjectDeleteFromFavouriteDialog.realEstateObjectDeleteFromFavourite);
            setRealEstateObjectDeleteFromFavouriteDialog({
                realEstateObjectDeleteFromFavourite: null,
                isDeleteFromFavouriteDialogOpen: false,
            });
        }
    };
    const handleDeleteRealEstateFromFavoriteOpen = (realEstateObject) => {
        setRealEstateObjectDeleteFromFavouriteDialog({
            realEstateObjectDeleteFromFavourite: realEstateObject,
            isDeleteFromFavouriteDialogOpen: true,
        });
    };
    const renderPagination = (isBottom = false) => (_jsx(Pagination, { isBottom: isBottom, limitsList: DEFAULT_LIMITS, onLimitChange: handleLimitChange, limit: listFetchParams.pagination.limit, offset: listFetchParams.pagination.offset, total: totalCount, onPaginationBack: handlePaginationBack, onPaginationForward: handlePaginationForward }));
    const renderObjectsList = () => (_jsxs(_Fragment, { children: [_jsx(ObjectsList, { addRealEstateToFavorite: addRealEstateToFavorite, removeRealEstateFromFavorite: handleDeleteRealEstateFromFavoriteOpen, objects: objectList, paramsList: appConfigurations.objectFilters, isOwnerList: isOwnerList, onSelectTag: handleSearchChange, onDelete: handleDeleteObject }), renderPagination(true)] }));
    const renderHeader = () => (_jsx(ObjectsPageHeader, { classes: classes, totalCount: objectsWithoutFiltersTotalCount, title: pageTitle, isCreateButtonDisabled: isCreateButtonDisabled }));
    const renderNoObjectsFound = () => _jsx(NoObjectsFound, { classes: classes });
    const renderOnlyFavoriteSwitcher = () => (_jsx(CustomSwitch, { checked: listFetchParams.filters.showOnlyFavorites, label: i18n.t('ONLY_FAVORITES'), onChange: handleShowOnlyFavoritesChange, name: "showOnlyFavorites", asButton: true }));
    const renderTopControls = () => {
        const isObjectListNotEmpty = Boolean(objectList.length);
        return (_jsxs(Grid, { className: classes.controlPanelBox, container: true, children: [_jsx(Grid, { className: classes.controlPanelBox, style: { padding: '10px 0px' }, item: true, container: true, md: 6, xs: 12, children: !isOwnerList && renderOnlyFavoriteSwitcher() }), _jsx(Grid, { item: true, md: 6, xs: 12, children: isObjectListNotEmpty && renderPagination() })] }));
    };
    const renderObjectsContent = () => {
        if (listInProgress) {
            return renderProgress();
        }
        const content = objectList.length ? renderObjectsList() : renderNoObjectsFound();
        return (_jsxs(_Fragment, { children: [renderTopControls(), content] }));
    };
    const renderContent = () => {
        const { paramsFilter, order } = listFetchParams;
        const { isDeleteDialogOpen, realEstateObjectToDelete } = realEstateObjectDeleteDialog;
        const { realEstateObjectDeleteFromFavourite, isDeleteFromFavouriteDialogOpen } = realEstateObjectDeleteFromFavouriteDialog;
        const contentStyle = isFiltersOpen ? classes.contentBoxWithOpenedFilter : classes.contentBox;
        const isFiltersButtonActive = !isEmpty(paramsFilter);
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: contentStyle, children: [renderHeader(), _jsxs(Paper, { className: classes.paper, elevation: 0, children: [_jsx(ObjectsControlPanel, { onSearchChange: handleSearchChange, onFiltersToggle: handleFiltersToggle, isFiltersButtonActive: isFiltersButtonActive, orderList: orderKeys, currentOrder: order, onOrderChange: handleOrderChange, selectedTag: listFetchParams.filters.tag, fetchTagsList: fetchTagsList, tagsList: tagsList }), renderObjectsContent()] })] }), _jsx(DeleteRealEstateObjectDialog, { isDeleteDialogOpen: isDeleteDialogOpen, onDialogClose: handleDeleteObjectCancel, realEstateObject: realEstateObjectToDelete, onDeleteConfirm: handleDeleteObjectConfirm }), _jsx(DeleteRealEstateFromFavoriteDialog, { isDeleteDialogOpen: isDeleteFromFavouriteDialogOpen, realEstateObject: realEstateObjectDeleteFromFavourite, onDialogClose: handleDeleteRealEstateFromFavoriteCancel, onDeleteConfirm: handleDeleteRealEstateFromFavoriteConfirm }), _jsx(ObjectFiltersBar, { filter: filterBarState, setFilters: setFilterBarState, paramsList: appConfigurations.objectFilters, exchangeRates: appConfigurations.exchangeRates, onFiltersToggle: handleFiltersToggle, isFiltersOpen: isFiltersOpen, filtersList: appConfigurations.objectFilters, onConfirm: handleFilterChange, onReset: handleFilterReset, citiesList: appConfigurations.citiesList })] }));
    };
    return inProgress ? renderProgress() : renderContent();
};
export default ObjectList;
