export default (theme) => ({
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  paper: {
    height: '100%',
    paddingBottom: theme.spacing(10),
    overflow: 'auto',
  },
  '@global': {
    '.MuiOutlinedInput-input': {
      paddingTop: `${theme.spacing(1)}px!important`,
      paddingBottom: `${theme.spacing(1)}px!important`,
    },
  },
});
