import axios from '../../../common/axios';

class FavoriteClientResource {
  /**
   * Remove real estate from favorite
   */
  removeRealEstateFromFavorite(realEstateObjectId) {
    return axios.delete(`/v1/realtor/real-estate/${realEstateObjectId}/favorite`, {}).then((res) => res.data);
  }

  /**
   * Add real estate to favorite
   */
  addRealEstateToFavorite(realEstateObjectId) {
    return axios.post(`/v1/realtor/real-estate/${realEstateObjectId}/favorite`, {}).then((res) => res.data);
  }
}

export default new FavoriteClientResource();
