import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Drawer } from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import i18n from '../../../../../common/i18n';
export const FiltersDrawerComponent = ({ classes, isFiltersOpen, onFiltersToggle, typeFilterComponent, filtersListByTypeComponent, onConfirm, onReset, clientFilterDrawer = false, }) => (_jsx(Drawer, { variant: "permanent", anchor: "right", className: clsx(classes.drawer, {
        [classes.drawerOpen]: isFiltersOpen,
        [classes.drawerClose]: !isFiltersOpen,
    }), classes: {
        paper: clsx({
            [classes.drawerOpen]: isFiltersOpen,
            [classes.drawerClose]: !isFiltersOpen,
            [classes.navigationBox]: true,
        }),
    }, children: _jsxs("div", { className: classes.toolbar, children: [_jsxs("div", { className: classes.toolbarHeader, children: [_jsx("h3", { className: classes.toolbarHeaderTitle, children: i18n.t('FILTERS') }), _jsx(CloseIcon, { className: classes.closeButton, onClick: onFiltersToggle })] }), _jsx("div", { children: clientFilterDrawer ? i18n.t('OBJECT_PARAMS') : i18n.t('FILTER_PARAMS') }), _jsxs("div", { className: classes.filterList, children: [typeFilterComponent, filtersListByTypeComponent] }), _jsxs("div", { className: classes.filterControl, children: [_jsx(Button, { color: "primary", type: "submit", className: classes.submit, onClick: onConfirm, children: i18n.t('APPLY_FILTERS') }), _jsx(Button, { className: classes.cancel, onClick: onReset, children: i18n.t('RESET_FILTERS') })] })] }) }));
const FilterDrawer = withStyles(styles)(FiltersDrawerComponent);
export default FilterDrawer;
