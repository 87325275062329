import React from 'react';
import PropTypes from 'prop-types';
import {
  RestoreClientWithThisTelephoneNumberDialog,
  ClientWithThisTelephoneNumberAlreadyExistDialog,
} from 'realtor/components/dialogs';

const ExistClientModal = ({ existClientData, isExistClientModalVisible, onDialogClose, onConfirmRestore }) =>
  existClientData.isDeleted ? (
    <RestoreClientWithThisTelephoneNumberDialog
      onDialogClose={onDialogClose}
      isDeleteDialogOpen={isExistClientModalVisible}
      confirmRestoreDialog={onConfirmRestore}
      client={existClientData}
    />
  ) : (
    <ClientWithThisTelephoneNumberAlreadyExistDialog
      client={existClientData}
      isDeleteDialogOpen={isExistClientModalVisible}
      onDialogClose={onDialogClose}
    />
  );

ExistClientModal.propTypes = {
  onDialogClose: PropTypes.func,
  onConfirmRestore: PropTypes.func,
  existClientData: PropTypes.object,
  isExistClientModalVisible: PropTypes.bool,
};

export default ExistClientModal;
