import { Box, Chip, FormControl, IconButton, InputAdornment, Input } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from 'realtor/components/forms/TagsForm/styles';
import PropTypes from 'prop-types';
import { SearchTag } from 'realtor/components/forms/TagsForm/components/SerchTags/SerchTags';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { DEFAULT_LOAD_LIST_PARAMS, DEFAULT_LOAD_TAGS_LIMIT, DEFAULT_LOAD_TAGS_OFFSET } from 'realtor/actions';
import i18n from '../../../../i18n';
import { useOutsideClose } from '../../../../../common/hooks';

const SEARCH_DELAY = 500;
const MAX_TAG_LENGTH = 20;

let debounceTimeout = null;

export const TagsForm = ({ classes, onLoadMoreTags, tag, assignedTags, onAssignTag, onAddNewTag }) => {
  const [loadTagParams, setLoadTagParams] = useState({ ...DEFAULT_LOAD_LIST_PARAMS });

  const [isSearchTagsVisible, setIsSearchTagsVisible] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClose(wrapperRef, () => {
    setIsSearchTagsVisible(false);
  });

  useEffect(() => {
    onLoadMoreTags(loadTagParams, true);
    setLoadTagParams({
      ...loadTagParams,
      offset: loadTagParams.offset + loadTagParams.limit,
    });
  }, []);

  const isTagsSearchVisible = assignedTags.length < 3;

  const handleLoadTags = () => {
    onLoadMoreTags(loadTagParams);
    setLoadTagParams({
      ...loadTagParams,
      offset: loadTagParams.offset + loadTagParams.limit,
    });
  };

  const handleAddNewTag = () => {
    onAddNewTag(loadTagParams.search);
    setLoadTagParams(DEFAULT_LOAD_LIST_PARAMS);
  };

  const handleToggleIsSearchTagsVisible = () => {
    setIsSearchTagsVisible(!isSearchTagsVisible);
  };

  const handleOpenSearchTags = () => {
    setIsSearchTagsVisible(true);
  };

  const handleSearchInputChange = (event) => {
    const searchValue = event.target.value
      .toLowerCase()
      .replace(/[^a-z0-9_а-яґєіїў ]/gi, '')
      .replace(/ /g, '_');

    if (searchValue.length > MAX_TAG_LENGTH || searchValue.length === loadTagParams.search.length) {
      return false;
    }

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const params = {
      search: searchValue,
      offset: DEFAULT_LOAD_TAGS_OFFSET,
      limit: DEFAULT_LOAD_TAGS_LIMIT,
    };
    setLoadTagParams(params);

    debounceTimeout = setTimeout(() => {
      onLoadMoreTags(params, true);
    }, SEARCH_DELAY);
  };

  const handleTagDelete = (chipToDelete) => () => {
    onAssignTag(chipToDelete);
  };

  return (
    <Box ref={wrapperRef} className={classes.tagsBlock}>
      <FormControl fullWidth margin="normal">
        <label>{i18n.t('TAGS')}</label>
        <Box className={classes.tagsBox}>
          {assignedTags.map((data) => (
            <div key={data.key}>
              <Chip label={data.title} onDelete={handleTagDelete(data)} />
            </div>
          ))}
        </Box>
        {isTagsSearchVisible && (
          <Input
            value={loadTagParams.search}
            onChange={handleSearchInputChange}
            onFocus={handleOpenSearchTags}
            endAdornment={
              <InputAdornment className={classes.searchTagsInputButton} position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleToggleIsSearchTagsVisible}>
                  {isSearchTagsVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </InputAdornment>
            }
          />
        )}
      </FormControl>
      {isSearchTagsVisible && isTagsSearchVisible && (
        <SearchTag
          classes={classes}
          onLoadTags={handleLoadTags}
          list={tag.tagsList}
          isLoading={tag.isLoading}
          onSelectTag={onAssignTag}
          assignedTags={assignedTags}
          searchTag={loadTagParams.search}
          onAddNewTag={handleAddNewTag}
        />
      )}
    </Box>
  );
};

TagsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
  onLoadMoreTags: PropTypes.func.isRequired,
  onAssignTag: PropTypes.func.isRequired,
  onAddNewTag: PropTypes.func.isRequired,
  assignedTags: PropTypes.any.isRequired,
};

export default withStyles(styles)(TagsForm);
