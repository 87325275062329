import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import i18n from '../../../../../common/i18n';
import Location from '../../common/Location/Location';

export const CitiesFilter = ({ citiesList, filter, onCityChange, onDistrictChange }) => (
  <Grid container xs={12} lg={12} sm={12}>
    <Location
      cityHeader={`${i18n.t('CITY')}:`}
      districtHeader={`${i18n.t('DISTRICT')}:`}
      regions={citiesList}
      onCityChange={onCityChange}
      onDistrictChange={onDistrictChange}
      cityId={filter.cityId}
      selectedDistrict={filter.districtId}
      gridParams={{
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        xs: 12,
      }}
    />
  </Grid>
);

CitiesFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  citiesList: PropTypes.array.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
};
