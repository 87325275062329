import { jsx as _jsx } from "react/jsx-runtime";
import { Carousel } from 'react-responsive-carousel';
import './CustomSlider.scss';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import styles from './styles';
import { DEFAULT_IMAGE_URL } from '../../../../services/imageServise';
const CustomSlider = ({ key, images = [], classes, objectId }) => {
    const photosListToView = images.length ? images.map(({ image }) => image.small) : [DEFAULT_IMAGE_URL];
    return (_jsx(Carousel, { autoPlay: false, showArrows: false, showStatus: false, showThumbs: false, showIndicators: images.length > 1, children: photosListToView.map((photo, i) => (_jsx(Link, { target: "_blank", to: `/real-estate-object/${objectId}`, children: _jsx("div", { className: classes.imageBox, style: { backgroundImage: `url("${photo}")` } }) }, i))) }, key));
};
export default withStyles(styles)(CustomSlider);
