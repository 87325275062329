import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import i18n from '../../../../../common/i18n';
import FiltersParamsFactory from './RealEstateObjectParams/RealEstateParamsFactory';
import SelectButtonByFilter from '../../common/SelectButtonByFilter/SelectButtonByFilter';

export const CreateRealEstateObjectForm = ({
  onRealEstateTypeSelect,
  filtersList,
  citiesList,
  onFilterRealEstateSelect,
  realEstateObject,
  onCityChange,
  onDistrictChange,
  errors,
  onServiceSelect,
  disableTypeSelect,
  onInputChange,
}) => {
  const renderTypeSelect = () => {
    const filterName = 'objectType';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_TYPE_';
    const header = i18n.t('SELECT_OBJECT_TYPE');

    return (
      <SelectButtonByFilter
        filter={realEstateObject}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        header={header}
        onSelect={onRealEstateTypeSelect}
        disabled={disableTypeSelect}
      />
    );
  };

  const renderParamsByType = () => (
    <FiltersParamsFactory
      filtersList={filtersList}
      realEstateObject={realEstateObject}
      onSelect={onFilterRealEstateSelect}
      citiesList={citiesList}
      onCityChange={onCityChange}
      onDistrictChange={onDistrictChange}
      onFilterServiceSelect={onServiceSelect}
      errors={errors}
      disableServiceType={disableTypeSelect}
      onInputChange={onInputChange}
    />
  );

  return (
    <div>
      <h2>{i18n.t('INFORMATION_ABOUT_NEW_REAL_ESTATE')}</h2>
      <form>
        {renderTypeSelect()}
        {renderParamsByType()}
      </form>
    </div>
  );
};

export default withStyles(styles)(CreateRealEstateObjectForm);

CreateRealEstateObjectForm.propTypes = {
  realEstateObject: PropTypes.object,
  errors: PropTypes.object,
  filtersList: PropTypes.object,
  citiesList: PropTypes.array.isRequired,
  onRealEstateTypeSelect: PropTypes.func.isRequired,
  onFilterRealEstateSelect: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onServiceSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  disableTypeSelect: PropTypes.bool,
};
