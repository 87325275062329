import {
  NullFilter,
  ApartmentFilter,
  HouseFilter,
  CommercialFilter,
  LandPlotFilter,
  GarageParkingFilter,
} from '../entities/Filter';
import { ItemCode, LocationBuilder } from '../index';

export const filterDomainFactory = (filter, realEstateTypes) => {
  switch (filter.objectType) {
    case realEstateTypes.APARTMENT:
      return new ApartmentFilter(filter);
    case realEstateTypes.HOUSE_OR_COUNTRY_HOUSE:
      return new HouseFilter(filter);
    case realEstateTypes.COMMERCIAL:
      return new CommercialFilter(filter);
    case realEstateTypes.LAND_PLOT:
      return new LandPlotFilter(filter);
    case realEstateTypes.GARAGE_PARKING:
      return new GarageParkingFilter(filter);
    default:
      return new NullFilter(null);
  }
};

const mapServerDataToDomainProps = (filter) => {
  const location = LocationBuilder.buildFromFiltersList(filter);

  return {
    id: filter.id,
    objectType: filter.realEstateType,
    objectServiceType: filter.realEstateServiceType,
    cityId: filter.cityId,
    districtId: filter.districtId,
    description: filter.description,
    currency: filter.currency,
    totalPriceFrom: filter.totalPriceFrom,
    totalPriceTo: filter.totalPriceTo,
    objectSubtype: filter.realEstateSubtype ? new Set(filter.realEstateSubtype) : null,
    areaFrom: filter.areaFrom,
    areaTo: filter.areaTo,
    kitchenAreaFrom: filter.kitchenAreaFrom,
    kitchenAreaTo: filter.kitchenAreaTo,
    flooringFrom: filter.flooringFrom,
    flooringTo: filter.flooringTo,
    floorFrom: filter.floorFrom,
    floorTo: filter.floorTo,
    squarePriceFrom: filter.squarePriceFrom,
    squarePriceTo: filter.squarePriceTo,
    roomsCount: filter.realEstateRoomsCount ? new Set(filter.realEstateRoomsCount) : null,
    objectCondition: filter.realEstateCondition ? new Set(filter.realEstateCondition) : null,
    landAreaFrom: filter.landAreaFrom,
    landAreaTo: filter.landAreaTo,
    objectMaterial: filter.realEstateMaterial ? new Set(filter.realEstateMaterial) : null,
    landAreaPriceFrom: filter.landAreaPriceFrom,
    landAreaPriceTo: filter.landAreaPriceTo,
    livingAreaFrom: filter.livingAreaFrom,
    livingAreaTo: filter.livingAreaTo,
    isMortgage: filter.isMortgage,
    ignoreLastFloor: filter.ignoreLastFloor,
    isLastFloor: filter.isLastFloor,
    location,
    code: new ItemCode(filter.code),
    hasNewRealEstateObjects: filter.hasNewRealEstateObjects,
    realEstateObjectsCount: filter.realEstateObjectsCount,
  };
};

const mapWithPartialServiceType = (filter) => ({
  ...mapServerDataToDomainProps(filter),
  objectPartialServiceType: filter.realEstateServiceType,
  objectServiceType: null,
});

export const serverFilterToDomainFactory = (filter, realEstateTypes) => {
  switch (filter.realEstateType) {
    case realEstateTypes.APARTMENT:
      return new ApartmentFilter(mapServerDataToDomainProps(filter));
    case realEstateTypes.HOUSE_OR_COUNTRY_HOUSE:
      return new HouseFilter(mapServerDataToDomainProps(filter));
    case realEstateTypes.COMMERCIAL:
      const commercialFilter = new CommercialFilter(mapWithPartialServiceType(filter));
      commercialFilter.objectPartialServiceType = filter.realEstateServiceType;
      return commercialFilter;
    case realEstateTypes.LAND_PLOT:
      const landPlotFilter = new LandPlotFilter(mapWithPartialServiceType(filter));
      landPlotFilter.objectPartialServiceType = filter.realEstateServiceType;
      return landPlotFilter;
    case realEstateTypes.GARAGE_PARKING:
      const garageParkingFilter = new GarageParkingFilter(mapWithPartialServiceType(filter));
      garageParkingFilter.objectPartialServiceType = filter.realEstateServiceType;
      return garageParkingFilter;
    default:
      return new NullFilter({});
  }
};
