export default (theme) => ({
  root: {
    maxWidth: 350,
    margin: theme.spacing(3),
  },
  media: {
    height: 140,
  },
  cardActions: {
    justifyContent: 'space-between',
  },
  objectCardTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardLink: {
    color: '#333',
    textDecoration: 'none',
  },
});
