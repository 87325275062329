import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Logo from '../../../../../common/components/Logo/Logo';
import styles from './styles';

const AuthPageLayer = ({ classes, children }) => (
  <div className={classes.authWrapper}>
    <Logo />
    <div className={classes.authBox}>{children}</div>
  </div>
);

export default withStyles(styles)(AuthPageLayer);

AuthPageLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.array,
};
