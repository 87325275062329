import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { NumericFormat } from 'react-number-format';
export const NumberFormatCustom = React.forwardRef((props, ref) => {
    const { onChange, value, ...other } = props;
    return (_jsx(NumericFormat, { ...other, getInputRef: ref, thousandSeparator: ' ', valueIsNumericString: true, maxLength: 13, value: value, onValueChange: (values) => {
            onChange({
                target: {
                    name: props.name,
                    // @ts-ignore
                    value: Number(values.value) > 0 ? values.floatValue : null,
                },
            });
        } }));
});
