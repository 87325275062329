import {
  AUTHORIZATION_START,
  AUTHORIZATION_FAIL,
  AUTHORIZATION_SUCCESS,
  LOGOUT_SUCCESS,
  DROP_ERRORS_SUCCESS,
  REDIRECT_TO_CONFIRM,
} from '../actions/authorization';
import AuthService from '../../../common/sevices/auth';
import ConfirmRegistrationService from '../../../common/sevices/confirmRegistrationService';

const initialState = {
  inProgress: false,
  error: null,
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case AUTHORIZATION_START:
      return {
        ...state,
        error: null,
        inProgress: true,
      };
    case AUTHORIZATION_FAIL:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case AUTHORIZATION_SUCCESS:
      AuthService.authorizeUser(action.data);
      return {
        ...state,
        inProgress: false,
      };
    case LOGOUT_SUCCESS:
      AuthService.destroySession();
      return {
        ...state,
      };
    case DROP_ERRORS_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case REDIRECT_TO_CONFIRM:
      ConfirmRegistrationService.saveConfirmUserToken(action.token);
      location.href = '/confirm-registration';
      return {
        error: null,
        inProgress: false,
      };

    default:
      return state;
  }
};
