import { connect } from 'react-redux';
import LoginPage from '../components/pages/LoginPage/LoginPage';
import { login, dropAuthorizationErrors } from '../actions/authorization';

const mapStateToProps = ({ login }) => {
  const { inProgress, error, rejectReason } = login;
  return {
    inProgress,
    error,
    rejectReason,
  };
};

const mapDispatchToProps = {
  login,
  dropAuthorizationErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
