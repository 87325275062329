import { BaseValidator } from './BaseValidator';

export class HouseValidator extends BaseValidator {
  validate() {
    const errors = this.initBaseValidation();

    errors.squarePrice = this.validateSquarePrice();

    if (!errors.squarePrice) {
      errors.squarePriceFrom = this.validatePriceWithCurrency(this.realEstateFilter.squarePriceFrom);
      errors.squarePriceTo = this.validatePriceWithCurrency(this.realEstateFilter.squarePriceTo);
    }

    errors.flooring = this.validateFlooringRange();

    if (!errors.flooring) {
      errors.flooringFrom = this.validateMaxValue(this.realEstateFilter.flooringFrom);
      errors.flooringTo = this.validateMaxValue(this.realEstateFilter.flooringTo);
    }

    return errors;
  }
}
