import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { getSubtypeList } from 'realtor/helpers/clientFilterParams';
import { AreaRangeFilter } from 'realtor/components/layears/RangeFilters';
import i18n from '../../../../../../common/i18n';
import styles from '../styles';
import SelectButtons from '../../../common/SelectButtons/SelectButtons';
import { BaseParams } from './BaseParams';

export class CommercialParams extends BaseParams {
  constructor(props) {
    super(props);
    this.props = props;
  }

  renderSubtypeType() {
    const { filtersList, filter, onMultiSelect, onMultiSelectAll } = this.props;
    const realEstateTypeCollection = getSubtypeList(filter, filtersList);

    return (
      <SelectButtons
        list={realEstateTypeCollection}
        onSelect={onMultiSelect}
        onSelectAll={onMultiSelectAll}
        selectedValue={filter.objectSubtype}
        name="objectSubtype"
        header={i18n.t('FILTER_REAL_ESTATE_SUBTYPE')}
        isMulti
      />
    );
  }

  renderArea() {
    const { onSelect, filter, errors } = this.props;

    return <AreaRangeFilter filter={filter} onSelect={onSelect} errors={errors} />;
  }

  renderFullParamsList() {
    const { classes } = this.props;

    return (
      <>
        <Grid sm={12}>{this.renderSubtypeType()}</Grid>
        <Grid item sm={6} xs={12} md={12} lg={12}>
          {this.renderCitiesFilter()}
        </Grid>
        <Grid sm={12}>{this.renderMaterial()}</Grid>
        <Grid xs={12} sm={12}>
          {this.renderArea()}
        </Grid>
        <Grid sm={12}>{this.renderCondition()}</Grid>
        <Grid sm={12} container className={classes.rangeParamsBox}>
          {this.renderFloorRange()}
        </Grid>
        <Grid sm={12} container>
          {this.renderDisableLastFloor()}
        </Grid>
        <Grid sm={12} container>
          {this.renderOnlyLastFloorFilter()}
        </Grid>
        <Grid sm={12}>{this.renderCurrency()}</Grid>
        <Grid sm={12} container className={classes.rangeParamsBox}>
          {this.renderTotalPriceRange()}
          {this.renderSquarePriceRange()}
        </Grid>
        {this.renderDescription()}
      </>
    );
  }

  render() {
    const { filter } = this.props;

    return (
      <Grid container>
        <Grid sm={12}>
          {this.renderPartialServiceType()}
          {filter.objectPartialServiceType && this.renderFullParamsList()}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(CommercialParams);

CommercialParams.propTypes = {
  filter: PropTypes.object,
  errors: PropTypes.object,
  filtersList: PropTypes.object,
  citiesList: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
  onMultiSelect: PropTypes.func.isRequired,
  onMultiSelectAll: PropTypes.func.isRequired,
  disableServiceType: PropTypes.bool,
};
