import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';

import { get } from 'lodash';
import { TelephoneNumberBuilder } from 'realtor/domain';
import ExistClientModal from 'realtor/components/forms/CreateClientWithFiltersForm/components/ExistClientModal/ExistClientModal';
import styles from './styles';
import CreateClientForm from '../CreateClientForm/CreateClientForm';
import i18n from '../../../../../common/i18n';
import { ClientValidation } from '../../../validation/client/clientValidation';
import { mapCreateClientErrors } from '../../../../allAccess/helpers/errorsMapper';

export class CreateClientWithFiltersForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state.client = props.client;
    this.state.filter = props.filter;
  }

  state = {
    client: {
      moreTelephoneNumbers: [],
      tags: [],
    },
    clientErrors: {},
    isExistClientModalVisible: false,
    existClientData: {},
  };

  // eslint-disable-next-line max-statements
  static getDerivedStateFromProps(props, state) {
    const client = { ...state.client };
    let clientErrors = { ...state.clientErrors };

    // TODO: Add init errors
    const { errors, appConfigurations } = props;
    const errorCode = get(errors, 'code');
    const existClientError = {
      isExistClientModalVisible: false,
      existClientData: {},
    };
    const validationErrors = errorCode ? mapCreateClientErrors(appConfigurations.errorCodes, errors) : {};

    if (appConfigurations.errorCodes.CLIENT_WITH_THIS_TELEPHONE_NUMBER_ALREADY_EXIST === errorCode) {
      existClientError.isExistClientModalVisible = true;
      existClientError.existClientData = get(errors, 'data.existClient');
      clientErrors = {
        ...clientErrors,
        ...validationErrors,
      };
    }

    if (appConfigurations.errorCodes.CLIENT_WITH_THIS_MORE_TELEPHONE_NUMBERS_ALREADY_EXIST === errorCode) {
      clientErrors.moreTelephoneNumbers = [];
      const existTelephoneNumbers = get(errors, 'data.existNumbersList');

      client.moreTelephoneNumbers.forEach(({ telephoneNumber }) => {
        const phoneNumberEntity = TelephoneNumberBuilder.buildFromFormData(telephoneNumber);
        const phoneNumberApiFormat = phoneNumberEntity.getTelephoneNumberFromContract();
        const moreTelephoneNumberError = existTelephoneNumbers.includes(phoneNumberApiFormat)
          ? i18n.t('CLIENT_WITH_THIS_MORE_TELEPHONE_NUMBER_ALREADY_EXIST_TEXT')
          : null;
        clientErrors.moreTelephoneNumbers.push(moreTelephoneNumberError);
      });
    }

    if (props.tag.createdTag) {
      props.dropCreatedTag();
      client.tags = [...state.client.tags, props.tag.createdTag];
    }

    return {
      ...state,
      client,
      clientErrors,
      ...existClientError,
    };
  }

  validateClientForm = () => {
    const validation = new ClientValidation(this.state.client);

    return validation.validate();
  };

  handleCloseExistClientModal = () => {
    this.props.dropErrors();
    this.setState({
      ...this.state,
      isExistClientModalVisible: false,
      existClientData: {},
    });
  };

  handleSubmit = (e) => {
    const { onConfirm } = this.props;

    e.preventDefault();
    this.setState({
      ...this.state,
      errors: {},
    });

    const { isClientFormValid, clientErrors } = this.validateClientForm();

    this.setState({
      ...this.state,
      clientErrors,
    });

    const dataToSave = {
      client: this.state.client,
    };

    if (isClientFormValid) {
      onConfirm(dataToSave);
    } else {
      Alert.error(i18n.t('CREATE_CLIENT_FORM_ERROR'), { position: 'top-right', effect: 'slide', timeout: 3000 });
    }

    return false;
  };

  handleAssignTag = (tag) => {
    let tagsList = this.state.client.tags;
    const isTagAssigned = !!tagsList.find((assignedTag) => assignedTag.id === tag.id);

    if (isTagAssigned) {
      tagsList = tagsList.filter((assignedTag) => assignedTag.id !== tag.id);
    } else {
      tagsList.push(tag);
    }

    return this.setState({
      ...this.state,
      client: {
        ...this.state.client,
        tags: tagsList,
      },
    });
  };

  handleClientFieldChange = (event) => {
    const { name, value } = event.target;
    const { onContentWasUpdated } = this.props;

    if (onContentWasUpdated) onContentWasUpdated();

    return this.setState({
      ...this.state,
      client: {
        ...this.state.client,
        [name]: value,
      },
    });
  };

  renderExistClientModal() {
    const { isExistClientModalVisible, existClientData } = this.state;
    const { onConfirmRestore } = this.props;

    return (
      <ExistClientModal
        isExistClientModalVisible={isExistClientModalVisible}
        existClientData={existClientData}
        onDialogClose={this.handleCloseExistClientModal}
        onConfirmRestore={onConfirmRestore}
      />
    );
  }

  render() {
    const { client, clientErrors, isExistClientModalVisible } = this.state;
    const { classes, confirmButtonText, onLoadMoreTags, tag, onAddNewTag, isSaveButtonDisabled } = this.props;

    const buttonText = confirmButtonText || i18n.t('ADD_CLIENT');

    return (
      <form onSubmit={this.handleSubmit}>
        {isExistClientModalVisible && this.renderExistClientModal()}
        <div className={classes.clientForm}>
          <CreateClientForm
            client={client}
            errors={clientErrors}
            onFieldChange={this.handleClientFieldChange}
            onLoadMoreTags={onLoadMoreTags}
            tag={tag}
            onAssignTag={this.handleAssignTag}
            onAddNewTag={onAddNewTag}
          />
        </div>
        <div className={classes.clientForm}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSaveButtonDisabled}
          >
            {buttonText}
          </Button>
        </div>
      </form>
    );
  }
}

export default withStyles(styles)(CreateClientWithFiltersForm);

CreateClientWithFiltersForm.propTypes = {
  client: PropTypes.object,
  appConfigurations: PropTypes.object,
  filter: PropTypes.object,
  errors: PropTypes.object,
  tag: PropTypes.object,
  classes: PropTypes.object,
  errorCodes: PropTypes.object,
  onConfirm: PropTypes.func,
  onContentWasUpdated: PropTypes.func,
  onLoadMoreTags: PropTypes.func,
  onAddNewTag: PropTypes.func,
  dropCreatedTag: PropTypes.func,
  dropErrors: PropTypes.func,
  onConfirmRestore: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string,
  isSaveButtonDisabled: PropTypes.bool,
};
