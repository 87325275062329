import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import i18n from '../../../../../../../common/i18n';

const styles = () => ({
  description: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
  },
  rowLabel: {
    color: '#C1C1C1',
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: '0',
  },
});

export const RealEstateObjectDetailsDescription = ({ classes, description }) => (
  <div className={classes.description}>
    <p className={classes.rowLabel}>{i18n.t('DESCRIPTION')}</p>
    <Typography>{description}</Typography>
  </div>
);

RealEstateObjectDetailsDescription.propTypes = {
  description: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RealEstateObjectDetailsDescription);
