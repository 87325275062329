import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import translationsProvider from './i18nTranslationsProvider';
import config from './i18nextConfig';

function setMomentLocale(lang) {
  const momentLocaleToAppLangMap = {
    zh: 'zh-cn',
  };
  const momentLocale = momentLocaleToAppLangMap[lang] || lang;
  moment.locale(momentLocale);
}

/**
 * Load i18n translations resources
 */
const i18nLoader = function load(appTranslations) {
  const i18nextInitPromise = new Promise((resolve) => {
    i18next
      // lookup strategy - "querystring", "cookie", "localStorage", "navigator", "htmlTag"
      .use(LanguageDetector)
      .use(translationsProvider(appTranslations))
      .init(config, (err) => {
        // when requested language can't be loaded - change to fallback lng, fallback lng always exist
        if (err) {
          i18next.changeLanguage(config.fallbackLng[0]);
        }

        // use the same locale for moment
        setMomentLocale(i18next.language);

        // never crash during translations loading
        resolve(i18next);
      });
  });

  const init = Promise.resolve().then(() => i18nextInitPromise);

  return init;
};

export { i18nLoader };
