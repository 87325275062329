import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormHelperText, InputAdornment, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// @ts-ignore
import cn from 'classnames';
import i18n from '../../../../i18n';
import styles from './styles';
import { NumberFormatCustom } from '../PriceNumberMask/PriceNumberMask';
export const PriceRangeInput = ({ nameFrom, nameTo, valueFrom, valueTo, classes, placeholderFrom, placeholderTo, label, error, currencyPrefix, header, errorTo = '', errorFrom = '', onFieldChange, }) => {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        return onFieldChange(name, value);
    };
    const styleFrom = valueFrom ? { paddingLeft: '4px' } : {};
    const styleTo = valueTo ? { paddingLeft: '4px' } : {};
    const labelData = header ? _jsx("h3", { children: header }) : _jsx("label", { children: label });
    const fieldErrorClasses = cn(classes.errorText, classes.fieldErrorText);
    return (_jsxs("div", { className: classes.rangeTextInputBox, children: [labelData, _jsxs("div", { className: classes.rangeTextField, children: [_jsx("span", { children: i18n.t('FROM') }), _jsxs(FormControl, { className: classes.rangeTextFieldTextInput, margin: "none", error: !!errorFrom || !!error, children: [_jsx(TextField, { placeholder: placeholderFrom, variant: "outlined", margin: "none", required: true, fullWidth: true, id: nameFrom, name: nameFrom, onChange: handleInputChange, value: valueFrom, inputProps: {
                                    min: 0,
                                    style: styleFrom,
                                }, error: !!errorFrom || !!error, InputProps: {
                                    inputComponent: NumberFormatCustom,
                                    startAdornment: valueFrom && _jsx(InputAdornment, { position: "start", children: currencyPrefix }),
                                } }), _jsx(FormHelperText, { className: fieldErrorClasses, children: errorFrom })] }), _jsx("div", { className: classes.dash }), _jsx("span", { children: i18n.t('TO') }), _jsxs(FormControl, { className: classes.rangeTextFieldTextInput, margin: "none", error: !!errorTo || !!error, children: [_jsx(TextField, { placeholder: placeholderTo, variant: "outlined", margin: "none", required: true, fullWidth: true, id: nameTo, name: nameTo, onChange: handleInputChange, value: valueTo, inputProps: {
                                    min: 0,
                                    style: styleTo,
                                }, error: !!errorTo || !!error, InputProps: {
                                    inputComponent: NumberFormatCustom,
                                    startAdornment: valueTo && _jsx(InputAdornment, { position: "start", children: currencyPrefix }),
                                } }), _jsx(FormHelperText, { className: fieldErrorClasses, children: errorTo })] })] }), _jsx(FormControl, { children: _jsx(FormHelperText, { className: classes.errorText, children: error }) })] }));
};
export default withStyles(styles)(PriceRangeInput);
