import axios from '../../common/axios';

class App {
  /**
   * Fetch token for share public objects list
   */
  fetchObjectListShareToken() {
    return axios.get('/v1/realtor/my/objects/hash').then((res) => res.data);
  }
}

export default new App();
