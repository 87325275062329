import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { DEFAULT_LOAD_LIST_PARAMS } from 'realtor/actions';
import { Chip, InputAdornment, OutlinedInput } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import SimpleSearchTags from 'realtor/components/layears/SimpleSearchTags/SimpleSearchTags';
import FakeSearch from 'realtor/components/common/FakeSearch/FakeSearch';

const SearchByTags = ({ classes, tagsList, onCloseTagSearch, fetchTagsList, onTagSelected, selected }) => {
  useEffect(() => {
    fetchTagsList({ ...DEFAULT_LOAD_LIST_PARAMS });
  }, []);

  const [search, setSearch] = useState('#');
  const [tagPagination, setTagPagination] = useState({ ...DEFAULT_LOAD_LIST_PARAMS });
  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    setSelectedTag(selected);
  }, [selected]);

  const handleLoadMoreTags = () => {
    const updatedTagPagination = {
      ...tagPagination,
      offset: tagPagination.offset + tagPagination.limit,
    };

    fetchTagsList(updatedTagPagination);

    setTagPagination(updatedTagPagination);
  };

  const handleTagSearchChange = (event) => {
    if (!event.target.value) onCloseTagSearch();
    const searchValue = event.target.value.replace('#', '');
    const tagPagination = { ...DEFAULT_LOAD_LIST_PARAMS };

    fetchTagsList({
      ...tagPagination,
      search: searchValue,
    });

    setTagPagination(tagPagination);
    setSearch(`#${searchValue}`);
  };

  const handleTagSelect = (tag = null) => {
    setSelectedTag(tag);
    onTagSelected(tag);
  };

  const renderSearch = () => (
    <>
      <OutlinedInput
        name="search"
        id="search"
        onChange={handleTagSearchChange}
        value={search}
        variant="standard"
        className={classes.search}
        placeholder="SEARCH BY TAG"
        autoFocus
        startAdornment={
          <InputAdornment position="start">
            <Search color="disabled" />
          </InputAdornment>
        }
      />
      <SimpleSearchTags onSelectTag={handleTagSelect} onLoadTags={handleLoadMoreTags} list={tagsList} />
    </>
  );

  const renderSelectedTag = () => (
    <FakeSearch>
      <Chip color="primary" label={selectedTag.title} onDelete={() => handleTagSelect(null)} />
    </FakeSearch>
  );

  return selectedTag ? renderSelectedTag() : renderSearch();
};

SearchByTags.propTypes = {
  classes: PropTypes.object.isRequired,
  tagsList: PropTypes.array,
  fetchTagsList: PropTypes.func,
  onCloseTagSearch: PropTypes.func,
  onTagSelected: PropTypes.func,
  selected: PropTypes.object,
};

export default SearchByTags;
