import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import i18n from '../../../../../../../common/i18n';

const styles = () => ({
  notes: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    marginBottom: '30px',
  },
  rowLabel: {
    color: '#C1C1C1',
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: '0',
  },
});

export const RealEstateObjectDetailsNotes = ({ classes, notes }) => (
  <div className={classes.notes}>
    <p className={classes.rowLabel}>{i18n.t('REAL_ESTATE_OBJECT_NOTATIONS')}</p>
    <Typography>{notes}</Typography>
  </div>
);

RealEstateObjectDetailsNotes.propTypes = {
  notes: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RealEstateObjectDetailsNotes);
