import {
  FETCH_OBJECTS_SUCCESS,
  FETCH_OBJECTS_FAIL,
  FETCH_OBJECTS_START,
  FETCH_OBJECTS_TOTAL_START,
  FETCH_OBJECTS_TOTAL_SUCCESS,
  FETCH_OBJECTS_TOTAL_FAIL,
  SET_SEARCH_OBJECTS_LIST,
  REAL_ESTATE_FAVORITE_ACTION_START,
  REAL_ESTATE_FAVORITE_ACTION_FAILED,
} from '../actions';

const initialState = {
  listInProgress: false,
  favoriteActionInProgress: false,
  inProgress: false,
  error: null,
  list: [],
  totalListCount: 0,
  totalCount: null,
};

// eslint-disable-next-line default-param-last
export const objectsList = (state = initialState, action) => {
  switch (action.type) {
    case REAL_ESTATE_FAVORITE_ACTION_START:
      return {
        ...state,
        error: null,
        favoriteActionInProgress: true,
      };
    case REAL_ESTATE_FAVORITE_ACTION_FAILED:
      return {
        ...state,
        error: action.error,
        favoriteActionInProgress: false,
      };
    case FETCH_OBJECTS_START:
      return {
        ...state,
        error: null,
        listInProgress: true,
      };
    case FETCH_OBJECTS_TOTAL_START:
      return {
        ...state,
        error: null,
        inProgress: true,
      };

    case FETCH_OBJECTS_FAIL:
      return {
        ...state,
        error: action.error,
        listInProgress: false,
      };
    case FETCH_OBJECTS_TOTAL_FAIL:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };

    case FETCH_OBJECTS_SUCCESS:
      return {
        ...state,
        error: null,
        list: action.data.result,
        totalListCount: action.data.totalCount,
        listInProgress: false,
      };
    case SET_SEARCH_OBJECTS_LIST:
      return {
        ...state,
        list: action.objectsList,
        favoriteActionInProgress: false,
      };
    case FETCH_OBJECTS_TOTAL_SUCCESS:
      return {
        ...state,
        error: null,
        totalCount: action.data.total,
        inProgress: false,
      };
    default:
      return state;
  }
};
