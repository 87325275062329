export class ConfirmProfileRequestDateBuilder {
  static build(formData) {
    const requestData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      organization: formData.organization,
    };

    // if (get(formData, 'organization.title')) {
    //   requestData.organization = formData.organization;
    // }

    if (formData.moreTelephoneNumbers.length) {
      requestData.telephoneNumbers = formData.moreTelephoneNumbers.filter(({ telephoneNumber }) =>
        Boolean(telephoneNumber),
      );
    }

    return requestData;
  }
}
