import React, { Fragment, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Paper } from '@material-ui/core';
import styles from './styles';
import i18n from '../../../../../../common/i18n';
import RealtorPageHeader from '../../../layears/RealtorPageHeader/RealtorPageHeader';
import CreateSingleFilterForm from '../../../forms/CreateSingleFilterForm/CreateSingleFilterForm';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';
import { FullScreenLoader } from '../../../../../components/FullScreenLoader/FullScreenLoader';
import { useGetAppConfigurationQuery } from '../../../../../allAccess/services/appConfigurationService';

export const DEFAULT_FILTER = {
  currency: 1,
};

export const CreateClientFilterPage = ({ clientId, createClientFilter, classes, inProgress }) => {
  const { data: appConfigurations } = useGetAppConfigurationQuery();

  const pageTitle = i18n.t('ADD_FILTER');
  useEffect(() => {
    setPageTitle(pageTitle);
  }, []);

  const handleFormConfirm = (formData) => createClientFilter(clientId, formData);

  const renderForm = () => (
    <Paper className={classes.paper} elevation={0}>
      <div>
        <CreateSingleFilterForm
          onConfirm={handleFormConfirm}
          filter={DEFAULT_FILTER}
          confirmButtonText={i18n.t('SAVE_CHANGES')}
          cancelButtonText={i18n.t('SAVE_WITHOUT_CHANGES')}
          inProgress={inProgress}
          appConfigurations={appConfigurations}
        />
      </div>
    </Paper>
  );

  const renderHeader = () => (
    <RealtorPageHeader>
      <div className={classes.headerBox}>
        <h1>{pageTitle}</h1>
      </div>
    </RealtorPageHeader>
  );

  return (
    <>
      {renderHeader()}
      <Paper className={classes.paper} elevation={0}>
        {inProgress && <FullScreenLoader />}
        {renderForm()}
      </Paper>
    </>
  );
};

CreateClientFilterPage.propTypes = {
  createClientFilter: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  inProgress: PropTypes.bool,
};

export default withStyles(styles)(CreateClientFilterPage);
