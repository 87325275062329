import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DOMAIN } from '../../../../../../Config';
export const registrationApi = createApi({
    reducerPath: 'registration',
    baseQuery: fetchBaseQuery({ baseUrl: `${DOMAIN}/v1/authentication/` }),
    endpoints: (builder) => ({
        registration: builder.mutation({
            query: (registrationModel) => ({
                url: 'realtor',
                method: 'POST',
                body: registrationModel.toServerEntity(),
            }),
        }),
    }),
});
export const { useRegistrationMutation } = registrationApi;
