import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link to="/" color="inherit" component={RouterLink}>
      M2C CRM
    </Link>{' '}
    {new Date().getFullYear()}.
  </Typography>
);

export default Copyright;
