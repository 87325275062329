import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button, FormControl, InputAdornment, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import Sort from '@material-ui/icons/Sort';
import { Search } from '@material-ui/icons';

import cn from 'classnames';
import SearchByTags from 'realtor/components/layears/ClientsControlPanel/SearchByTags/SimpleSearchByTag';
import styles from './styles';
import i18n from '../../../../../common/i18n';

const SEARCH_DELAY = 500;
const SEARCH_MIN_LENGTH = 3;

class ClientsControlPanel extends PureComponent {
  state = {
    search: '',
    searchWasCalled: false,
    isSearchByTag: false,
  };

  componentWillUpdate(nextProps, nextState) {
    if (nextState.search.length < SEARCH_MIN_LENGTH && nextState.search.length < this.state.search.length) {
      this.props.onSearchChange({ search: '' });
    }
  }

  componentDidUpdate() {
    if (this.props.selectedTag) {
      this.setState({ ...this.state, isSearchByTag: true });
    }
  }

  searchDebounceTimeout = null;

  handleSearchChange = (event) => {
    const search = event.target.value;
    const { onSearchChange } = this.props;

    if (Array.from(search)[0] === '#') {
      return this.setState({
        ...this.state,
        isSearchByTag: true,
      });
    }

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      if (search.length >= SEARCH_MIN_LENGTH) {
        onSearchChange({ search });
        this.setState({
          ...this.state,
          searchWasCalled: true,
        });
      }
    }, SEARCH_DELAY);

    this.setState({
      ...this.state,
      isSearchByTag: false,
      searchByTag: '',
      search,
    });
  };

  handleSelectSearchTag = (tag) => {
    this.props.onSearchChange({ tag });
  };

  handleOrderChange = (event) => {
    const { onOrderChange } = this.props;
    return onOrderChange(event.target.value);
  };

  handleCloseSearchTags = () =>
    this.setState({
      ...this.state,
      isSearchByTag: false,
    });

  renderFiltersButton() {
    const { classes, onFiltersToggle, isFiltersButtonActive, isFiltersButtonDisabled } = this.props;

    const filterButtonClasses = cn({
      [classes.filtersButtonActive]: isFiltersButtonActive,
      [classes.controlButton]: true,
      [classes.filterButton]: true,
    });

    const styles = isFiltersButtonDisabled ? {} : { color: '#FFFFFF' };

    return (
      <Button
        onClick={onFiltersToggle}
        className={filterButtonClasses}
        disabled={isFiltersButtonDisabled}
        startIcon={<FilterListIcon style={styles} className={classes.controlIcons} />}
      >
        {i18n.t('FILTERS')}
      </Button>
    );
  }

  renderSortList() {
    const { classes, currentOrder, orderList } = this.props;

    const orderButtonClasses = cn({
      [classes.controlButton]: true,
      [classes.orderButton]: true,
    });

    return (
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        startAdornment={<Sort className={classes.controlIcons} />}
        variant="outlined"
        IconComponent={() => null}
        className={orderButtonClasses}
        value={currentOrder}
        onChange={this.handleOrderChange}
      >
        {orderList.map((orderItem, i) => (
          <MenuItem key={i} value={orderItem}>
            {i18n.t(orderItem)}
          </MenuItem>
        ))}
      </Select>
    );
  }

  renderSearchByTags() {
    const { classes, tagsList, fetchTagsList, selectedTag } = this.props;

    return (
      <SearchByTags
        onCloseTagSearch={this.handleCloseSearchTags}
        classes={classes}
        tagsList={tagsList}
        fetchTagsList={fetchTagsList}
        onTagSelected={this.handleSelectSearchTag}
        selected={selectedTag}
      />
    );
  }

  renderSearchBlock() {
    const { classes } = this.props;
    const { search } = this.state;

    return (
      <OutlinedInput
        name="search"
        id="search"
        onChange={this.handleSearchChange}
        value={search}
        variant="standard"
        className={classes.search}
        placeholder={i18n.t('CLIENT_SEARCH_PLACEHOLDER')}
        startAdornment={
          <InputAdornment position="start">
            <Search color="disabled" />
          </InputAdornment>
        }
      />
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.paramsFilter}>
        <FormControl fullWidth margin="none" className={classes.paramsFormControl}>
          <div className={classes.searchBlock}>
            {this.state.isSearchByTag ? this.renderSearchByTags() : this.renderSearchBlock()}
          </div>
          <div className={classes.controlActions}>
            {this.renderSortList()}
            {this.renderFiltersButton()}
          </div>
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles)(ClientsControlPanel);

ClientsControlPanel.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  onOrderChange: PropTypes.func.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isFiltersButtonActive: PropTypes.bool,
  orderList: PropTypes.array.isRequired,
  currentOrder: PropTypes.string.isRequired,
  fetchTagsList: PropTypes.func.isRequired,
  tagsList: PropTypes.array,
  isFiltersButtonDisabled: PropTypes.bool,
  selectedTag: PropTypes.number,
};
