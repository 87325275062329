import {
  getErrorBasedOnCurrency,
  validateMaxValue,
  validateRangeHelper,
  validateRangeWithEqualHelper,
} from 'realtor/helpers/validationHelpers';

export class BaseValidator {
  constructor(realEstateFilter, exchangeRates) {
    this.realEstateFilter = realEstateFilter;
    this.exchangeRates = exchangeRates;
    this.MEDIUMINT_MAX = 16777215;
  }

  validateRangeWithEqual(from, to) {
    return validateRangeWithEqualHelper(from, to);
  }

  validateMaxValue(value) {
    return validateMaxValue(value, this.MEDIUMINT_MAX);
  }

  validateRange(from, to) {
    return validateRangeHelper(from, to);
  }

  validatePriceWithCurrency(price) {
    if (!price) return;

    const { realEstateFilter, exchangeRates, MEDIUMINT_MAX } = this;

    // eslint-disable-next-line consistent-return
    return getErrorBasedOnCurrency(price, realEstateFilter.currency, MEDIUMINT_MAX, exchangeRates);
  }

  validateArea() {
    return this.validateRange(this.realEstateFilter.areaFrom, this.realEstateFilter.areaTo);
  }

  validateLivingArea() {
    return this.validateRange(this.realEstateFilter.livingAreaFrom, this.realEstateFilter.livingAreaTo);
  }

  validateKitchenArea() {
    return this.validateRange(this.realEstateFilter.kitchenAreaFrom, this.realEstateFilter.kitchenAreaTo);
  }

  validateFloorRange() {
    return this.validateRangeWithEqual(this.realEstateFilter.floorFrom, this.realEstateFilter.floorTo);
  }

  validateFlooringRange() {
    return this.validateRangeWithEqual(this.realEstateFilter.flooringFrom, this.realEstateFilter.flooringTo);
  }

  validateSquarePrice() {
    return this.validateRange(this.realEstateFilter.squarePriceFrom, this.realEstateFilter.squarePriceTo);
  }

  validateLandAreaPrice() {
    return this.validateRange(this.realEstateFilter.landAreaPriceFrom, this.realEstateFilter.landAreaPriceTo);
  }

  initBaseValidation() {
    const errors = {};

    errors.totalPrice = this.validateRange(this.realEstateFilter.totalPriceFrom, this.realEstateFilter.totalPriceTo);

    if (!errors.totalPrice) {
      errors.totalPriceFrom = this.validatePriceWithCurrency(this.realEstateFilter.totalPriceFrom);
      errors.totalPriceTo = this.validatePriceWithCurrency(this.realEstateFilter.totalPriceTo);
    }

    errors.area = this.validateArea();

    if (!errors.area) {
      errors.areaFrom = this.validateMaxValue(this.realEstateFilter.areaFrom);
      errors.areaTo = this.validateMaxValue(this.realEstateFilter.areaTo);
    }

    return errors;
  }
}
