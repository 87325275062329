import { getErrorBasedOnCurrency, validateMaxValue, validateRangeHelper } from 'realtor/helpers/validationHelpers';
import i18n from '../../../../../common/i18n';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import { NOTES_AND_DESCRIPTION_LENGTH } from '../../../../constants/validation';

export class BaseValidator {
  constructor(realEstateObject, exchangeRates) {
    this.realEstateObject = realEstateObject;
    this.exchangeRates = exchangeRates;
    this.MEDIUMINT_MAX = 16777215;
  }

  validateMaxValue(value) {
    return validateMaxValue(value, this.MEDIUMINT_MAX);
  }

  validateRange(from, to) {
    return validateRangeHelper(from, to);
  }

  validatePriceWithCurrency(price) {
    if (!price) return;

    const { realEstateObject, exchangeRates, MEDIUMINT_MAX } = this;

    // eslint-disable-next-line consistent-return
    return getErrorBasedOnCurrency(price, realEstateObject.currency, MEDIUMINT_MAX, exchangeRates);
  }

  validateFloor() {
    let error = null;
    if (this.realEstateObject.floor && this.realEstateObject.flooring) {
      if (Number(this.realEstateObject.floor) > Number(this.realEstateObject.flooring)) {
        error = i18n.t('FLOR_CAN_NOT_BE_LESS_THAN_FLOORING', { flooring: this.realEstateObject.flooring });
      }
    }

    return error;
  }

  validateArea() {
    return this.validateRange(this.realEstateObject.areaFrom, this.realEstateObject.areaTo);
  }

  validateLivingArea() {
    return this.validateRange(this.realEstateObject.livingAreaFrom, this.realEstateObject.livingAreaTo);
  }

  validateKitchenArea() {
    return this.validateRange(this.realEstateObject.kitchenAreaFrom, this.realEstateObject.kitchenAreaTo);
  }

  validateFlooringRange() {
    return this.validateRange(this.realEstateObject.flooringFrom, this.realEstateObject.flooringTo);
  }

  validateSquarePrice() {
    return this.validateRange(this.realEstateObject.squarePriceFrom, this.realEstateObject.squarePriceTo);
  }

  validateLandAreaPrice() {
    return this.validateRange(this.realEstateObject.landAreaPriceFrom, this.realEstateObject.landAreaPriceTo);
  }

  initBaseValidation() {
    const errors = {};

    errors.title = this.validateTitle();

    errors.description = this.validateDescription();

    errors.totalPrice = this.validateTotalPrice();

    errors.notes = FormValidation.validateLength(this.realEstateObject.notes, 0, NOTES_AND_DESCRIPTION_LENGTH);

    errors.city = FormValidation.validateRequired(this.realEstateObject.cityId);

    errors.area = FormValidation.validateRequired(this.realEstateObject.area);

    if (this.realEstateObject.virtualTourUrl) {
      errors.virtualTourUrl = this.validateVirtualTourLink();
    }

    return errors;
  }

  // eslint-disable-next-line consistent-return
  validateVirtualTourLink() {
    // eslint-disable-next-line
    const urlRegExp = /(https?:\/\/(.+?\.)?3dshoot\.viewin360\.co(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/gm;

    if (!urlRegExp.test(this.realEstateObject.virtualTourUrl)) {
      return i18n.t('INVALID_VIRTUAL_TOUR_LINK');
    }
  }

  validateTotalPrice() {
    const errors = [
      FormValidation.validateRequired(this.realEstateObject.totalPrice),
      this.validatePriceWithCurrency(this.realEstateObject.totalPrice),
    ];
    return errors.find((error) => !!error) || null;
  }

  validateTitle() {
    const errors = [
      FormValidation.validateRequired(this.realEstateObject.title),
      FormValidation.validateLength(this.realEstateObject.title, 3, 50),
      // TODO: uncomment symbol validation if it need
      // FormValidation.validateOrganization(this.realEstateObject.title),
    ];

    return errors.find((error) => !!error) || null;
  }

  validateDescription() {
    const errors = [
      FormValidation.validateRequired(this.realEstateObject.description),
      FormValidation.validateLength(this.realEstateObject.description, 0, NOTES_AND_DESCRIPTION_LENGTH),
    ];

    return errors.find((error) => !!error) || null;
  }
}
