import i18n from '../../../common/i18n';

const getTitles = (list) => Object.keys(list);

const getSubtypeCollection = (paramsList, type, typeTitle) => {
  const objectSubtypes = paramsList.objectSubtypes[type];
  return getTitles(objectSubtypes).map((title) => ({
    title: i18n.t(`FILTER_REAL_ESTATE_SUBTYPE_${typeTitle}_${title}`),
    value: objectSubtypes[title],
  }));
};

export const getListParamCollection = (filtersList, filterName, prefix) => {
  const titles = Object.keys(filtersList[filterName]);

  return titles.map((title) => ({
    title: i18n.t(`${prefix}${title}`),
    value: filtersList[filterName][title],
  }));
};

export const getSubtypeList = (filter, paramsList) => {
  let list = [];
  switch (filter.objectType) {
    case paramsList.objectType.HOUSE_OR_COUNTRY_HOUSE:
      list = getSubtypeCollection(paramsList, 'house', 'HOUSE');
      break;
    case paramsList.objectType.APARTMENT:
      list = getSubtypeCollection(paramsList, 'apartment', 'FLOR');
      break;
    case paramsList.objectType.COMMERCIAL:
      list = getSubtypeCollection(paramsList, 'commercial', 'FLOR');
      break;
    case paramsList.objectType.GARAGE_PARKING:
      list = getSubtypeCollection(paramsList, 'garageAndParking', 'PARKING_GARAGE');
      break;
    default:
      list = [];
  }

  return list;
};

const getSetFromFilterParams = (filterParam) => {
  const result = new Set();
  Object.values(filterParam).forEach((value) => {
    result.add(value);
  });

  return result;
};

export const getDefaultFilterByType = (objectType, objectFilters) => {
  switch (objectType) {
    case objectFilters.objectType.COMMERCIAL:
      return {
        objectSubtype: getSetFromFilterParams(objectFilters.objectSubtypes.commercial),
        objectMaterial: getSetFromFilterParams(objectFilters.objectMaterial),
        objectCondition: getSetFromFilterParams(objectFilters.objectCondition),
      };
    case objectFilters.objectType.APARTMENT:
      return {
        objectSubtype: getSetFromFilterParams(objectFilters.objectSubtypes.apartment),
        roomsCount: getSetFromFilterParams(objectFilters.roomsCount),
        objectMaterial: getSetFromFilterParams(objectFilters.objectMaterial),
        objectCondition: getSetFromFilterParams(objectFilters.objectCondition),
      };
    case objectFilters.objectType.LAND_PLOT:
      return {};
    case objectFilters.objectType.GARAGE_PARKING:
      return {
        objectSubtype: getSetFromFilterParams(objectFilters.objectSubtypes.garageAndParking),
      };
    case objectFilters.objectType.HOUSE_OR_COUNTRY_HOUSE:
      return {
        objectSubtype: getSetFromFilterParams(objectFilters.objectSubtypes.house),
        roomsCount: getSetFromFilterParams(objectFilters.roomsCount),
        objectMaterial: getSetFromFilterParams(objectFilters.objectMaterial),
        objectCondition: getSetFromFilterParams(objectFilters.objectCondition),
      };
    default:
      return {};
  }
};
