export default (theme) => ({
  submit: {
    margin: `${theme.spacing(3)}px 0px`,
    padding: `${theme.spacing(1.5)}px 0px`,
    background: '#F5841F',
    borderRadius: theme.spacing(3),
  },
  cancel: {
    margin: `${theme.spacing(3)}px 0px`,
    padding: `${theme.spacing(1.5)}px 0px`,
    background: '#FFFFFF',
    border: '1px solid #3694D1',
    color: '#3694D1',
    borderRadius: theme.spacing(3),
  },
  addMoreTelephoneNumber: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  form: {
    maxWidth: '500px',
  },
});
