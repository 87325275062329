import axios from '../../../common/axios';

class RealtorResource {
  /**
   * Update realtor profile
   */
  confirmProfile(profileData) {
    return axios.put('/v1/realtor/my/profile', profileData).then((res) => res.data);
  }

  partialUpdateProfile(profileData) {
    return axios.patch('/v1/realtor/my/profile', profileData).then((res) => res.data);
  }
}

export default new RealtorResource();
