import { Client, TelephoneNumberBuilder } from 'realtor/domain';
import {
  fetchRealEstateObjectTotalCountFail,
  fetchRealEstateObjectTotalCountStart,
  fetchRealEstateObjectTotalCountSuccess,
} from 'realtor/actions/myObjectsList';
import ObjectResource from '../resourses/Object.resource';

export const FETCH_OBJECTS_START = 'FETCH_OBJECTS_START';
export const FETCH_OBJECTS_FAIL = 'FETCH_OBJECTS_FAIL';
export const FETCH_OBJECTS_SUCCESS = 'FETCH_OBJECTS_SUCCESS';

export const FETCH_OBJECTS_TOTAL_START = 'FETCH_OBJECTS_TOTAL_START';
export const FETCH_OBJECTS_TOTAL_FAIL = 'FETCH_OBJECTS_TOTAL_FAIL';
export const FETCH_OBJECTS_TOTAL_SUCCESS = 'FETCH_OBJECTS_TOTAL_SUCCESS';

const fetchTotalStart = () => ({ type: FETCH_OBJECTS_TOTAL_START });

const fetchTotalFail = (error) => ({ type: FETCH_OBJECTS_TOTAL_FAIL, error });

const fetchTotalSuccess = (data) => ({ type: FETCH_OBJECTS_TOTAL_SUCCESS, data });

const fetchObjectsStart = () => ({ type: FETCH_OBJECTS_START });

const fetchObjectsFail = (error) => ({ type: FETCH_OBJECTS_FAIL, error });

const fetchObjectsSuccess = (data) => ({
  type: FETCH_OBJECTS_SUCCESS,
  data: {
    ...data,
    result: data.result.map((realEstateObject) => {
      const client = realEstateObject.client
        ? new Client({
            ...realEstateObject.client,
            telephoneNumber: TelephoneNumberBuilder.buildFromServerData(realEstateObject.client.telephoneNumber),
          })
        : null;
      return {
        ...realEstateObject,
        client,
      };
    }),
  },
});

export const fetchObjects = (params) => (dispatch) => {
  dispatch(fetchObjectsStart());
  ObjectResource.getSearchObjectsList(params)
    .then((response) => dispatch(fetchObjectsSuccess(response)))
    .catch((error) => dispatch(fetchObjectsFail(error)));
};

export const fetchObjectsTotal = (params) => (dispatch, getState) => {
  dispatch(fetchTotalStart());
  dispatch(fetchObjectsStart());
  ObjectResource.getObjectsTotal()
    .then((data) => {
      dispatch(fetchTotalSuccess(data));
      if (data.total) fetchObjects(params)(dispatch, getState);
    })
    .catch((error) => dispatch(fetchTotalFail(error)));
};

export const getRealEstateObjectTotalCount = () => (dispatch) => {
  dispatch(fetchRealEstateObjectTotalCountStart());
  ObjectResource.getObjectsTotal()
    .then((data) => {
      dispatch(fetchRealEstateObjectTotalCountSuccess(data));
    })
    .catch((err) => {
      dispatch(fetchRealEstateObjectTotalCountFail(err));
    });
};

export const dropRealEstateObjectsTotalCount = () => (dispatch) =>
  dispatch(fetchRealEstateObjectTotalCountSuccess({ totalCount: null }));
