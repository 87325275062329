export default () => ({
    sliderImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        backgroundColor: 'gray',
        maxHeight: '70vh',
        '@media (max-width: 1200px)': {
        // height: '30vh',
        },
        '& img': {
            maxHeight: '70vh',
            objectFit: 'cover',
        },
    },
    sliderImage: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
    imageThumb: {
        width: '100%',
        '& div': {
            height: '50px',
        },
    },
    carouselClass: {
        width: '80%',
        // maxWidth: '1200px',
        '@media (max-width: 900px)': {
            width: '100%',
        },
        '& .thumbs-wrapper': {
            marginTop: 0,
            '& .thumb': {
                borderColor: 'transparent',
                opacity: '.7',
                transition: '.2s',
            },
            '& .thumb.selected': {
                borderColor: '#F5841F',
                opacity: 1,
            },
            '& .thumb:hover': {
                borderColor: '#F5841F',
            },
            '& .control-next.control-arrow': {
                right: '-10px',
                '&:before': {
                    borderLeft: '8px solid #F5841F',
                    borderTopWidth: '8px',
                    borderBottomWidth: '8px',
                },
            },
            '& .control-prev.control-arrow': {
                left: '-10px',
                '&:before': {
                    borderRight: '8px solid #F5841F',
                    borderTopWidth: '8px',
                    borderBottomWidth: '8px',
                },
            },
        },
        '& .carousel-slider': {
            '& .control-next.control-arrow': {
                backgroundColor: 'rgba(0,0,0, .5)',
                width: '50px',
                '@media (max-width: 768px)': {
                    backgroundColor: 'transparent',
                },
                '&:before': {
                    borderLeft: '10px solid #F5841F',
                    borderTopWidth: '16px',
                    borderBottomWidth: '16px',
                },
            },
            '& .control-prev.control-arrow': {
                backgroundColor: 'rgba(0,0,0, .5)',
                width: '50px',
                '@media (max-width: 768px)': {
                    backgroundColor: 'transparent',
                },
                '&:before': {
                    borderRight: '10px solid #F5841F',
                    borderTopWidth: '16px',
                    borderBottomWidth: '16px',
                },
            },
        },
    },
});
