import React from 'react';
import PropTypes from 'prop-types';
import { DOMAIN } from '../../../../../../../Config';

export const DefaultPhoto = ({ classes, onEditRealEstate }) => (
  <div className={classes.defaultSliderBox}>
    <img
      onClick={onEditRealEstate}
      className={classes.defaultSliderImage}
      src={`${DOMAIN}/static/images/image-not-found.png`}
    />
  </div>
);

DefaultPhoto.propTypes = {
  classes: PropTypes.object.isRequired,
  onEditRealEstate: PropTypes.func.isRequired,
};
