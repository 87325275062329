import { City, District, Region, Location } from '../index';

export class LocationBuilder {
  static buildFromFiltersList(filter) {
    const city = filter.city
      ? new City({
          id: filter.city.id,
          name: filter.city.name,
        })
      : null;
    const district = filter.district
      ? new District({
          id: filter.district.id,
          name: filter.district.name,
        })
      : null;
    const region =
      filter.city && filter.city.region
        ? new Region({
            id: filter.city.region.id,
            name: filter.city.region.name,
          })
        : null;

    return new Location({
      city,
      district,
      region,
    });
  }
}
