import {
  getErrorBasedOnCurrency,
  validateMaxValue,
  validateRangeHelper,
  validateRangeWithEqualHelper,
} from 'realtor/helpers/validationHelpers';

export class BaseValidator {
  constructor(filter, exchangeRates) {
    this.filter = filter;
    this.exchangeRates = exchangeRates;
    this.MEDIUMINT_MAX = 16777215;
  }

  validateMaxValue(value) {
    return validateMaxValue(value, this.MEDIUMINT_MAX);
  }

  validateRange(from, to) {
    return validateRangeHelper(from, to);
  }

  validateRangeWithEqual(from, to) {
    return validateRangeWithEqualHelper(from, to);
  }

  validatePriceWithCurrency(price) {
    if (!price) return;

    const { filter, exchangeRates, MEDIUMINT_MAX } = this;

    return getErrorBasedOnCurrency(price, filter.currency, MEDIUMINT_MAX, exchangeRates);
  }

  validateFloor() {
    return this.validateRangeWithEqual(this.filter.floorFrom, this.filter.floorTo);
  }

  validateArea() {
    return this.validateRange(this.filter.areaFrom, this.filter.areaTo);
  }

  validateLivingArea() {
    return this.validateRange(this.filter.livingAreaFrom, this.filter.livingAreaTo);
  }

  validateKitchenArea() {
    return this.validateRange(this.filter.kitchenAreaFrom, this.filter.kitchenAreaTo);
  }

  validateFlooringRange() {
    return this.validateRangeWithEqual(this.filter.flooringFrom, this.filter.flooringTo);
  }

  validateSquarePrice() {
    return this.validateRange(this.filter.squarePriceFrom, this.filter.squarePriceTo);
  }

  validateLandAreaPrice() {
    return this.validateRange(this.filter.landAreaPriceFrom, this.filter.landAreaPriceTo);
  }

  validateTotalPrice() {
    return this.validateRange(this.filter.totalPriceFrom, this.filter.totalPriceTo);
  }
}
