import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { isProfileFilled } from 'realtor/selectors/User/isProfileFilled';
import { RealtorCabinet } from 'realtor/components/layears/RealtorCabinet/RealtorCabinet';
import { ShareTokenHelper } from '../../../helpers/ShareTokenHelper';
import { fetchShareToken } from '../../../actions/objects';
import { fetchUserInfo } from '../../../actions/user';
import { logout } from '../../../allAccess/actions/authorization';
import { redirectTo } from '../../../actions/redirect';
import styles from '../../components/layears/RealtorCabinet/styles';

const mapStateToProps = (state) => {
  const { myObjects, user } = state;

  return {
    shareLink: myObjects.shareToken ? ShareTokenHelper.buildShareLinkForObjectsList(myObjects.shareToken) : null,
    isProfileFilled: isProfileFilled(state),
    inProgress: user.inProgress,
    userProfile: user.profile,
  };
};

const mapDispatchToProps = {
  fetchShareToken,
  fetchUserInfo,
  logout,
  redirectTo,
};

export default compose(
  withStyles(styles, {
    name: 'RealtorCabinet',
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(RealtorCabinet);
