import i18n from '../../../common/i18n';

export const getCurrencyLabel = (currency) => {
  switch (currency) {
    case 0:
      return i18n.t('CURRENCY_USD');
    case 1:
      return i18n.t('CURRENCY_UAH');
    default:
      return i18n.t('CURRENCY_USD');
  }
};
