import { BaseValidator } from './BaseValidator';

export class LandPlotValidator extends BaseValidator {
  validate() {
    const errors = this.initBaseValidation();

    errors.landAreaPrice = this.validateLandAreaPrice();

    if (!errors.landAreaPrice) {
      errors.landAreaPriceFrom = this.validatePriceWithCurrency(this.realEstateFilter.landAreaPriceFrom);
      errors.landAreaPriceTo = this.validatePriceWithCurrency(this.realEstateFilter.landAreaPriceTo);
    }

    return errors;
  }
}
