export default (theme) => ({
  objectContact: {
    width: '100%',
    display: 'flex',
    color: '#5F5F5F',
    fontSize: '14px',
    alignItems: 'center',
    background: '#F5F8FC',
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    minHeight: '46px',
    justifyContent: 'space-between',
  },
  objectContactClientCallActions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  objectContactClientName: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    maxWidth: '42%',
  },
  locationIcon: {
    color: '#C4C4C4',
  },
  phone: {
    color: '#000000',
    display: 'inline-block',
  },
  socialButton: {
    padding: theme.spacing(0),
  },
  clientFullName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});
