const sidebarWidth = '320px';
export const borderColor = '#ccc';
export const tabsBackgroundColor = '#f9f9f9';

export default (theme) => ({
  '@global': {
    html: {
      fontSize: '14px',
    },
    body: {
      height: '100%',
      margin: 0,
      boxSizing: 'border-box',
      maxHeight: '100%',
      padding: 0,
      backgroundColor: '#fff',
    },
    '#app': {
      flex: 1,
    },
    h1: {
      fontSize: '2em',
      margin: '0px',
      lineHeight: '40px',
    },
    hr: {
      display: 'block',
      height: '1px',
      border: 0,
      borderTop: '1px solid #F1F1F1',
      padding: 0,
    },
    label: {
      marginBottom: theme.spacing(1),
      color: '#767676',
      fontSize: '0.85em',
      fontWeight: 700,
    },
    '.MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '.MuiSelect-select': {
      padding: theme.spacing(1),
    },
    '.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(0.5),
    },
    '.MuiInputAdornment-positionStart': {
      marginRight: 0,
    },
    textArea: {
      maxWidth: '650px',
      width: '100%',
      border: '1px solid rgb(204, 204, 204)',
      borderRadius: theme.spacing(1),
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    a: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  appWrapper: {
    display: 'flex',
    height: '-webkit-fill-available',
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',

    // set global app styles
    '& *': {
      fontFamily: theme.typography.fontFamily,
    },
  },
  sidebarCol: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    flex: `0 1 ${sidebarWidth}`,
    minWidth: sidebarWidth,
    maxWidth: sidebarWidth,
    overflow: 'auto',
    boxShadow: '4px 0px 5px 0px rgba(0, 0, 0, 0.12)',
  },
  mainContent: {
    width: '100%',
  },
  chartListHeader: {
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '16px',
    borderTop: `1px solid ${borderColor}`,
    borderBottom: `1px solid ${borderColor}`,
  },
  chartListHeaderLabel: {
    flex: '1 1 auto',
  },
});
