import {
  FETCH_CLIENT_FILTER_START,
  FETCH_CLIENT_FILTER_SUCCESS,
  FETCH_CLIENT_FILTER_FAIL,
  UPDATE_CLIENT_FILTER_FAIL,
  UPDATE_CLIENT_FILTER_START,
  CREATE_CLIENT_FILTER_START,
  CREATE_CLIENT_FILTER_FAIL,
  CREATE_CLIENT_FILTER_SUCCESS,
  UPDATE_CLIENT_FILTER_SUCCESS,
  DELETE_CLIENT_FILTER_START,
  DELETE_CLIENT_FILTER_SUCCESS,
  DELETE_CLIENT_FILTER_FAIL,
} from '../actions';

const initialState = {
  inProgress: false,
  updateInProgress: false,
  error: null,
  clientFilter: null,
};

/* eslint-disable complexity */
export const clientFilter = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_FILTER_START:
      return {
        ...state,
        error: null,
        updateInProgress: true,
      };
    case UPDATE_CLIENT_FILTER_FAIL:
      return {
        ...state,
        error: action.error,
        updateInProgress: false,
      };
    case UPDATE_CLIENT_FILTER_SUCCESS:
      return {
        ...state,
        error: null,
        updateInProgress: false,
        clientFilter: action.clientFilterData,
      };
    case FETCH_CLIENT_FILTER_START:
    case CREATE_CLIENT_FILTER_START:
    case DELETE_CLIENT_FILTER_START:
      return {
        ...state,
        error: null,
        inProgress: true,
        clientFilter: null,
      };
    case FETCH_CLIENT_FILTER_FAIL:
    case CREATE_CLIENT_FILTER_FAIL:
    case DELETE_CLIENT_FILTER_FAIL:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case FETCH_CLIENT_FILTER_SUCCESS:
    case CREATE_CLIENT_FILTER_SUCCESS:
    case DELETE_CLIENT_FILTER_SUCCESS:
      return {
        ...state,
        error: null,
        inProgress: false,
        clientFilter: action.clientFilterData,
      };
    default:
      return state;
  }
};
