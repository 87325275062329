import React from 'react';

const CopyToClipboardIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="5" width="10" height="16" rx="1" fill="#767676" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      /* eslint-disable max-len */
      d="M9 3C8.44772 3 8 3.44772 8 4V4.06667H16C16.5523 4.06667 17 4.51438 17 5.06667V19C17.5523 19 18 18.5523 18 18V4C18 3.44772 17.5523 3 17 3H9Z"
      fill="#767676"
    />
  </svg>
);

export default CopyToClipboardIcon;
