import { TelephoneNumber } from './TelephoneNumber';

export class ClientTelephoneNumber extends TelephoneNumber {
  props = {
    id: null,
    telephoneNumber: null,
    order: null,
    isPrimary: false,
  };

  set order(order) {
    this.props.order = order;
  }

  get order() {
    return this.props.order;
  }

  set isPrimary(isPrimary) {
    this.props.isPrimary = isPrimary;
  }

  get isPrimary() {
    return this.props.isPrimary;
  }
}
