export default (theme) => ({
    cityAutocomplete: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    districtAutocomplete: {
        marginTop: '2px',
    },
    locationTextField: {
        paddingRight: theme.spacing(3),
    },
});
