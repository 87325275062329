import React, { Fragment, PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, Grid, TextareaAutosize } from '@material-ui/core';

import {
  FlooringRangeFilter,
  LivingAreaRangeFilter,
  LandAreaPriceRangeFilter,
  KitchenAreaRangeFilter,
  SquarePriceRangeFilter,
  FloorRangeFilter,
  TotalPriceRangeFilter,
} from 'realtor/components/layears/RangeFilters';
import styles from '../styles';
import i18n from '../../../../../../common/i18n';
import Location from '../../../common/Location/Location';
import SelectButtonByFilter from '../../../common/SelectButtonByFilter/SelectButtonByFilter';
import CustomSwitch from '../../../common/CustomSwitch/CustomSwitch';

export class BaseParams extends PureComponent {
  renderCitiesFilter() {
    const { errors, citiesList, onCityChange, filter, onDistrictChange } = this.props;

    return (
      <>
        <h3>{i18n.t('MAIN_INFORMATION')}</h3>
        <Grid container xs={12} lg={6} sm={12}>
          <Location
            regions={citiesList}
            onCityChange={onCityChange}
            onDistrictChange={onDistrictChange}
            cityId={filter.cityId}
            selectedDistrict={filter.districtId}
            gridParams={{
              sm: 12,
              md: 6,
              lg: 6,
              xl: 6,
              xs: 12,
            }}
            cityError={errors.city}
          />
        </Grid>
      </>
    );
  }

  renderPartialServiceType() {
    const { filtersList, onSelect, filter, errors, disableServiceType } = this.props;
    const filterName = 'objectPartialServiceType';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_SERVICE_TYPE_';
    const header = i18n.t('SELECT_OBJECT_SERVICE_TYPE');

    return (
      <SelectButtonByFilter
        filter={filter}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        header={header}
        onSelect={onSelect}
        errors={errors}
        disabled={disableServiceType}
      />
    );
  }

  renderServiceType() {
    const { filtersList, filter, errors, onFilterServiceSelect, disableServiceType } = this.props;
    const filterName = 'objectServiceType';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_SERVICE_TYPE_';
    const header = i18n.t('SELECT_OBJECT_SERVICE_TYPE');

    return (
      <SelectButtonByFilter
        filter={filter}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        header={header}
        onSelect={onFilterServiceSelect}
        errors={errors}
        disabled={disableServiceType}
      />
    );
  }

  renderMaterial() {
    const { filtersList, onMultiSelect, filter, onMultiSelectAll } = this.props;
    const filterName = 'objectMaterial';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_MATERIAL_';
    const header = i18n.t('FILTER_REAL_ESTATE_MATERIAL');

    return (
      <SelectButtonByFilter
        filter={filter}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        title={header}
        onSelect={onMultiSelect}
        isMulti
        onSelectAll={onMultiSelectAll}
      />
    );
  }

  renderRoomsCount() {
    const { filtersList, onMultiSelect, filter, onMultiSelectAll } = this.props;
    const filterName = 'roomsCount';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_ROOMS_COUNT_';
    const header = i18n.t('FILTER_REAL_ESTATE_ROOMS_COUNT');

    return (
      <SelectButtonByFilter
        filter={filter}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        title={header}
        onSelect={onMultiSelect}
        isMulti
        onSelectAll={onMultiSelectAll}
      />
    );
  }

  renderDescription() {
    const { onSelect, filter, errors, classes } = this.props;
    return (
      <>
        <FormControl fullWidth margin="normal">
          <label>{i18n.t('CLIENT_PARAMS_DESCRIPTION')}</label>
          <TextareaAutosize
            helperText={errors.description}
            error={!!errors.description}
            className={errors.description ? classes.descriptionAreaError : ''}
            rowsMin={5}
            onChange={(event) => {
              const { name, value } = event.target;
              return onSelect(name, value);
            }}
            value={filter.description}
            name="description"
          />
        </FormControl>
        <FormControl>
          <FormHelperText className={classes.errorText}>{errors.description}</FormHelperText>
        </FormControl>
      </>
    );
  }

  renderTotalPriceRange() {
    const { onSelect, filter, errors } = this.props;

    return <TotalPriceRangeFilter onSelect={onSelect} filter={filter} errors={errors} />;
  }

  renderSquarePriceRange() {
    const { onSelect, filter, errors } = this.props;

    return <SquarePriceRangeFilter onSelect={onSelect} filter={filter} errors={errors} />;
  }

  landAreaPriceRange() {
    const { onSelect, filter, errors } = this.props;

    return <LandAreaPriceRangeFilter onSelect={onSelect} filter={filter} errors={errors} />;
  }

  renderKitchenArea() {
    const { onSelect, filter, errors } = this.props;

    return <KitchenAreaRangeFilter onSelect={onSelect} filter={filter} errors={errors} />;
  }

  renderLivingArea() {
    const { onSelect, filter, errors } = this.props;

    return <LivingAreaRangeFilter onSelect={onSelect} filter={filter} errors={errors} />;
  }

  renderCondition() {
    const { filtersList, onMultiSelect, filter, onMultiSelectAll } = this.props;
    const filterName = 'objectCondition';
    const buttonTitlePrefix = 'FILTER_REAL_ESTATE_FLOR_CONDITION_';
    const header = i18n.t('FILTER_REAL_ESTATE_FLOR_CONDITION');

    return (
      <SelectButtonByFilter
        filter={filter}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        title={header}
        onSelect={onMultiSelect}
        isMulti
        onSelectAll={onMultiSelectAll}
      />
    );
  }

  renderCurrency() {
    const { filtersList, onSelect, filter } = this.props;
    const filterName = 'currency';
    const buttonTitlePrefix = 'CURRENCY_';
    const header = i18n.t('CURRENCY');

    return (
      <SelectButtonByFilter
        filter={filter}
        filtersList={filtersList}
        filterName={filterName}
        buttonTitlePrefix={buttonTitlePrefix}
        title={header}
        onSelect={onSelect}
      />
    );
  }

  renderFlooringRange() {
    const { onSelect, filter, errors } = this.props;

    return <FlooringRangeFilter filter={filter} onSelect={onSelect} errors={errors} />;
  }

  renderDisableLastFloor() {
    const { filter, onSelect } = this.props;

    const paramName = 'ignoreLastFloor';
    const isDisabled = filter.isLastFloor;

    return (
      <Grid sm={12} md={12} xs={12}>
        <CustomSwitch
          disabled={isDisabled}
          checked={filter[paramName]}
          label={i18n.t('IGNORE_LAST_FLOOR')}
          onChange={onSelect}
          name={paramName}
        />
      </Grid>
    );
  }

  renderOnlyLastFloorFilter() {
    const { filter, onSelect } = this.props;

    const paramName = 'isLastFloor';
    const isDisabled = filter.ignoreLastFloor;

    return (
      <Grid sm={12} md={12} xs={12}>
        <CustomSwitch
          disabled={isDisabled}
          checked={filter[paramName]}
          label={i18n.t('LAST_FLOOR')}
          onChange={onSelect}
          name={paramName}
        />
      </Grid>
    );
  }

  renderParamsOnlyForBuyService() {
    const { filter, filtersList, onSelect } = this.props;

    const paramName = 'isMortgage';

    return (
      filter.objectServiceType === filtersList.objectServiceType.BUY && (
        <Grid sm={12} md={12} xs={12}>
          <CustomSwitch
            checked={filter[paramName]}
            label={i18n.t('FILTER_REAL_ESTATE_IS_MORTGAGE')}
            onChange={onSelect}
            name={paramName}
          />
        </Grid>
      )
    );
  }

  renderFloorRange() {
    const { onSelect, filter, errors } = this.props;
    return <FloorRangeFilter onSelect={onSelect} filter={filter} errors={errors} />;
  }
}

export default withStyles(styles)(BaseParams);

BaseParams.propTypes = {
  filter: PropTypes.object,
  classes: PropTypes.object,
  errors: PropTypes.object,
  filtersList: PropTypes.object,
  citiesList: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onMultiSelect: PropTypes.func.isRequired,
  onMultiSelectAll: PropTypes.func.isRequired,
  onFilterServiceSelect: PropTypes.func.isRequired,
  disableServiceType: PropTypes.bool,
};
