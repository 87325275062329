export default (theme) => ({
  submit: {
    margin: `${theme.spacing(3)}px 0px`,
    padding: `${theme.spacing(1.5)}px 0px`,
    background: '#F5841F',
    borderRadius: theme.spacing(3),
  },
  cancel: {
    margin: `${theme.spacing(3)}px 0px`,
    padding: `${theme.spacing(1.5)}px 0px`,
    borderRadius: theme.spacing(3),
  },
  controlButtons: {
    maxWidth: '420px',
  },
});
