import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dropFiltersCount } from 'realtor/actions';
import { useHistory } from 'react-router-dom';
import ClipWithCounter from 'realtor/components/layears/ClipWithCounter/ClipWithCounter';
import { getUrlWithFilterQueryParams } from './helpers/searchUrl';

const ClipObjectFiltersCount = ({ realEstateObject, dropFiltersCount }) => {
  const history = useHistory();
  const handleClick = () => {
    dropFiltersCount(realEstateObject.id);

    const urlParams = getUrlWithFilterQueryParams(realEstateObject);

    return history.push(`/search-clients${urlParams}`);
  };

  return (
    <ClipWithCounter
      count={realEstateObject.clientFiltersCount}
      onClick={handleClick}
      isActive={realEstateObject.hasNewClientSearchParams}
    />
  );
};

ClipObjectFiltersCount.propTypes = {
  realEstateObject: PropTypes.object.isRequired,
  dropFiltersCount: PropTypes.func,
};

const mapDispatchToProps = {
  dropFiltersCount,
};

export const ClipObjectFiltersCountWidget = connect(null, mapDispatchToProps)(ClipObjectFiltersCount);
