export default (theme) => ({
  avatarBlock: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },
  avatarBlockFullName: {
    margin: 0,
  },
  avatarBlockAvatar: {
    marginRight: theme.spacing(2),
    border: '2px solid #3694D1',
  },
});
