import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { set, get } from 'lodash';
import style from './style';
import { CityService } from '../../../../services/CityService';
import i18n from '../../../../../common/i18n';
const CityAutocomplete = ({ regions, cityId, onCityChange, error, classes, cityHeader, }) => {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        if (!cityId) {
            return setValue(null);
        }
        const city = CityService.getCityFormRegions(regions, cityId);
        if (regions && regions.length && city && city.name !== get(value, 'city')) {
            const region = CityService.getRegion(regions, city.regionId);
            const value = {
                region: region.name,
                city: city.name,
            };
            setValue(value);
        }
    }, [cityId]);
    const getOptions = () => {
        let options = [];
        regions.forEach((region) => {
            const citiesList = region.cities.map((city) => ({
                region: region.name,
                city: city.name,
                regionId: region.id,
                cityId: city.id,
                withDistricts: Boolean(get(city, 'districts.length', false)),
            }));
            options = [...options, ...citiesList];
        });
        return options;
    };
    const handleInputValueChange = (inputValue) => {
        setInputValue(inputValue);
    };
    const label = cityHeader ? _jsx("h3", { children: cityHeader }) : _jsx("label", { id: "city", children: i18n.t('CITY') });
    return (_jsxs(_Fragment, { children: [label, _jsx(Autocomplete, { className: classes.cityAutocomplete, value: value, onChange: (event, newValue) => {
                    onCityChange(newValue);
                }, inputValue: inputValue, onInputChange: (event, newInputValue) => {
                    handleInputValueChange(newInputValue);
                }, id: "controllable-states-demo", options: getOptions(), fullWidth: true, groupBy: (option) => option.region, getOptionLabel: (option) => option.city, renderInput: (params) => {
                    set(params, 'inputProps.autoComplete', 'chrome-off');
                    return _jsx(TextField, { variant: "outlined", ...params, helperText: error, error: !!error });
                } })] }));
};
export default withStyles(style)(CityAutocomplete);
