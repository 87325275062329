import FavoriteRealEstateResource from 'realtor/resourses/FavoriteRealEstate.resource';
import Alert from 'react-s-alert';
import { FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS } from 'realtor/actions/realEstateObject';

export const SET_SEARCH_OBJECTS_LIST = 'SET_SEARCH_OBJECTS_LIST';
export const REAL_ESTATE_FAVORITE_ACTION_START = 'REAL_ESTATE_FAVORITES_ACTION_START';
export const SINGLE_REAL_ESTATE_FAVORITE_ACTION_START = 'SINGLE_REAL_ESTATE_FAVORITE_ACTION_START';
export const REAL_ESTATE_FAVORITE_ACTION_FAILED = 'REAL_ESTATE_FAVORITES_ACTION_FAILED';
export const SINGLE_REAL_ESTATE_FAVORITE_ACTION_FAILED = 'SINGLE_REAL_ESTATE_FAVORITE_ACTION_FAILED';

const singleRealEstateFavoriteActionStart = () => ({
  type: SINGLE_REAL_ESTATE_FAVORITE_ACTION_START,
});

const realEstateFavoriteActionStart = () => ({
  type: REAL_ESTATE_FAVORITE_ACTION_START,
});

const singleRealEstateFavoriteActionFailed = () => ({
  type: SINGLE_REAL_ESTATE_FAVORITE_ACTION_FAILED,
});

const realEstateFavoriteActionFailed = () => ({
  type: REAL_ESTATE_FAVORITE_ACTION_FAILED,
});

const addToFavoriteSuccess = (realEstateObjectId, list) => {
  const objectsList = list.map((realEstateObject) => {
    if (realEstateObject.id === realEstateObjectId) {
      realEstateObject.isFavourite = true;
    }
    return realEstateObject;
  });

  return { type: SET_SEARCH_OBJECTS_LIST, objectsList };
};

const addSingleObjectToFavouriteSuccess = (realEstateObject) => {
  realEstateObject.isFavourite = true;
  return { type: FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS, realEstateObject: { ...realEstateObject } };
};

const removeSingleObjectFromFavouriteSuccess = (realEstateObject) => {
  realEstateObject.isFavourite = false;
  return { type: FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS, realEstateObject: { ...realEstateObject } };
};

const removeFromFavoriteSuccess = (realEstateObjectId, list) => {
  const objectsList = list.map((realEstateObject) => {
    if (realEstateObject.id === realEstateObjectId) {
      realEstateObject.isFavourite = false;
    }
    return realEstateObject;
  });

  return { type: SET_SEARCH_OBJECTS_LIST, objectsList };
};

export const addSingleObjectToFavourite = (realEstateObject) => (dispatch, getState) => {
  const state = getState();
  dispatch(singleRealEstateFavoriteActionStart());

  FavoriteRealEstateResource.addRealEstateToFavorite(realEstateObject.id)
    .then(() => {
      dispatch(addSingleObjectToFavouriteSuccess(state.realEstateObject.realEstateObject));
    })
    .catch((error) => {
      dispatch(singleRealEstateFavoriteActionFailed());
      Alert.error(error, { position: 'top-right', effect: 'slide', timeout: 3000 });
    });
};

export const removeSingleObjectToFavourite = (realEstateObject) => (dispatch, getState) => {
  const state = getState();

  dispatch(singleRealEstateFavoriteActionStart());

  FavoriteRealEstateResource.removeRealEstateFromFavorite(realEstateObject.id)
    .then(() => {
      dispatch(removeSingleObjectFromFavouriteSuccess(state.realEstateObject.realEstateObject));
    })
    .catch((error) => {
      dispatch(singleRealEstateFavoriteActionFailed());
      Alert.error(error, { position: 'top-right', effect: 'slide', timeout: 3000 });
    });
};

export const addRealEstateToFavorite = (realEstateObject) => (dispatch, getState) => {
  const state = getState();
  dispatch(realEstateFavoriteActionStart());
  FavoriteRealEstateResource.addRealEstateToFavorite(realEstateObject.id)
    .then(() => {
      dispatch(addToFavoriteSuccess(realEstateObject.id, state.objectsList.list));
    })
    .catch((error) => {
      dispatch(realEstateFavoriteActionFailed());
      Alert.error(error, { position: 'top-right', effect: 'slide', timeout: 3000 });
    });
};

export const removeRealEstateFromFavorite = (realEstateObject) => (dispatch, getState) => {
  const state = getState();
  dispatch(realEstateFavoriteActionStart());

  FavoriteRealEstateResource.removeRealEstateFromFavorite(realEstateObject.id)
    .then(() => {
      dispatch(removeFromFavoriteSuccess(realEstateObject.id, state.objectsList.list));
    })
    .catch((error) => {
      dispatch(realEstateFavoriteActionFailed());
      Alert.error(error, { position: 'top-right', effect: 'slide', timeout: 3000 });
    });
};
