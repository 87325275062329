import { connect } from 'react-redux';

import UpdateClientPage from '../../components/pages/ClientsPages/UpdateClientPage/UpdateClientPage';

import {
  fetchClient,
  updateClient,
  fetchTagsList,
  createNewTag,
  dropCreatedTag,
  dropErrors,
  restoreClient,
} from '../../actions';

const mapStateToProps = (state, { match }) => {
  const { client, tag } = state;

  return {
    client: client.client,
    error: client.error,
    inProgress: client.inProgress,
    clientId: Number(match.params.clientId),
    tag,
  };
};

const mapDispatchToProps = {
  fetchClient,
  updateClient,
  fetchTagsList,
  createNewTag,
  dropCreatedTag,
  dropErrors,
  restoreClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateClientPage);
