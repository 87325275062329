import thunkMiddleware from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
/* @ts-ignore */
import { registrationApi } from './allAccess/modules/Authorization/store/services/registrationApi';
import { appConfigurationApi } from '../app/allAccess/services/appConfigurationService';
// @ts-ignore
import { loggerMiddleware, devToolsEnhancer } from '../common/middleware/reduxDevTools';
/* @ts-ignore */
import catchErrors from '../common/middleware/catchErrors';
/* @ts-ignore */
import rootReducer from './reducers/index';
/* @ts-ignore */
const middlewares = [thunkMiddleware, catchErrors, ...loggerMiddleware];
/* @ts-ignore */
const enhancers = [...devToolsEnhancer];
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares).concat([appConfigurationApi.middleware, registrationApi.middleware]),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(enhancers),
});
setupListeners(store.dispatch);
