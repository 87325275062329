import { Grid, IconButton, Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import cn from 'classnames';
import React from 'react';
import Alert from 'react-s-alert';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';

import { getCurrencyLabel } from 'realtor/helpers/currency';
import { formatPriceNumber } from 'realtor/helpers/formatPriceNubmer';
import { ClipClientFilterObjectsCountWidget } from 'realtor/components/layears/ClipClientFilterObjectsCount/ClipClientFilterObjectsCount';
import ClientListParamsControlButtons from './ClientListParamsControlButtons/ClientListParamsControlButtons';
import i18n from '../../../../../../../common/i18n';

const ClientListParamsHeader = ({ classes, filter, paramsList, onDeleteFilter, clientId, isOwnerList }) => {
  const objectTypeList = paramsList.objectType;
  const objectTypeListNames = Object.keys(objectTypeList);
  const [realEstateTypeName] = objectTypeListNames.filter((name) => objectTypeList[name] === filter.objectType);

  const realEstateServiceTypeList = paramsList.objectServiceType;
  const realEstateServiceTypeNames = Object.keys(realEstateServiceTypeList);
  const [realEstateServiceTypeName] = realEstateServiceTypeNames.filter(
    (name) => realEstateServiceTypeList[name] === (filter.objectServiceType || filter.objectPartialServiceType),
  );

  const cardControlBoxClasses = cn(classes.clientCardHeaderItem, classes.filterControl, classes.cardControlBox);

  const getPrice = () => {
    const { totalPriceFrom, totalPriceTo, currency } = filter || {};
    const formattedPriceTo = formatPriceNumber(totalPriceTo);

    if (totalPriceFrom) {
      return `${formatPriceNumber(totalPriceFrom)}${getCurrencyLabel(currency)} -
      ${formattedPriceTo}${getCurrencyLabel(currency)}`;
    }

    return `${i18n.t('TO')} ${formattedPriceTo}
    ${getCurrencyLabel(currency)}`;
  };
  const handleCodeCopy = (e) => {
    e.stopPropagation();
    copy(filter.code.getFullCode());
    Alert.success(i18n.t('FILTER_CODE_IS_COPIED'), { position: 'top-right', effect: 'slide', timeout: 3000 });
  };

  const renderControlButtons = () => (
    <ClientListParamsControlButtons clientId={clientId} filterId={filter.id} onDeleteFilter={onDeleteFilter} />
  );

  return (
    <Grid container sm={12} className={classes.filterBox}>
      <Grid className={classes.idBox} item sm={3}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          {isOwnerList && <ClipClientFilterObjectsCountWidget clientFilter={filter} clientId={clientId} />}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            CID {filter.code.getVisibleCode()}
            <IconButton onClick={handleCodeCopy}>
              <FileCopyIcon />
            </IconButton>
          </div>
        </div>
      </Grid>
      {isOwnerList && (
        <Grid item className={cardControlBoxClasses} sm={2} md={2}>
          {renderControlButtons()}
        </Grid>
      )}
      <Grid className={classes.filterInfoBox} item container sm={7}>
        <Grid className={classes.filterInfoBox} item md={7}>
          <Tooltip title={i18n.t(`FILTER_REAL_ESTATE_TYPE_${realEstateTypeName}`)}>
            <div className={classes.filterValue}>{i18n.t(`FILTER_REAL_ESTATE_TYPE_${realEstateTypeName}`)}</div>
          </Tooltip>
          {(filter.objectServiceType || filter.objectPartialServiceType) && (
            <Tooltip title={i18n.t(`FILTER_REAL_ESTATE_SERVICE_TYPE_${realEstateServiceTypeName}`)}>
              <div className={classes.filterValue}>
                {i18n.t(`FILTER_REAL_ESTATE_SERVICE_TYPE_${realEstateServiceTypeName}`)}
              </div>
            </Tooltip>
          )}
        </Grid>
        <Grid className={classes.filterInfoBox} item md={3}>
          {filter.location.getFilterListView()}
        </Grid>
        <Grid className={classes.filterInfoBox} item md={2}>
          {getPrice()}
        </Grid>
      </Grid>
    </Grid>
  );
};

ClientListParamsHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  paramsList: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  onDeleteFilter: PropTypes.func.isRequired,
  isOwnerList: PropTypes.bool.isRequired,
};

export default ClientListParamsHeader;
