import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const TruncateText = ({ text }) => {
  const truncatedText = text.length > 100 ? `${text.slice(0, 100)}...` : text;
  return truncatedText !== text ? (
    <Tooltip placement="top" title={<Typography>{text}</Typography>} arrow>
      <p>{truncatedText}</p>
    </Tooltip>
  ) : (
    <p>{truncatedText}</p>
  );
};

export default TruncateText;

TruncateText.propTypes = {
  text: PropTypes.string.isRequired,
};
