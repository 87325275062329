import { BaseFilter } from './BaseFilter';

export class LandPlotFilter extends BaseFilter {
  get objectPartialServiceType() {
    return this.props.objectPartialServiceType;
  }

  set objectPartialServiceType(objectPartialServiceType) {
    this.props.objectPartialServiceType = objectPartialServiceType;
  }

  get areaFrom() {
    return this.props.areaFrom;
  }

  set areaFrom(areaFrom) {
    this.props.areaFrom = areaFrom;
  }

  get areaTo() {
    return this.props.areaTo;
  }

  set areaTo(areaTo) {
    this.props.areaTo = areaTo;
  }

  get landAreaPriceFrom() {
    return this.props.landAreaPriceFrom;
  }

  set landAreaPriceFrom(landAreaPriceFrom) {
    this.props.landAreaPriceFrom = landAreaPriceFrom;
  }

  get landAreaPriceTo() {
    return this.props.landAreaPriceTo;
  }

  set landAreaPriceTo(landAreaPriceTo) {
    this.props.landAreaPriceTo = landAreaPriceTo;
  }

  /* eslint-disable complexity */
  toServerEntity() {
    return {
      ...this.toBaseServerEntity(),
      areaFrom: this.props.areaFrom ? Number(this.props.areaFrom) : 0,
      areaTo: this.props.areaTo ? Number(this.props.areaTo) : null,
      landAreaPriceFrom: this.props.landAreaPriceFrom ? Number(this.props.landAreaPriceFrom) : null,
      landAreaPriceTo: this.props.landAreaPriceTo ? Number(this.props.landAreaPriceTo) : null,
      realEstateServiceType: this.props.objectPartialServiceType ? Number(this.props.objectPartialServiceType) : null,
    };
  }
}
