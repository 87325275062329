import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core';

import { Link as RouterLink, withRouter } from 'react-router-dom';

import MenuIcon from '@material-ui/icons/Menu';
import styles from './styles';
import SmallLogo from '../SmallLogo/SmallLogo';
import addClientSmallImage from '../../../../../../public/images/add_client_mobile.png';
import addRealEstateImage from '../../../../../../public/images/add_real_estate_mobile.png';

const MobileNavigationBar = ({ onMenuToggle, classes, location }) => {
  const handleDrawerToggle = () => onMenuToggle(true);

  const renderClientCreateButton = location.pathname === '/clients' || location.pathname === '/search-clients';
  const renderRealEstateCreateButton = location.pathname === '/objects' || location.pathname === '/search-objects';

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className={classes.mobileNavigationBar} position="static">
        <Toolbar>
          <div>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className={classes.logo}>
            <SmallLogo />
          </div>
          <div className={classes.controlButtons}>
            {renderRealEstateCreateButton && (
              <IconButton component={RouterLink} to="/real-estate-object/create" className={classes.controlButton}>
                <img alt="logo" width={32} name="logo" src={addRealEstateImage} />
              </IconButton>
            )}
            {renderClientCreateButton && (
              <IconButton component={RouterLink} to="/clients/create" className={classes.controlButton}>
                <img alt="logo" width={32} name="logo" src={addClientSmallImage} />
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default withRouter(withStyles(styles)(MobileNavigationBar));

MobileNavigationBar.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
};
