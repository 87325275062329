export var OrderKeys;
(function (OrderKeys) {
    OrderKeys["FROM_NEWEST_TO_OLDER"] = "FROM_NEWEST_TO_OLDER";
    OrderKeys["FROM_OLDER_TO_NEWEST"] = "FROM_OLDER_TO_NEWEST";
})(OrderKeys || (OrderKeys = {}));
var OrderColumns;
(function (OrderColumns) {
    OrderColumns["CREATED_AT"] = "created_at";
})(OrderColumns || (OrderColumns = {}));
var Orders;
(function (Orders) {
    Orders["ASC"] = "ASC";
    Orders["DESC"] = "DESC";
})(Orders || (Orders = {}));
export var RealEstateObjectType;
(function (RealEstateObjectType) {
    RealEstateObjectType[RealEstateObjectType["HOUSE_OR_COUNTRY_HOUSE"] = 1] = "HOUSE_OR_COUNTRY_HOUSE";
    RealEstateObjectType[RealEstateObjectType["APARTMENT"] = 2] = "APARTMENT";
    RealEstateObjectType[RealEstateObjectType["COMMERCIAL"] = 4] = "COMMERCIAL";
    RealEstateObjectType[RealEstateObjectType["LAND_PLOT"] = 8] = "LAND_PLOT";
    RealEstateObjectType[RealEstateObjectType["GARAGE_PARKING"] = 16] = "GARAGE_PARKING";
})(RealEstateObjectType || (RealEstateObjectType = {}));
export var ApartmentSubtypes;
(function (ApartmentSubtypes) {
    ApartmentSubtypes[ApartmentSubtypes["NEW"] = 1] = "NEW";
    ApartmentSubtypes[ApartmentSubtypes["SECONDARY"] = 2] = "SECONDARY";
})(ApartmentSubtypes || (ApartmentSubtypes = {}));
export var CommercialSubtypes;
(function (CommercialSubtypes) {
    CommercialSubtypes[CommercialSubtypes["OFFICE"] = 1] = "OFFICE";
    CommercialSubtypes[CommercialSubtypes["WAREHOUSE"] = 2] = "WAREHOUSE";
    CommercialSubtypes[CommercialSubtypes["SHOPPING_AREAS"] = 4] = "SHOPPING_AREAS";
})(CommercialSubtypes || (CommercialSubtypes = {}));
export var GarageParkingSubtypes;
(function (GarageParkingSubtypes) {
    GarageParkingSubtypes[GarageParkingSubtypes["GARAGE"] = 1] = "GARAGE";
    GarageParkingSubtypes[GarageParkingSubtypes["PARKING"] = 2] = "PARKING";
})(GarageParkingSubtypes || (GarageParkingSubtypes = {}));
export var HouseSubtypes;
(function (HouseSubtypes) {
    HouseSubtypes[HouseSubtypes["COTTAGE"] = 8] = "COTTAGE";
    HouseSubtypes[HouseSubtypes["COUNTRY_HOUSE"] = 2] = "COUNTRY_HOUSE";
    HouseSubtypes[HouseSubtypes["HOUSE"] = 1] = "HOUSE";
    HouseSubtypes[HouseSubtypes["PART_OF_HOUSE"] = 4] = "PART_OF_HOUSE";
    HouseSubtypes[HouseSubtypes["TOWNHOUSE"] = 16] = "TOWNHOUSE";
    HouseSubtypes[HouseSubtypes["UNFINISHED"] = 32] = "UNFINISHED";
})(HouseSubtypes || (HouseSubtypes = {}));
export var ObjectCondition;
(function (ObjectCondition) {
    ObjectCondition[ObjectCondition["AFTER_BUILDERS"] = 1] = "AFTER_BUILDERS";
    ObjectCondition[ObjectCondition["FOR_FINISHING"] = 2] = "FOR_FINISHING";
    ObjectCondition[ObjectCondition["EMERGENCY"] = 4] = "EMERGENCY";
    ObjectCondition[ObjectCondition["SOVIET_RENOVATION"] = 8] = "SOVIET_RENOVATION";
    ObjectCondition[ObjectCondition["REDECORATING"] = 16] = "REDECORATING";
    ObjectCondition[ObjectCondition["RESIDENTIAL_CONDITION"] = 32] = "RESIDENTIAL_CONDITION";
    ObjectCondition[ObjectCondition["RENOVATION"] = 64] = "RENOVATION";
    ObjectCondition[ObjectCondition["AUTHORS_DESIGN"] = 128] = "AUTHORS_DESIGN";
    ObjectCondition[ObjectCondition["NON_RESIDENTIAL"] = 256] = "NON_RESIDENTIAL";
})(ObjectCondition || (ObjectCondition = {}));
export var ObjectMaterial;
(function (ObjectMaterial) {
    ObjectMaterial[ObjectMaterial["BRICK"] = 1] = "BRICK";
    ObjectMaterial[ObjectMaterial["PANELS"] = 2] = "PANELS";
    ObjectMaterial[ObjectMaterial["BLOCKS"] = 4] = "BLOCKS";
    ObjectMaterial[ObjectMaterial["MONOLITH"] = 8] = "MONOLITH";
    ObjectMaterial[ObjectMaterial["FOAM_CONCRETE"] = 16] = "FOAM_CONCRETE";
    ObjectMaterial[ObjectMaterial["AERATED_CONCRETE"] = 32] = "AERATED_CONCRETE";
    ObjectMaterial[ObjectMaterial["SLAG"] = 64] = "SLAG";
    ObjectMaterial[ObjectMaterial["SHELL"] = 128] = "SHELL";
    ObjectMaterial[ObjectMaterial["LOG_HOUSE"] = 256] = "LOG_HOUSE";
    ObjectMaterial[ObjectMaterial["METAL"] = 512] = "METAL";
})(ObjectMaterial || (ObjectMaterial = {}));
export var Currency;
(function (Currency) {
    Currency[Currency["USD"] = 0] = "USD";
    Currency[Currency["UAH"] = 1] = "UAH";
})(Currency || (Currency = {}));
export var RealEstateObjectAccess;
(function (RealEstateObjectAccess) {
    RealEstateObjectAccess[RealEstateObjectAccess["PRIVATE"] = 0] = "PRIVATE";
    RealEstateObjectAccess[RealEstateObjectAccess["PUBLIC"] = 1] = "PUBLIC";
})(RealEstateObjectAccess || (RealEstateObjectAccess = {}));
export var RealEstateObjectPartialServiceTypes;
(function (RealEstateObjectPartialServiceTypes) {
    RealEstateObjectPartialServiceTypes[RealEstateObjectPartialServiceTypes["BUY"] = 1] = "BUY";
    RealEstateObjectPartialServiceTypes[RealEstateObjectPartialServiceTypes["RENT"] = 2] = "RENT";
    RealEstateObjectPartialServiceTypes[RealEstateObjectPartialServiceTypes["INVESTMENT_RESALE"] = 4] = "INVESTMENT_RESALE";
})(RealEstateObjectPartialServiceTypes || (RealEstateObjectPartialServiceTypes = {}));
export var RealEstateObjectServiceTypes;
(function (RealEstateObjectServiceTypes) {
    RealEstateObjectServiceTypes[RealEstateObjectServiceTypes["BUY"] = 1] = "BUY";
    RealEstateObjectServiceTypes[RealEstateObjectServiceTypes["RENT"] = 2] = "RENT";
    RealEstateObjectServiceTypes[RealEstateObjectServiceTypes["INVESTMENT_RESALE"] = 4] = "INVESTMENT_RESALE";
    RealEstateObjectServiceTypes[RealEstateObjectServiceTypes["DAY_RENT"] = 8] = "DAY_RENT";
})(RealEstateObjectServiceTypes || (RealEstateObjectServiceTypes = {}));
export var RealEstateObjectStates;
(function (RealEstateObjectStates) {
    RealEstateObjectStates[RealEstateObjectStates["CONSTRUCTION_PHASE"] = 1] = "CONSTRUCTION_PHASE";
    RealEstateObjectStates[RealEstateObjectStates["AFTER_THE_DEVELOPER"] = 2] = "AFTER_THE_DEVELOPER";
    RealEstateObjectStates[RealEstateObjectStates["NEW_BUILDING"] = 4] = "NEW_BUILDING";
    RealEstateObjectStates[RealEstateObjectStates["SECONDARY_HOUSING"] = 8] = "SECONDARY_HOUSING";
})(RealEstateObjectStates || (RealEstateObjectStates = {}));
export var RoomsCountParams;
(function (RoomsCountParams) {
    RoomsCountParams[RoomsCountParams["HOUSE_OR_COUNTRY_HOUSE"] = 1] = "HOUSE_OR_COUNTRY_HOUSE";
    RoomsCountParams[RoomsCountParams["APARTMENT"] = 2] = "APARTMENT";
    RoomsCountParams[RoomsCountParams["COMMERCIAL"] = 4] = "COMMERCIAL";
    RoomsCountParams[RoomsCountParams["LAND_PLOT"] = 8] = "LAND_PLOT";
    RoomsCountParams[RoomsCountParams["GARAGE_PARKING"] = 16] = "GARAGE_PARKING";
})(RoomsCountParams || (RoomsCountParams = {}));
