import React, { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bool } from 'prop-types';

import NotFoundPage from 'realtor/components/pages/NotFoundPage/NotFoundPage';
import { RegistrationPage } from '../../allAccess/modules/Authorization/pages/Registration/RegistrationPage';
import LoginPageContainer from '../containers/LoginPageContainer';
import ConfirmRegistrationContainer from '../containers/ConfirmRegistrationContainer';
import ShareObjectPage from '../containers/ShareObjectPage';
import ShareObjectsListPage from '../../containers/ShareObjectsListPage';

export default class AllAccessRoutes extends PureComponent {
  render() {
    const { isConfirmationTokenExist } = this.props;

    return (
      <Switch>
        <Route exact path="/">
          <Redirect to="/auth" />
        </Route>
        <Route path="/auth" component={LoginPageContainer} />
        <Route path="/registration" component={RegistrationPage} />
        {isConfirmationTokenExist && <Route path="/confirm-registration" component={ConfirmRegistrationContainer} />}
        <Route path="/share/object" component={ShareObjectPage} />
        <Route path="/share/objects" component={ShareObjectsListPage} />
        <Route path="/404" component={NotFoundPage} />
        <Redirect from="*" to="/auth" />
      </Switch>
    );
  }
}

AllAccessRoutes.propTypes = {
  isConfirmationTokenExist: bool,
};
