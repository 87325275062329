import React from 'react';
import PropTypes from 'prop-types';
import { getCurrencyLabel } from 'realtor/helpers/currency';
import { FormControl, InputAdornment, TextField } from '@material-ui/core';
import { NumberFormatCustom } from 'realtor/components/common/PriceNumberMask/PriceNumberMask';
import i18n from '../../../../../common/i18n';

export const LandAreaPriceFilter = ({ filterErrors, filter, onInputChange, isTitleLabel, disabled }) => {
  const currencyPrefix = getCurrencyLabel(filter.currency);
  const value = filter.landAreaPrice;

  const renderTitle = (title) => (isTitleLabel ? <label>{title}</label> : <h3>{title}</h3>);

  const placeholder = `${currencyPrefix}${i18n.t('LAND_AREA_PRICE')}`;

  const style = value ? { paddingLeft: '4px' } : {};

  return (
    <FormControl margin="none" helperText={filterErrors.landAreaPrice} error={filterErrors.landAreaPrice}>
      {renderTitle(`${i18n.t('LAND_AREA_PRICE_PLACEHOLDER')}:`)}
      <TextField
        placeholder={placeholder}
        variant="outlined"
        margin="none"
        disabled={disabled}
        required
        fullWidth
        id="landAreaPrice"
        name="landAreaPrice"
        onChange={onInputChange}
        value={value}
        inputProps={{
          min: 0,
          style,
        }}
        error={!!filterErrors.landAreaPrice}
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: value && <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
        }}
      />
    </FormControl>
  );
};

LandAreaPriceFilter.propTypes = {
  filterErrors: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  isTitleLabel: PropTypes.bool,
  disabled: PropTypes.bool,
};
