import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Logo from '../../../../../common/components/Logo/Logo';
import styles from './styles';

class ConfirmPageLayer extends PureComponent {
  render() {
    const { classes, children } = this.props;

    return (
      <div className={classes.confirmProfileWrapper}>
        <Logo />
        <div className={classes.confirmProfileBox}>{children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(ConfirmPageLayer);

ConfirmPageLayer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.array,
};
