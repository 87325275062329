export default (theme) => ({
  profileTable: {
    maxWidth: 500,
  },
  controlsBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  editButton: {
    marginTop: `${theme.spacing(3)}px`,
    padding: `${theme.spacing(1.5)}px 0px`,
    background: '#FFFFFF',
    border: '1px solid #3694D1',
    color: '#3694D1',
    borderRadius: theme.spacing(3),
    width: '350px',
  },
});
