import OrganizationResource from '../resourses/Organization.resource';

export const FETCH_ORGANIZATIONS_LIST_START = 'FETCH_ORGANIZATIONS_LIST_START';
export const FETCH_ORGANIZATIONS_LIST_FAIL = 'FETCH_ORGANIZATIONS_LIST_FAIL';
export const FETCH_ORGANIZATIONS_LIST_SUCCESS = 'FETCH_ORGANIZATIONS_LIST_SUCCESS';
export const CLEAN_ORGANIZATIONS_LIST = 'CLEAN_ORGANIZATIONS_LIST';

const fetchOrganizationListStart = () => ({ type: FETCH_ORGANIZATIONS_LIST_START });

const fetchOrganizationListSuccess = (organizationsList) => ({
  type: FETCH_ORGANIZATIONS_LIST_SUCCESS,
  organizationsList,
});

const fetchOrganizationListFail = (error) => ({ type: FETCH_ORGANIZATIONS_LIST_FAIL, error });

const cleanOrganizationList = () => ({ type: CLEAN_ORGANIZATIONS_LIST });

export const fetchOrganizationsList = (params) => (dispatch) => {
  dispatch(fetchOrganizationListStart());
  OrganizationResource.getList(params)
    .then((response) => dispatch(fetchOrganizationListSuccess(response)))
    .catch(({ error }) => dispatch(fetchOrganizationListFail(error)));
};

export const clearOrganizationsList = () => (dispatch) => dispatch(cleanOrganizationList());
