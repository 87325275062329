import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
// @ts-ignore
import AppContainer from './AppConteiner';
// @ts-ignore
import { theme } from '../../common/theme';
// @ts-ignore
import ReactSAlert from '../../common/components/ReactSAlert/ReactSAlert';
import { useGetAppConfigurationQuery } from '../allAccess/services/appConfigurationService';
const loaderBoxStyle = {
    overflow: 'hidden',
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
};
export const AppLoaderContainer = () => {
    const { data, isLoading, isSuccess } = useGetAppConfigurationQuery();
    const renderAppLoader = () => (_jsx("div", { style: loaderBoxStyle, children: _jsx(CircularProgress, {}) }));
    /* @ts-ignore */
    theme.isMobile = Boolean(data?.isMobile);
    return (_jsxs(MuiThemeProvider, { theme: theme, children: [_jsx(ReactSAlert, {}), isLoading && renderAppLoader(), isSuccess && _jsx(AppContainer, {})] }));
};
