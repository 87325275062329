export default (theme) => ({
    selectButton: {
        borderRadius: '8px',
        boxShadow: 'none',
        background: '#F1F1F1',
        textTransform: 'none',
        margin: `0px ${theme.spacing(1)}px ${theme.spacing(1)}px 0px`,
        '&:hover': {
            background: '#F5841F',
            color: '#fff',
        },
    },
    activeSelectButton: {
        color: '#fff!important',
        backgroundColor: '#F5841F!important',
        '&:hover': {
            background: '#F5841F',
            boxShadow: 'none',
            cursor: 'auto',
        },
    },
    selectButtonBox: {
        marginTop: theme.spacing(3),
    },
    selectButtonBlock: {
        marginTop: theme.spacing(1),
    },
    errorText: {
        color: '#f44336',
    },
});
