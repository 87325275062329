import React, { Fragment, PureComponent } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Button, FormControl, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { RealtorProfileValidation } from 'realtor/validation/realtorProfile/realtorProfileValidation';
import { trimValuesAtEnd } from 'realtor/helpers/validationHelpers';
import styles from './styles';
import i18n from '../../../../i18n';
import { FullScreenLoader } from '../../../../components/FullScreenLoader/FullScreenLoader';

export class ConfirmUserProfileForm extends PureComponent {
  state = {
    values: {
      firstName: '',
      lastName: '',
      moreTelephoneNumbers: [],
      email: '',
      organization: '',
    },
    organizationsList: [],
    errors: {},
    profile: {
      telephoneNumber: '',
      email: '',
      organization: '',
    },
  };

  static getDerivedStateFromProps(props, state) {
    let bewState = { ...state };

    if (!isEmpty(props.errors)) {
      bewState = {
        ...bewState,
        errors: props.errors,
      };
    }
    if (!isEmpty(props.profile)) {
      bewState = {
        ...bewState,
        profile: props.profile,
      };
    }
    return bewState;
  }

  reloadOrCleanOrganizationsList = (value) => {
    const { onFetchOrganizationsList, onClearOrganizationsList, organizationsList } = this.props;

    if (value.length > 2) {
      return onFetchOrganizationsList({ q: value });
    }

    if (organizationsList.length) {
      return onClearOrganizationsList();
    }
  };

  handleOrganizationTitleChange = (event) => {
    const { value } = event.target;
    const { organizationsList } = this.props;

    const existedOrganizations = organizationsList.find((organizations) => organizations.title === value);

    const organization = existedOrganizations || { title: value };

    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        organization,
      },
    });

    return this.reloadOrCleanOrganizationsList(value);
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    return this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [name]: value,
      },
    });
  };

  handleSelectOrganization = (event, target) => {
    const { organizationsList } = this.props;
    const organization = organizationsList.find((organization) => organization.title === target);

    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        organization,
      },
    });
  };

  handleSubmit = (e) => {
    const { onConfirm, onDropErrors } = this.props;
    e.preventDefault();

    const trimmedValues = trimValuesAtEnd(this.state.values);

    const { isRealtorProfileFormValid, realtorProfileErrors } = this.validateForm(trimmedValues);
    this.setState({
      ...this.state,
      values: trimmedValues,
      errors: realtorProfileErrors,
    });

    isRealtorProfileFormValid ? onConfirm(this.state.values) : onDropErrors();
    return false;
  };

  handleMoreTelephoneNumbersChange = (moreTelephoneNumbers) =>
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        moreTelephoneNumbers,
      },
    });

  validateForm = (values) => {
    const validator = new RealtorProfileValidation(values);

    return validator.validate();
  };

  render() {
    const { classes, inProgress } = this.props;
    const { values, errors, profile } = this.state;

    return (
      <>
        {inProgress && <FullScreenLoader />}
        <form
          className={classes.form}
          noValidate
          onSubmit={this.handleSubmit}
          method="POST"
          name="confirm-validation-form"
        >
          <FormControl fullWidth margin="normal">
            <label htmlFor="outlined-adornment-first-name">{i18n.t('FIRST_NAME')}</label>
            <TextField
              placeholder={i18n.t('FIRST_NAME_PLACEHOLDER')}
              variant="outlined"
              margin="none"
              required
              fullWidth
              id="firstName"
              name="firstName"
              autoFocus
              onChange={this.handleFieldChange}
              value={values.firstName}
              helperText={errors.firstName}
              error={!!errors.firstName}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <label htmlFor="outlined-adornment-last-name">{i18n.t('LAST_NAME')}</label>
            <TextField
              placeholder={i18n.t('LAST_NAME_PLACEHOLDER')}
              variant="outlined"
              margin="none"
              required
              fullWidth
              id="lastName"
              name="lastName"
              autoFocus
              onChange={this.handleFieldChange}
              value={values.lastName}
              helperText={errors.lastName}
              error={!!errors.lastName}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <label htmlFor="outlined-adornment-organization">{i18n.t('ORGANIZATION')}</label>
            <TextField
              placeholder={i18n.t('ORGANIZATION_PLACEHOLDER')}
              variant="outlined"
              margin="none"
              required
              fullWidth
              id="organization"
              name="organization"
              autoFocus
              onChange={this.handleFieldChange}
              value={values.organization}
              helperText={errors.organization}
              error={!!errors.organization}
            />
          </FormControl>
          {/* <FormControl fullWidth margin={'normal'}> */}
          {/*  <label htmlFor="outlined-adornment-organization" >{i18n.t('ORGANIZATION')}</label> */}
          {/*  <Autocomplete */}
          {/*    freeSolo */}
          {/*    id="free-solo-2-demo" */}
          {/*    disableClearable */}
          {/*    fullWidth */}
          {/*    margin="none" */}
          {/*    options={organizationsList.map((option) => option.title)} */}
          {/*    onChange={this.handleSelectOrganization} */}
          {/*    renderInput={(params) => ( */}
          {/*      <TextField */}
          {/*        placeholder={i18n.t('ORGANIZATION_PLACEHOLDER')} */}
          {/*        variant="outlined" */}
          {/*        margin="none" */}
          {/*        fullWidth */}
          {/*        onChange={this.handleOrganizationTitleChange} */}
          {/*        helperText={errors.organization} */}
          {/*        error={!!errors.organization} */}
          {/*        {...params} */}
          {/*        InputProps={{ */}
          {/*          ...params.InputProps, */}
          {/*          type: 'search', */}
          {/*        }} */}
          {/*      /> */}
          {/*    )} */}
          {/*  /> */}
          {/* </FormControl> */}
          <FormControl fullWidth margin="normal">
            <label htmlFor="outlined-adornment-telephone-number">{i18n.t('TELEPHONE_NUMBER')}</label>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="telephone-number"
              name="telephone-number"
              disabled
              autoFocus
              value={profile.telephoneNumber.getTelephoneNumberFromContract()}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={inProgress}
          >
            {i18n.t('CONFIRM_PROFILE')}
          </Button>
        </form>
      </>
    );
  }
}

export default withStyles(styles)(ConfirmUserProfileForm);

ConfirmUserProfileForm.propTypes = {
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onDropErrors: PropTypes.func,
  onFetchOrganizationsList: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  organizationsList: PropTypes.array,
  onClearOrganizationsList: PropTypes.func,
  profile: PropTypes.object,
};
