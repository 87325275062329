import {
  FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_FAIL,
  FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_START,
  FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_SUCCESS,
} from 'realtor/actions';

const initialState = {
  inProgress: false,
  error: null,
  totalCount: null,
};

// eslint-disable-next-line default-param-last
export const realEstateObjectsTotalCount = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_START:
      return {
        ...state,
        error: null,
        totalCount: null,
        inProgress: true,
      };
    case FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_FAIL:
      return {
        ...state,
        error: action.error,
        inProgress: false,
        totalCount: null,
      };

    case FETCH_REAL_ESTATE_OBJECTS_TOTAL_COUNT_SUCCESS:
      return {
        ...state,
        error: action.error,
        inProgress: false,
        totalCount: action.data.totalCount,
      };
    default:
      return state;
  }
};
