import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, TextareaAutosize, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';

import MoreTelephoneNumbers from 'realtor/components/common/MoreTelephoneNumbers/MoreTelephoneNumbers';
import TagsForm from 'realtor/components/forms/TagsForm/TagsForm';
import styles from './styles';
import i18n from '../../../../i18n';
import { fullNameToUpperCase } from '../../../helpers/fullNameHelper';

const CreateClientForm = ({
  classes,
  errors,
  client,
  onFieldChange,
  onLoadMoreTags,
  tag,
  onAssignTag,
  onAddNewTag,
}) => {
  const handleFullNameChange = (event) => {
    const { name, value } = event.target;

    onFieldChange({
      target: {
        value: fullNameToUpperCase(value),
        name,
      },
    });
  };

  const handleMoreTelephoneNumberChange = (moreTelephoneNumbers) =>
    onFieldChange({
      target: {
        value: moreTelephoneNumbers,
        name: 'moreTelephoneNumbers',
      },
    });

  return (
    <form className={classes.form} noValidate>
      <FormControl fullWidth margin="normal">
        <label htmlFor="outlined-adornment-full-name">{i18n.t('FIO')}</label>
        <TextField
          variant="outlined"
          margin="none"
          required
          fullWidth
          id="fullName"
          name="fullName"
          autoFocus
          onChange={handleFullNameChange}
          value={client.fullName}
          helperText={errors.fullName}
          error={!!errors.fullName}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <label htmlFor="outlined-adornment-telephoneNumber">{i18n.t('TELEPHONE_NUMBER')}</label>
        <InputMask mask="+38(099) 999 99 99" onChange={onFieldChange} value={client.telephoneNumber} alwaysShowMask>
          {() => (
            <TextField
              variant="outlined"
              margin="none"
              required
              fullWidth
              name="telephoneNumber"
              type="test"
              id="telephoneNumber"
              helperText={errors.telephoneNumber}
              error={!!errors.telephoneNumber}
            />
          )}
        </InputMask>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <MoreTelephoneNumbers
          moreTelephoneNumbers={client.moreTelephoneNumbers}
          errors={errors}
          onNumbersChange={handleMoreTelephoneNumberChange}
        />
      </FormControl>
      <TagsForm
        onAddNewTag={onAddNewTag}
        onLoadMoreTags={onLoadMoreTags}
        tag={tag}
        onAssignTag={onAssignTag}
        assignedTags={client.tags}
      />
      <FormControl fullWidth margin="normal">
        <label>{i18n.t('CLIENT_NOTATIONS')}</label>
        <TextareaAutosize
          rowsMin={5}
          onChange={onFieldChange}
          value={client.description}
          name="description"
          helperText={errors.description}
          error={!!errors.description}
          className={errors.description ? classes.descriptionAreaError : classes.description}
        />
      </FormControl>
      <FormControl>
        <FormHelperText className={classes.errorText}>{errors.description}</FormHelperText>
      </FormControl>
    </form>
  );
};

export default withStyles(styles)(CreateClientForm);

CreateClientForm.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object,
  tag: PropTypes.object,
  errors: PropTypes.object,
  onFieldChange: PropTypes.func,
  onLoadMoreTags: PropTypes.func,
  onAssignTag: PropTypes.func,
  onAddNewTag: PropTypes.func,
};
