import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable */
/* tslint:disable */
import { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { useGetAppConfigurationQuery } from '../../../../services/appConfigurationService';
import { RegistrationForm } from './components/RegistrationForm/RegistrationForm';
import styles from './styles';
import AuthPageLayer from '../../../../components/layears/AuthPageLayer/AuthPageLayer';
import FormHeaderLayer from '../../../../../../common/components/layers/FormHeaderLayer/FormHeaderLayer';
import AuthFooterLayer from '../../../../components/layears/AuthFooterLayer/AuthFooterLayer';
import { mapRegistrationErrors } from '../../../../helpers/errorsMapper';
import i18n from '../../../../../../common/i18n';
import { useRegistrationMutation } from '../../store/services/registrationApi';
import { RegistrationDataModel } from '../../models/RegistrationDataModel';
import { TelephoneNumberBuilder } from '../../../../../realtor/domain';
import ConfirmRegistrationService from '../../../../../../common/sevices/confirmRegistrationService';
export const RegistrationPageComponent = ({ classes, dropRegistrationErrors }) => {
    const history = useHistory();
    const { data: appConfigurations } = useGetAppConfigurationQuery();
    const [registration, { isLoading, isSuccess, error, data }] = useRegistrationMutation();
    const [localErrors, setLocalErrors] = useState({});
    const handleRegistrationConfirm = (data) => {
        const registrationDataModel = new RegistrationDataModel({
            telephoneNumber: TelephoneNumberBuilder.buildFromFormData(data.telephoneNumber),
            password: data.password,
            email: data.email,
        });
        registration(registrationDataModel);
    };
    useEffect(() => {
        if (isSuccess) {
            ConfirmRegistrationService.saveConfirmUserToken(data.token);
            // TODO: Use history.push('/confirm-registration');
            location.href = '/confirm-registration';
            // history.push('/confirm-registration');
        }
    }, [isSuccess]);
    useEffect(() => {
        if (error) {
            const errorData = error?.data?.error || {};
            const errorCode = errorData.code;
            const validationErrors = errorCode ? mapRegistrationErrors(appConfigurations.errorCodes, errorData) : {};
            setLocalErrors(validationErrors);
        }
    }, [error]);
    const renderRegistrationSuccess = () => (_jsx(Alert, { severity: "success", variant: "outlined", children: i18n.t('SUCCESS_REGISTRATION_TEXT') }));
    const renderHeader = () => (_jsxs(FormHeaderLayer, { title: i18n.t('REGISTRATION'), children: [`${i18n.t('ALREADY_HAVE_ACCOUNT')} `, _jsx(Link, { to: "/auth", component: RouterLink, children: i18n.t('SIGN_IN') })] }));
    return (_jsxs(AuthPageLayer, { children: [renderHeader(), _jsx("hr", { size: 1, className: classes.hr }), isSuccess ? (renderRegistrationSuccess()) : (_jsx(RegistrationForm, { odRegistration: handleRegistrationConfirm, errors: localErrors, onDropErrors: dropRegistrationErrors, inProgress: isLoading })), _jsx(AuthFooterLayer, { confirmText: i18n.t('REGISTRATION_CONFIRM_TEXT') })] }));
};
export const RegistrationPage = withStyles(styles)(RegistrationPageComponent);
