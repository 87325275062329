import ChipByType from 'realtor/components/layears/ChipByType/ChipByType.';
import { IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
import { ClipObjectFiltersCountWidget } from 'realtor/components/layears/ClipObjectFiltersCount/ClipObjectFiltersCount';
import i18n from '../../../../../../../common/i18n';
import style from './styles';
import CopyToClipboardIcon from '../../../../../../shared/svg/CopyToClipboardIcon/CopyToClipboardIcon';

export const RealEstateObjectDetailsHeader = ({ classes, realEstateObject, filters }) => {
  const handleCopyCode = () => {
    navigator.clipboard.writeText(realEstateObject.code);

    Alert.success(i18n.t('CODE_COPY_SUCCESS'), { position: 'top-right', effect: 'slide', timeout: 3000 });
  };

  return (
    <div>
      <div className={classes.headerRow}>
        <div>
          <ChipByType object={realEstateObject} paramsList={filters} isStatic />
          {realEstateObject.isMyObject && <ClipObjectFiltersCountWidget realEstateObject={realEstateObject} />}
        </div>
        <span>
          <cite>{realEstateObject.code}</cite>
          <IconButton onClick={handleCopyCode} size="small">
            <CopyToClipboardIcon />
          </IconButton>
        </span>
      </div>
      <Typography className={classes.headerTitle} align="left" variant="h2">
        {realEstateObject.title}
      </Typography>
    </div>
  );
};

RealEstateObjectDetailsHeader.propTypes = {
  realEstateObject: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
};

export default withStyles(style)(RealEstateObjectDetailsHeader);
