/* eslint-disable import/max-dependencies */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, debounce } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import styles from './styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Checkbox, Input, InputBase, InputLabel, Select } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import i18n from '../../../common/i18n';
import FormControl from '@material-ui/core/FormControl';
import ObjectHelper from '../../helpers/objectHelper';
import Button from '@material-ui/core/Button';
import Location from 'realtor/components/common/Location/Location';

const SEARCH_DELAY = 500;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export class ObjectFilters extends PureComponent {
  constructor(props) {
    super(props);
    const { filters } = props;

    const defaultInputs = {
      objectType: ObjectHelper.getObjectValues(filters.objectType),
      objectStates: ObjectHelper.getObjectValues(filters.objectStates),
      objectServiceType: ObjectHelper.getObjectValues(filters.objectServiceType),
      objectAccess: ObjectHelper.getObjectValues(filters.objectAccess),
      cityId: null,
      districtId: null,
      q: '',
    };

    this.state = {
      inputs: defaultInputs,
      defaultInputs,
    };
  }

  getMultiselectClasses(current, selected) {
    const { classes } = this.props;
    return selected.includes(current) ? classes.selectedItem : classes.regularItem;
  }

  /**
   * Get only changed filters
   */
  buildrRequestParamsByFilters(inputs) {
    const { defaultInputs } = this.state;
    const filters = {};
    for (const input in inputs) {
      switch (input) {
        case 'cityId':
        case 'districtId':
        case 'q':
          if (inputs[input]) filters[input] = inputs[input];
          break;
        default:
          if (inputs[input].length !== defaultInputs[input].length) filters[input] = inputs[input];
          break;
      }
    }

    return filters;
  }

  handleDiscardFilters = () => {
    const inputs = {
      ...this.state.defaultInputs,
      q: '',
      cityId: null,
      districtId: null,
    };

    this.setState({
      inputs,
    });

    const paramsForSubmit = this.buildrRequestParamsByFilters(inputs);
    this.props.onFilterSubmit(paramsForSubmit);
  };

  handleInputChange = (event) => {
    const { value, name } = event.target;
    if (value.length) {
      this.setState({
        ...this.state,
        inputs: {
          ...this.state.inputs,
          [name]: value,
        },
      });
    }
  };

  handleCityChange = (event) => {
    this.setState({
      ...this.state,
      inputs: {
        ...this.state.inputs,
        cityId: get(event, 'cityId', null),
      },
    });
  };

  handleDistrictChange = (data) => {
    this.setState({
      ...this.state,
      inputs: {
        ...this.state.inputs,
        districtId: data ? data.id : null,
      },
    });
  };

  handleSearchChangeDelay = debounce(() => {
    this.handleFiltersSubmit();
  }, SEARCH_DELAY);

  handleSearchChange = (event) => {
    const { value } = event.target;
    this.setState({
      ...this.state,
      inputs: {
        ...this.state.inputs,
        q: value,
      },
    });
    if (value.length === 0 || value.length > 2) this.handleSearchChangeDelay();
  };

  handleFiltersSubmit = () => {
    const paramsForSubmit = this.buildrRequestParamsByFilters(this.state.inputs);
    this.props.onFilterSubmit(paramsForSubmit);
  };

  renderMultiSelect = (list, label, name) => {
    const { classes } = this.props;
    const { inputs } = this.state;
    const menuItems = [];
    for (const menuItemsKey in list) {
      menuItems.push(
        <MenuItem
          key={list[menuItemsKey]}
          value={list[menuItemsKey]}
          className={this.getMultiselectClasses(list[menuItemsKey], inputs[name])}
        >
          <Checkbox checked={inputs[name].includes(list[menuItemsKey])} color="primary" />
          {i18n.t(`OBJECT_FILTER_${menuItemsKey}`)}
        </MenuItem>,
      );
    }
    return (
      <Grid item sm={6} md={4} lg={3} xl={2}>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel id={`${name}-mutiple-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-mutiple-label`}
            id={`${name}-mutiple`}
            multiple
            value={inputs[name]}
            onChange={this.handleInputChange}
            input={<Input />}
            name={name}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              const selectedKeys = Object.keys(list).filter((key) => selected.includes(list[key]));

              return selectedKeys.map((key) => i18n.t(`OBJECT_FILTER_${key}`)).join(', ');
            }}
          >
            {menuItems.map((item) => item)}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  renderCitiesFilter() {
    const { regions } = this.props;
    return (
      <Location
        regions={regions}
        onCityChange={this.handleCityChange}
        onDistrictChange={this.handleDistrictChange}
        cityId={this.state.inputs.cityId}
        selectedDistrict={this.state.inputs.districtId}
        gridParams={{
          sm: 6,
          md: 4,
          lg: 3,
          xl: 2,
          xs: 2,
        }}
      />
    );
  }

  renderFiltersToolbar() {
    const { classes } = this.props;
    return (
      <AccordionSummary
        className={classes.panelPlaceholder}
        expandIcon={<FilterListIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <Search />
          </div>
          <div>
            <InputBase
              placeholder={`${i18n.t('SEARCH')}...`}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={this.state.inputs.search}
              inputProps={{ 'aria-label': 'search' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={this.handleSearchChange}
            />
          </div>
        </div>
      </AccordionSummary>
    );
  }

  renderFilters = () => {
    const { filters, showAccessFilter } = this.props;
    return (
      <Grid container spacing={3}>
        {this.renderMultiSelect(filters.objectType, i18n.t('TYPE'), 'objectType')}
        {this.renderMultiSelect(filters.objectStates, i18n.t('STATE'), 'objectStates')}
        {this.renderMultiSelect(filters.objectServiceType, i18n.t('SERVICE_TYPE'), 'objectServiceType')}
        {showAccessFilter && this.renderMultiSelect(filters.objectAccess, i18n.t('ACCESS'), 'objectAccess')}
        {this.renderCitiesFilter()}
      </Grid>
    );
  };

  renderControlButtons = () => {
    const { classes } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <div className={classes.submitFilters}>
            <Button type="submit" variant="contained" onClick={this.handleDiscardFilters}>
              {i18n.t('DISCARD')}
            </Button>
            <Button type="submit" variant="contained" color="primary" onClick={this.handleFiltersSubmit}>
              {i18n.t('APPLY')}
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Accordion className={classes.accordion}>
        {this.renderFiltersToolbar()}
        <AccordionDetails>
          <Grid container>
            {this.renderFilters()}
            {this.renderControlButtons()}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withStyles(styles)(ObjectFilters);

ObjectFilters.propTypes = {
  onFilterSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  regions: PropTypes.array.isRequired,
  showAccessFilter: PropTypes.bool,
};
