export default (theme) => ({
    contentBox: {
        marginRight: '0px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    contentBoxWithOpenedFilter: {
        marginRight: '324px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    headerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    addClientButton: {
        background: '#F5841F',
        borderRadius: theme.spacing(3),
        color: '#fff',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14)',
        fontWeight: 700,
        padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
        opacity: 0.8,
        transition: '0.5s',
        '&:hover': {
            cursor: 'pointer',
            background: '#F5841F',
            opacity: 1,
            transition: '0.5s',
        },
    },
    paper: {
        height: '100%',
        position: 'relative',
    },
    emptyStateBox: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    emptyStateMarker: {
        display: 'block',
        width: theme.spacing(15),
        height: theme.spacing(15),
        backgroundColor: '#C4C4C4',
    },
    noClientsText: {
        padding: theme.spacing(2),
        fontSize: '2.3em',
    },
    wontAddClientText: {
        fontSize: '1.4em',
    },
    controlPanelBox: {
        alignItems: 'center',
    },
    '@media (max-width: 1024px)': {
        contentBoxWithOpenedFilter: {
            marginRight: '0px',
        },
        addClientButton: {
            display: 'none',
        },
    },
});
