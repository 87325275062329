import {
  FETCH_ORGANIZATIONS_LIST_FAIL,
  FETCH_ORGANIZATIONS_LIST_START,
  FETCH_ORGANIZATIONS_LIST_SUCCESS,
  CLEAN_ORGANIZATIONS_LIST,
} from '../actions';

const initialState = {
  inProgress: false,
  error: null,
  organizationsList: [],
};

// eslint-disable-next-line default-param-last
export const organization = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS_LIST_START:
      return {
        ...state,
        error: null,
        inProgress: true,
      };
    case FETCH_ORGANIZATIONS_LIST_FAIL:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };

    case FETCH_ORGANIZATIONS_LIST_SUCCESS:
      return {
        ...state,
        error: action.error,
        inProgress: false,
        organizationsList: action.organizationsList,
      };
    case CLEAN_ORGANIZATIONS_LIST:
      return {
        ...state,
        organizationsList: [],
      };
    default:
      return state;
  }
};
