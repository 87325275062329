import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_FILTER } from 'realtor/components/pages/ClientsPages/CreateClientPage/CreateClientPage';
import { CreateClientWithoutFiltersForm } from 'realtor/components/forms/CreateClientWithoutFiltersForm/CreateClientWithoutFiltersForm';

import { withStyles } from '@material-ui/core/styles';
import { Client, Tag, TelephoneNumberBuilder } from 'realtor/domain';
import { CreateClientRequestDataBuilder } from 'realtor/helpers/requestDataBuildes/CreateClientRequestDataBuilder';
import ClientResource from 'realtor/resourses/Client.resource';
import Link from '@material-ui/core/Link';
import i18n from '../../../../../common/i18n';
import styles from './styles';
import ClientsAssign from '../../common/ClientsAssign/ClientsAssign';
import { FullScreenLoader } from '../../../../components/FullScreenLoader/FullScreenLoader';
import { useGetAppConfigurationQuery } from '../../../../modules/appConfiguration/appConfigurationService';

const DEFAULT_CLIENT = {
  fullName: '',
  telephoneNumber: '',
  moreTelephoneNumbers: [],
  tags: [],
};

export const AssignClientPage = (props) => {
  const { classes, tag, restoreClient, fetchTagsList, createNewTag, dropCreatedTag, onClientSelected, client } = props;

  const [inProgress, setInProgress] = useState(false);
  const [isCreation, setIsCreation] = useState(false);
  const [creationError, setError] = useState({});
  const { data: appConfigurations } = useGetAppConfigurationQuery();

  const tagData = {
    ...tag,
    tagsList: tag.tagsList.map((tag) => tag.toFormEntity()),
  };

  const handleCreateClient = (client) => {
    const clientDomain = new Client({
      fullName: client.client.fullName,
      telephoneNumber: TelephoneNumberBuilder.buildFromFormData(client.client.telephoneNumber),
      description: client.client.description,
      moreTelephoneNumbers: client.client.moreTelephoneNumbers
        .filter(({ telephoneNumber }) => !!telephoneNumber)
        .map(({ telephoneNumber, id }, index) =>
          TelephoneNumberBuilder.buildClientTelephoneNumberFromFormData(telephoneNumber, id, index + 1, false),
        ),
      tags: client.client.tags.map((tag) => new Tag(tag)),
    });

    const requestData = CreateClientRequestDataBuilder.build({
      clientDomain,
    });

    setInProgress(true);

    ClientResource.create(requestData)
      .then(({ createdClient }) => {
        const createdClientDomain = new Client({
          id: createdClient.id,
          fullName: createdClient.fullName,
          telephoneNumber: TelephoneNumberBuilder.buildFromFormData(createdClient.telephoneNumber),
          description: null,
          moreTelephoneNumbers: createdClient.moreTelephoneNumbers
            .filter(({ telephoneNumber }) => !!telephoneNumber)
            .map(({ telephoneNumber, id }, index) =>
              TelephoneNumberBuilder.buildClientTelephoneNumberFromFormData(telephoneNumber, id, index + 1, false),
            ),
          tags: [],
        });
        onClientSelected(createdClientDomain);
        setIsCreation(false);
      })
      .catch(({ error }) => setError(error))
      .finally(() => setInProgress(false));
  };

  const handleAddClient = () => {
    setIsCreation(true);
  };

  const handleCloseAddClient = () => {
    setIsCreation(false);
  };

  const handleDropErrors = () => {
    setError({});
  };

  const showLoader = inProgress;

  return (
    <div>
      {showLoader && <FullScreenLoader />}
      <h2>{i18n.t('ADD_SALES')}</h2>
      {isCreation && (
        <Link onClick={handleCloseAddClient} className={classes.backToSelect}>
          {i18n.t('SELECT_EXIST_CLIENT')}
        </Link>
      )}
      {isCreation ? (
        <CreateClientWithoutFiltersForm
          classes={classes}
          onConfirm={handleCreateClient}
          client={DEFAULT_CLIENT}
          filter={DEFAULT_FILTER}
          appConfigurations={appConfigurations}
          onLoadMoreTags={fetchTagsList}
          onAddNewTag={createNewTag}
          tag={tagData}
          dropCreatedTag={dropCreatedTag}
          errors={creationError}
          dropErrors={handleDropErrors}
          onConfirmRestore={restoreClient}
          isSaveButtonDisabled={showLoader}
        />
      ) : (
        <ClientsAssign
          isCreation={isCreation}
          client={client}
          onAddClient={handleAddClient}
          onClientSelected={onClientSelected}
        />
      )}
    </div>
  );
};

AssignClientPage.propTypes = {
  classes: PropTypes.object,
  createNewTag: PropTypes.func.isRequired,
  dropCreatedTag: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
  fetchTagsList: PropTypes.func.isRequired,
  restoreClient: PropTypes.func.isRequired,
  onClientSelected: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssignClientPage);
