import PropTypes from 'prop-types';
import { Phone } from '@material-ui/icons';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import styles from './styles';
import viberLogo from '../../../../../../../../public/images/viber_logo.png';
import tgLogo from '../../../../../../../../public/images/telegram_logo.png';

const ClientContacts = ({ client, classes }) =>
  client ? (
    <div className={classes.objectContact}>
      <div className={classes.objectContactClientName}>
        <Phone className={classes.locationIcon} />
        <Tooltip placement="top" title={<h3>{client.fullName}</h3>} arrow>
          <span className={classes.clientFullName}>{client.fullName}</span>
        </Tooltip>
      </div>
      <div className={classes.objectContactClientCallActions}>
        <strong>
          <a href={client.telephoneNumber.getCallLink()}>{client.telephoneNumber.getTelephoneNumberView()}</a>
        </strong>
        <IconButton className={classes.socialButton} href={client.telephoneNumber.getViberUrl()} target="_blank">
          <img width={22} src={viberLogo} />
        </IconButton>
        <IconButton className={classes.socialButton} href={client.telephoneNumber.getTelegramUrl()} target="_blank">
          <img width={22} src={tgLogo} />
        </IconButton>
      </div>
    </div>
  ) : (
    <div className={classes.objectContact}>Без клієнта</div>
  );

ClientContacts.propTypes = {
  client: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(ClientContacts);
