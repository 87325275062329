import React from 'react';
import PropTypes from 'prop-types';
import { getCurrencyLabel } from 'realtor/helpers/currency';
import PriceRangeInput from 'realtor/components/common/PriceRangeInput/PriceRangeInput';
import i18n from '../../../../../common/i18n';

export const LandAreaPriceRangeFilter = ({ onSelect, filter, errors }) => {
  const currencyPrefix = getCurrencyLabel(filter.currency);
  const placeholder = `${currencyPrefix}${i18n.t('LAND_AREA_PRICE')}`;

  return (
    <PriceRangeInput
      valueFrom={filter.landAreaPriceFrom}
      valueTo={filter.landAreaPriceTo}
      nameFrom="landAreaPriceFrom"
      nameTo="landAreaPriceTo"
      onFieldChange={onSelect}
      label={i18n.t('LAND_AREA_PRICE_PLACEHOLDER')}
      placeholderFrom={placeholder}
      placeholderTo={placeholder}
      error={errors.landAreaPrice}
      currencyPrefix={currencyPrefix}
      errorFrom={errors.landAreaPriceFrom}
      errorTo={errors.landAreaPriceTo}
    />
  );
};

LandAreaPriceRangeFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};
