import {
  FETCH_MY_REAL_ESTATE_OBJECT_START,
  FETCH_MY_REAL_ESTATE_OBJECT_FAIL,
  FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS,
  ADD_OBJECT_IMAGES_SUCCESS,
  CREATE_REAL_ESTATE_OBJECT_START,
  CREATE_REAL_ESTATE_OBJECT_SUCCESS,
  CREATE_REAL_ESTATE_OBJECT_FAIL,
  UPDATE_REAL_ESTATE_OBJECT_FAIL,
  UPDATE_REAL_ESTATE_OBJECT_SUCCESS,
  UPDATE_REAL_ESTATE_OBJECT_START,
  ADD_OBJECT_IMAGES_START,
  ADD_OBJECT_IMAGES_FAIL,
  DELETE_OBJECT_IMAGE_SUCCESS,
  DELETE_OBJECT_IMAGE_START,
  DELETE_OBJECT_IMAGE_FAIL,
  SINGLE_REAL_ESTATE_FAVORITE_ACTION_START,
  SINGLE_REAL_ESTATE_FAVORITE_ACTION_FAILED,
} from '../actions';

const initialState = {
  inProgress: false,
  objectMutationInProgress: false,
  error: null,
  realEstateObject: null,
};

/* eslint-disable complexity */
// eslint-disable-next-line default-param-last
export const realEstateObject = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MY_REAL_ESTATE_OBJECT_START:
      return {
        ...state,
        error: null,
        inProgress: true,
      };
    case FETCH_MY_REAL_ESTATE_OBJECT_SUCCESS:
      return {
        ...state,
        realEstateObject: action.realEstateObject,
        inProgress: false,
        objectMutationInProgress: false,
      };
    case FETCH_MY_REAL_ESTATE_OBJECT_FAIL:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      };
    case CREATE_REAL_ESTATE_OBJECT_START:
    case ADD_OBJECT_IMAGES_START:
    case DELETE_OBJECT_IMAGE_START:
    case SINGLE_REAL_ESTATE_FAVORITE_ACTION_START:
    case UPDATE_REAL_ESTATE_OBJECT_START: {
      return {
        ...state,
        objectMutationInProgress: true,
      };
    }
    case CREATE_REAL_ESTATE_OBJECT_SUCCESS:
    case CREATE_REAL_ESTATE_OBJECT_FAIL:
    case UPDATE_REAL_ESTATE_OBJECT_FAIL:
    case UPDATE_REAL_ESTATE_OBJECT_SUCCESS:
    case ADD_OBJECT_IMAGES_FAIL:
    case DELETE_OBJECT_IMAGE_FAIL:
    case SINGLE_REAL_ESTATE_FAVORITE_ACTION_FAILED: {
      return {
        ...state,
        objectMutationInProgress: false,
      };
    }
    case ADD_OBJECT_IMAGES_SUCCESS: {
      // eslint-disable-next-line no-param-reassign
      state.realEstateObject.photos = [...state.realEstateObject.photos, ...action.payload];

      return {
        ...state,
        realEstateObject: Object.create(
          Object.getPrototypeOf(state.realEstateObject),
          Object.getOwnPropertyDescriptors(state.realEstateObject),
        ),
        objectMutationInProgress: false,
      };
    }
    case DELETE_OBJECT_IMAGE_SUCCESS:
      // eslint-disable-next-line no-param-reassign
      state.realEstateObject.photos = state.realEstateObject.photos.filter(({ id }) => id !== action.id);

      return {
        ...state,
        realEstateObject: Object.create(
          Object.getPrototypeOf(state.realEstateObject),
          Object.getOwnPropertyDescriptors(state.realEstateObject),
        ),
        objectMutationInProgress: false,
      };
    default:
      return state;
  }
};
