import { connect } from 'react-redux';

import CreateClientFilterPage from '../../components/pages/ClientFilterPages/CreateClientFilterPage/CreateClientFilterPage';

import { createClientFilter } from '../../actions';

const mapStateToProps = (state, { match }) => {
  const { clientFilter } = state;

  return {
    clientId: Number(match.params.clientId),
    inProgress: clientFilter.inProgress,
  };
};

const mapDispatchToProps = {
  createClientFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClientFilterPage);
