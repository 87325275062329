import SelectButtonByFilter from 'realtor/components/common/SelectButtonByFilter/SelectButtonByFilter';
import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../../../common/i18n';

export const ServiceTypeFilter = ({ filtersList, filter, onFilterChange }) => {
  const filterName = 'objectServiceType';
  const buttonTitlePrefix = 'FILTER_REAL_ESTATE_SERVICE_TYPE_';
  const header = `${i18n.t('PARAMS_FILTER_SERVICE_TYPE')}:`;

  return (
    <SelectButtonByFilter
      filter={filter}
      filtersList={filtersList}
      filterName={filterName}
      buttonTitlePrefix={buttonTitlePrefix}
      header={header}
      onSelect={onFilterChange}
    />
  );
};

ServiceTypeFilter.propTypes = {
  filtersList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
