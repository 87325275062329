import FavoriteClientResource from 'realtor/resourses/FavoriteClient.resource';
import Alert from 'react-s-alert';

export const SET_CLIENTS_LIST = 'SET_CLIENTS_LIST';
export const CLIENT_FAVORITE_ACTION_START = 'CLIENT_FAVORITES_ACTION_START';
export const CLIENT_FAVORITE_ACTION_FAILED = 'CLIENT_FAVORITES_ACTION_FAILED';

const clientFavoriteActionStart = () => ({
  type: CLIENT_FAVORITE_ACTION_START,
});

const clientFavoriteActionFailed = () => ({
  type: CLIENT_FAVORITE_ACTION_FAILED,
});

const addToFavoriteSuccess = (clientId, list) => {
  const clientsList = list.map((client) => {
    if (client.id === clientId) {
      client.isFavorite = true;
    }
    return client;
  });

  return { type: SET_CLIENTS_LIST, clientsList };
};

const removeFromFavoriteSuccess = (clientId, list) => {
  const clientsList = list.map((client) => {
    if (client.id === clientId) {
      client.isFavorite = false;
    }
    return client;
  });

  return { type: SET_CLIENTS_LIST, clientsList };
};

export const addToFavorite = (client) => (dispatch, getState) => {
  const state = getState();
  dispatch(clientFavoriteActionStart());
  FavoriteClientResource.addToFavorite(client.id)
    .then(() => {
      dispatch(addToFavoriteSuccess(client.id, state.clientList.list));
    })
    .catch((error) => {
      dispatch(clientFavoriteActionFailed());
      Alert.success(error, { position: 'top-right', effect: 'slide', timeout: 3000 });
    });
};

export const removeFromFavorite = (client) => (dispatch, getState) => {
  const state = getState();
  dispatch(clientFavoriteActionStart());
  FavoriteClientResource.removeFromFavorite(client.id)
    .then(() => {
      dispatch(removeFromFavoriteSuccess(client.id, state.clientList.list));
    })
    .catch((error) => {
      dispatch(clientFavoriteActionFailed());
      Alert.success(error, { position: 'top-right', effect: 'slide', timeout: 3000 });
    });
};
