import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FileUploader } from 'react-drag-drop-files';
import { withStyles } from '@material-ui/core/styles';
import Alert from 'react-s-alert';
import styles from './styles';
import i18n from '../../../../../common/i18n';
import { MAX_PHOTO_SIZE } from '../../../../constants/photos';
const FileUploadBox = ({ onUpload, classes }) => {
    const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG'];
    const handleChange = (files) => {
        const filesToUpload = Array.from(files).filter(({ size }) => size < MAX_PHOTO_SIZE);
        if (files.length !== filesToUpload.length) {
            Alert.error(i18n.t('INVALID_PHOTO_SIZE'), { position: 'top-right', effect: 'slide', timeout: 3000 });
        }
        onUpload({
            target: {
                files: filesToUpload,
            },
        });
    };
    return (_jsx(FileUploader, { multiple: true, handleChange: handleChange, types: fileTypes, name: "files", children: _jsxs("div", { className: classes.uploadBox, children: [_jsx("h1", { children: "Drag&Drop" }), _jsx("p", { className: classes.addPhotosSlogan, children: i18n.t('ADD_PHOTOS_BUTTON') })] }) }));
};
export default withStyles(styles)(FileUploadBox);
