import React from 'react';
import CustomSwitch from 'realtor/components/common/CustomSwitch/CustomSwitch';
import PropTypes from 'prop-types';
import i18n from '../../../../../common/i18n';

export const MortgageFilter = ({ filter, onFilterChange }) => {
  const name = 'isMortgage';
  return (
    <CustomSwitch
      label={i18n.t('FILTER_REAL_ESTATE_ONLY_MORTGAGE')}
      name={name}
      checked={filter[name]}
      onChange={onFilterChange}
    />
  );
};

MortgageFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
