import Alert from 'react-s-alert';

import ClientResource from '../resourses/Client.resource';
import i18n from '../../../common/i18n';
import { onRedirect } from '../../actions/redirect';
import { CreateClientRequestDataBuilder } from '../helpers/requestDataBuildes/CreateClientRequestDataBuilder';
import { Client, serverFilterToDomainFactory, Tag, TelephoneNumberBuilder } from '../domain';

export const CREATE_CLIENT_START = 'CREATE_CLIENT_START';
export const CREATE_CLIENT_FAIL = 'CREATE_CLIENT_FAIL';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';

export const DELETE_CLIENT_START = 'DELETE_CLIENT_START';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';

export const FETCH_CLIENT_START = 'FETCH_CLIENT_START';
export const FETCH_CLIENT_FAIL = 'FETCH_CLIENT_FAIL';
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS';

export const UPDATE_CLIENT_START = 'UPDATE_CLIENT_START';
export const UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';

export const DROP_ERRORS_SUCCESS = 'DROP_ERRORS_SUCCESS';

export const RESTORE_CLIENT_START = 'RESTORE_CLIENT_START';
export const RESTORE_CLIENT_FAIL = 'RESTORE_CLIENT_FAIL';
export const RESTORE_CLIENT_SUCCESS = 'RESTORE_CLIENT_SUCCESS';

const restoreClientStart = () => ({ type: RESTORE_CLIENT_START });

const restoreClientSuccess = () => ({ type: RESTORE_CLIENT_SUCCESS });

const restoreClientFail = (error) => ({ type: RESTORE_CLIENT_FAIL, error });

const createClientStart = () => ({ type: CREATE_CLIENT_START });

const createClientSuccess = (clientData) => ({ type: CREATE_CLIENT_SUCCESS, clientData });

const createClientFail = (error) => ({ type: CREATE_CLIENT_FAIL, error });

export const deleteClientStart = () => ({ type: DELETE_CLIENT_START });

export const deleteClientSuccess = () => ({ type: DELETE_CLIENT_SUCCESS });

export const deleteClientFail = (error) => ({ type: DELETE_CLIENT_FAIL, error });

const fetchClientStart = () => ({ type: FETCH_CLIENT_START });

const fetchClientSuccess = (client, types) => {
  const clientDomain = new Client({
    ...client,
    moreTelephoneNumbers: client.moreTelephoneNumbers.map(({ telephoneNumber, id }) =>
      TelephoneNumberBuilder.buildFromServerData(telephoneNumber, id),
    ),
    telephoneNumber: TelephoneNumberBuilder.buildFromServerData(client.telephoneNumber),
    filters: client.filters.map((filter) => serverFilterToDomainFactory(filter, types)),
    tags: client.tags.map((tag) => new Tag(tag)),
  });

  return { type: FETCH_CLIENT_SUCCESS, clientData: clientDomain };
};

const fetchClientFail = (error) => ({ type: FETCH_CLIENT_FAIL, error });

const updateClientStart = () => ({ type: UPDATE_CLIENT_START });

const updateClientSuccess = (clientData) => ({ type: UPDATE_CLIENT_SUCCESS, clientData });

const updateClientFail = (error) => ({ type: UPDATE_CLIENT_FAIL, error });

const dropErrorsSuccess = () => ({ type: DROP_ERRORS_SUCCESS });

export const dropErrors = () => (dispatch) => dispatch(dropErrorsSuccess());

export const createClient = (clientWithFilterFormData) => (dispatch) => {
  const clientDomain = new Client({
    fullName: clientWithFilterFormData.client.fullName,
    telephoneNumber: TelephoneNumberBuilder.buildFromFormData(clientWithFilterFormData.client.telephoneNumber),
    description: clientWithFilterFormData.client.description,
    moreTelephoneNumbers: clientWithFilterFormData.client.moreTelephoneNumbers
      .filter(({ telephoneNumber }) => !!telephoneNumber)
      .map(({ telephoneNumber, id }, index) =>
        TelephoneNumberBuilder.buildClientTelephoneNumberFromFormData(telephoneNumber, id, index + 1, false),
      ),
    tags: clientWithFilterFormData.client.tags.map((tag) => new Tag(tag)),
  });

  dispatch(createClientStart());
  const requestData = CreateClientRequestDataBuilder.build({
    clientDomain,
  });

  ClientResource.create(requestData)
    .then((response) => {
      dispatch(createClientSuccess(response));
      return dispatch(onRedirect(`/clients/${response.createdClient.id}/filter/create`));
    })
    .catch(({ error }) => dispatch(createClientFail(error)));
};

export const updateClient = (clientId, clientWithFilterFormData) => (dispatch) => {
  const clientDomain = new Client({
    id: clientId,
    fullName: clientWithFilterFormData.client.fullName,
    description: clientWithFilterFormData.client.description,
    telephoneNumber: TelephoneNumberBuilder.buildFromFormData(clientWithFilterFormData.client.telephoneNumber),
    moreTelephoneNumbers: clientWithFilterFormData.client.moreTelephoneNumbers
      .filter(({ telephoneNumber }) => !!telephoneNumber)
      .map(({ telephoneNumber, id }, index) =>
        TelephoneNumberBuilder.buildClientTelephoneNumberFromFormData(telephoneNumber, id, index + 1, false),
      ),
    tags: clientWithFilterFormData.client.tags.map((tag) => new Tag(tag)),
  });

  dispatch(updateClientStart());
  const requestData = CreateClientRequestDataBuilder.build({
    clientDomain,
  });

  ClientResource.updateClient(clientId, requestData)
    .then((clientData) => {
      dispatch(updateClientSuccess(clientData));
      Alert.success(i18n.t('CLIENT_SUCCESSFULLY_UPDATED', { fullName: clientData.fullName }), {
        position: 'top-right',
        effect: 'slide',
        timeout: 3000,
      });
      return dispatch(onRedirect('/clients'));
    })
    .catch(({ error }) => {
      dispatch(updateClientFail(error));
    });
};

export const deleteClient = (client) => (dispatch) => {
  dispatch(deleteClientStart());
  ClientResource.deleteClient(client.id)
    .then(() => {
      dispatch(deleteClientSuccess());
    })
    .catch((error) => {
      dispatch(deleteClientFail(error));
    });
};

export const fetchClient = (clientId) => (dispatch, getState) => {
  const state = getState();

  dispatch(fetchClientStart());
  ClientResource.getSingleClient(clientId)
    .then((clientData) => {
      dispatch(fetchClientSuccess(clientData, state.appConfigurations.objectFilters.objectType));
    })
    .catch((error) => {
      dispatch(fetchClientFail(error));
    });
};

export const restoreClient = (clientId) => (dispatch) => {
  dispatch(restoreClientStart());
  ClientResource.restoreClient(clientId)
    .then((clientData) => {
      dispatch(restoreClientSuccess());
      Alert.success(i18n.t('CLIENT_SUCCESSFULLY_RESTORED', { fullName: clientData.fullName }), {
        position: 'top-right',
        effect: 'slide',
        timeout: 3000,
      });
      return dispatch(onRedirect(`/clients/${clientData.id}/update`));
    })
    .catch((error) => {
      dispatch(restoreClientFail(error));
    });
};
