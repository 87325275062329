import { BaseValidator } from './BaseValidator';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import { NOTES_AND_DESCRIPTION_LENGTH } from '../../../../constants/validation';

export class ApartmentFilterValidator extends BaseValidator {
  // eslint-disable-next-line max-statements
  validate() {
    const errors = {};

    errors.area = this.validateArea();

    errors.kitchenArea = this.validateKitchenArea();

    errors.floor = this.validateFloor();

    errors.squarePrice = this.validateSquarePrice();

    errors.totalPrice = this.validateTotalPrice();

    errors.livingArea = this.validateLivingArea();

    errors.city = FormValidation.validateRequired(this.filter.cityId);

    errors.objectServiceType = FormValidation.validateRequired(this.filter.objectServiceType);

    errors.description = FormValidation.validateLength(this.filter.description, 0, NOTES_AND_DESCRIPTION_LENGTH);

    if (!errors.area) {
      errors.areaFrom = this.validateMaxValue(this.filter.areaFrom);
      errors.areaTo = this.validateMaxValue(this.filter.areaTo);
    }

    if (!errors.kitchenArea) {
      errors.kitchenAreaFrom = this.validateMaxValue(this.filter.kitchenAreaFrom);
      errors.kitchenAreaTo = this.validateMaxValue(this.filter.kitchenAreaTo);
    }

    if (!errors.livingArea) {
      errors.livingAreaFrom = this.validateMaxValue(this.filter.livingAreaFrom);
      errors.livingAreaTo = this.validateMaxValue(this.filter.livingAreaTo);
    }

    if (!errors.floor) {
      errors.floorFrom = this.validateMaxValue(this.filter.floorFrom);
      errors.floorTo = this.validateMaxValue(this.filter.floorTo);
    }

    if (!errors.squarePrice) {
      errors.squarePriceFrom = this.validatePriceWithCurrency(this.filter.squarePriceFrom);
      errors.squarePriceTo = this.validatePriceWithCurrency(this.filter.squarePriceTo);
    }

    if (!errors.totalPrice) {
      const requiredError = FormValidation.validateRequired(this.filter.totalPriceTo);

      errors.totalPriceFrom = this.validatePriceWithCurrency(this.filter.totalPriceFrom);
      errors.totalPriceTo = requiredError || this.validatePriceWithCurrency(this.filter.totalPriceTo);
    }

    return errors;
  }
}
