import React, { Fragment, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

import RealtorPageHeader from '../../../layears/RealtorPageHeader/RealtorPageHeader';
import i18n from '../../../../../../common/i18n';
import CreateClientWithFiltersForm from '../../../forms/CreateClientWithFiltersForm/CreateClientWithFiltersForm';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';
import { FullScreenLoader } from '../../../../../components/FullScreenLoader/FullScreenLoader';
import { useGetAppConfigurationQuery } from '../../../../../modules/appConfiguration/appConfigurationService';

export const DEFAULT_FILTER = {
  currency: 1,
};

export const CreateClientPage = ({
  classes,
  onCreateClient,
  fetchTagsList,
  tag,
  createNewTag,
  dropCreatedTag,
  error,
  dropErrors,
  restoreClient,
  inProgress,
}) => {
  const pageTitle = i18n.t('INFORMATION_ABOUT_CLIENT');
  const { data: appParams } = useGetAppConfigurationQuery();

  useEffect(() => {
    setPageTitle(pageTitle);
  }, []);
  const renderHeader = () => (
    <RealtorPageHeader>
      <div className={classes.headerBox}>
        <h1>{i18n.t('ADD_NEW_CLIENT')}</h1>
      </div>
    </RealtorPageHeader>
  );

  const fatsData = {
    ...tag,
    tagsList: tag.tagsList.map((tag) => tag.toFormEntity()),
  };

  const DEFAULT_CLIENT = {
    fullName: '',
    telephoneNumber: '',
    moreTelephoneNumbers: [],
    tags: [],
  };

  return (
    <>
      {renderHeader()}
      <Paper className={classes.paper} elevation={0}>
        <h2>{i18n.t('INFORMATION_ABOUT_CLIENT')}</h2>
        <div>
          {inProgress && <FullScreenLoader />}
          <CreateClientWithFiltersForm
            onConfirm={onCreateClient}
            client={DEFAULT_CLIENT}
            filter={DEFAULT_FILTER}
            appConfigurations={appParams}
            onLoadMoreTags={fetchTagsList}
            onAddNewTag={createNewTag}
            tag={fatsData}
            dropCreatedTag={dropCreatedTag}
            errors={error}
            dropErrors={dropErrors}
            onConfirmRestore={restoreClient}
          />
        </div>
      </Paper>
    </>
  );
};

CreateClientPage.propTypes = {
  classes: PropTypes.object,
  error: PropTypes.object,
  inProgress: PropTypes.bool,
  onCreateClient: PropTypes.func.isRequired,
  createNewTag: PropTypes.func.isRequired,
  dropCreatedTag: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
  fetchTagsList: PropTypes.func.isRequired,
  restoreClient: PropTypes.func.isRequired,
  dropErrors: PropTypes.func.isRequired,
};

export default withStyles(styles)(CreateClientPage);
