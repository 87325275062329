import { BaseValidator } from './BaseValidator';
import { FormValidation } from '../../../../../common/helpers/FormValidation';
import { NOTES_AND_DESCRIPTION_LENGTH } from '../../../../constants/validation';

export class CommercialFilterValidator extends BaseValidator {
  // eslint-disable-next-line max-statements
  validate() {
    const errors = {};

    errors.area = this.validateArea();

    errors.floor = this.validateFloor();

    errors.squarePrice = this.validateSquarePrice();

    errors.totalPrice = this.validateTotalPrice();

    errors.objectPartialServiceType = FormValidation.validateRequired(this.filter.objectPartialServiceType);

    errors.city = FormValidation.validateRequired(this.filter.cityId);

    errors.description = FormValidation.validateLength(this.filter.description, 0, NOTES_AND_DESCRIPTION_LENGTH);

    if (!errors.area) {
      errors.areaFrom = this.validateMaxValue(this.filter.areaFrom);
      errors.areaTo = this.validateMaxValue(this.filter.areaTo);
    }

    if (!errors.floor) {
      errors.floorFrom = this.validateMaxValue(this.filter.floorFrom);
      errors.floorTo = this.validateMaxValue(this.filter.floorTo);
    }

    if (!errors.squarePrice) {
      errors.squarePriceFrom = this.validatePriceWithCurrency(this.filter.squarePriceFrom);
      errors.squarePriceTo = this.validatePriceWithCurrency(this.filter.squarePriceTo);
    }

    if (!errors.totalPrice) {
      const requiredError = FormValidation.validateRequired(this.filter.totalPriceTo);

      errors.totalPriceFrom = this.validatePriceWithCurrency(this.filter.totalPriceFrom);
      errors.totalPriceTo = requiredError || this.validatePriceWithCurrency(this.filter.totalPriceTo);
    }

    return errors;
  }
}
