import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import RealtorPageHeader from 'realtor/components/layears/RealtorPageHeader/RealtorPageHeader';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';
import CreateSingleRealEstateObjectForm from 'realtor/components/forms/CreateSingleRealEstateObjectForm/CreateSingleRealEstateObjectForm';
import AssignClientContainer from 'realtor/containers/Client/AssignClientContainer';
import SaveWithoutClient from 'realtor/components/dialogs/SaveWithoutClient/SaveWithoutClient';
import { useHistory } from 'react-router-dom';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';
import styles from './styles';
import i18n from '../../../../../../common/i18n';
import { FullScreenLoader } from '../../../../../components/FullScreenLoader/FullScreenLoader';
import { useGetAppConfigurationQuery } from '../../../../../allAccess/services/appConfigurationService';

// eslint-disable-next-line max-statements
export const UpdateRealEstateObjectPage = ({
  classes,
  inProgress,
  realEstateObjectId,
  getMyRealEstateObject,
  realEstateObject,
  updateRealEstateObject,
  addObjectPhotos,
  deleteObjectPhoto,
  fetchTagsList,
  tag,
  createNewTag,
  dropCreatedTag,
  objectMutationInProgress,
}) => {
  const history = useHistory();
  const { data: appConfigurations } = useGetAppConfigurationQuery();

  const [client, setClient] = useState(() => null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(() => true);
  const [isPhotoWasChanged, setIsPhotoWasChanged] = useState(() => false);
  const [isSaveWithoutClientDialogOpen, setIsSaveWithoutClientDialogOpen] = useState(() => false);
  const [realEstateObjectToSave, setRealEstateObjectToSave] = useState(() => null);

  const topRef = useRef(null);

  const executeScroll = () => topRef.current.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    if (realEstateObject && realEstateObject.title) {
      setPageTitle(i18n.t('EDIT_REAL_ESTATE_OBJECT_WINDOW_TITLES', { realEstateObjectTitle: realEstateObject.title }));
    }
  }, [realEstateObject]);

  const fatsData = useMemo(
    () => ({
      ...tag,
      tagsList: tag.tagsList.map((tag) => tag.toFormEntity()),
    }),
    [tag],
  );

  useEffect(() => {
    getMyRealEstateObject(realEstateObjectId, appConfigurations);
  }, [realEstateObjectId]);

  useEffect(() => {
    if (realEstateObject && realEstateObject.client) {
      return setClient(realEstateObject.client);
    }
  }, [realEstateObject]);

  const handleEditContinue = () => {
    setIsSaveWithoutClientDialogOpen(false);
    executeScroll();
  };

  const handlePhotosAdded = (photos) => {
    addObjectPhotos(realEstateObjectId, photos);
    setIsPhotoWasChanged(true);
  };

  const handlePhotosDeleted = (realEstateObjectId, imageId) => {
    deleteObjectPhoto(realEstateObjectId, imageId);
    setIsPhotoWasChanged(true);
  };

  const handleClientChange = (client) => {
    setClient(client);
    setIsSaveButtonDisabled(false);
  };

  const handleConfirmSaveWithoutClient = () => {
    updateRealEstateObject(
      realEstateObjectId,
      {
        ...realEstateObjectToSave,
        clientId: client ? client.id : null,
      },
      appConfigurations,
    );
  };

  const handleSaveRealEstateObject = (realEstateObject) => {
    if (isSaveButtonDisabled && isPhotoWasChanged) {
      return history.push(`/real-estate-object/${realEstateObject.id}`);
    }

    if (!client) {
      setIsSaveWithoutClientDialogOpen(true);
      setRealEstateObjectToSave(realEstateObject);
    } else {
      updateRealEstateObject(
        realEstateObjectId,
        {
          ...realEstateObject,
          clientId: client ? client.id : null,
        },
        appConfigurations,
      );
    }
  };
  const renderHeader = () => (
    <RealtorPageHeader>
      <div className={classes.headerBox}>
        <h1>{i18n.t('EDIT_REAL_ESTATE_OBJECT')}</h1>
      </div>
    </RealtorPageHeader>
  );

  const renderForm = () => {
    const isSaveButtonEnabled = !isSaveButtonDisabled || isPhotoWasChanged;

    return (
      <>
        <div ref={topRef} />
        <div className={classes.clientBlock}>
          <AssignClientContainer client={client} onClientSelected={handleClientChange} />
        </div>
        <div>
          <CreateSingleRealEstateObjectForm
            onConfirm={handleSaveRealEstateObject}
            realEstateObject={realEstateObject || {}}
            appConfigurations={appConfigurations}
            confirmButtonText={i18n.t('SAVE_REAL_ESTATE_OBJECT_SAVE_BUTTON_TEXT')}
            cancelButtonText={i18n.t('CANCEL_REAL_ESTATE_OBJECT_SAVE_BUTTON_TEXT')}
            disableTypeSelect
            isSaveButtonDisabled={!isSaveButtonEnabled}
            onAnyValueChange={() => setIsSaveButtonDisabled(false)}
            onObjectPhotosAdded={handlePhotosAdded}
            deleteObjectPhoto={handlePhotosDeleted}
            fetchTagsList={fetchTagsList}
            tag={fatsData}
            onCreateNewTag={createNewTag}
            dropCreatedTag={dropCreatedTag}
          />
        </div>
      </>
    );
  };

  const renderLoader = () => <CircularProgressBox />;

  const renderContent = () => {
    const showForm = !inProgress;

    return showForm ? renderForm() : renderLoader();
  };

  return (
    <>
      {objectMutationInProgress && <FullScreenLoader />}
      {renderHeader()}
      <Paper className={classes.paper} elevation={0}>
        {renderContent()}
        <SaveWithoutClient
          isDialogOpen={isSaveWithoutClientDialogOpen}
          onEditContinue={handleEditContinue}
          onSaveWithoutClient={handleConfirmSaveWithoutClient}
        />
      </Paper>
    </>
  );
};

UpdateRealEstateObjectPage.propTypes = {
  classes: PropTypes.object.isRequired,
  inProgress: PropTypes.bool.isRequired,
  objectMutationInProgress: PropTypes.bool.isRequired,
  getMyRealEstateObject: PropTypes.func.isRequired,
  updateRealEstateObject: PropTypes.func.isRequired,
  realEstateObjectId: PropTypes.number.isRequired,
  realEstateObject: PropTypes.object.isRequired,
  addObjectPhotos: PropTypes.func.isRequired,
  deleteObjectPhoto: PropTypes.func.isRequired,
  fetchTagsList: PropTypes.func.isRequired,
  createNewTag: PropTypes.func.isRequired,
  dropCreatedTag: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
};

export default withStyles(styles)(UpdateRealEstateObjectPage);
