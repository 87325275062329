import axios from 'axios';
import { get } from 'lodash';
import i18n from './i18n';
import { isIE11 } from './isIE11';
import AuthService from './sevices/auth';
import { DOMAIN } from '../Config';

const instance = axios.create({
  baseURL: DOMAIN,
});

instance.interceptors.request.use((config) => {
  // TODO: add user info to request headers so request can be authorized
  // config.headers.common.User = extractUserInfo();

  // add language info to request headers
  // eslint-disable-next-line no-param-reassign
  config.headers.common['User-Language'] = i18n.language;

  // Set standard HTTP/1.0 no-cache header to disable caching requests in IE11
  // since IE11 cache GET requests with different language headers
  // eslint-disable-next-line no-param-reassign
  if (isIE11) config.headers.common.Pragma = 'no-cache';

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${AuthService.getToken()}`;

  // eslint-disable-next-line no-param-reassign
  config.maxRedirects = 0;

  return config;
});

instance.interceptors.response.use(
  (data) => data,
  (err) => {
    const status = get(err, 'response.status');
    const error = get(err, 'response.data.error') || get(err, 'response.data');
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ error, status });
  },
);

export default instance;
