import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ClientsList from 'realtor/components/common/ClientsList/ClientList';

import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';
import styles from './styles';
import i18n from '../../../../../../common/i18n';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';
import { FullScreenLoader } from '../../../../../components/FullScreenLoader/FullScreenLoader';

const ORDER_LIST = {
  FROM_NEWEST_TO_OLDER: {
    order: 'created_at',
    dir: 'DESC',
  },
  FROM_OLDER_TO_NEWEST: {
    order: 'created_at',
    dir: 'ASC',
  },
};

const ORDER_KEYS = Object.keys(ORDER_LIST);

export const ClientList = ({
  fetchMyTotal,
  inProgress,
  totalCount,
  dropTotalCount,
  isDeleteActionInProgress,
  ...props
}) => {
  const pageTitle = i18n.t('MY_CLIENTS');

  useEffect(
    () => () => {
      dropTotalCount();
    },
    [],
  );

  useEffect(() => {
    setPageTitle(pageTitle);
    fetchMyTotal();
  }, []);

  if (inProgress || totalCount === null) {
    return <CircularProgressBox />;
  }

  return (
    <>
      {isDeleteActionInProgress && <FullScreenLoader />}
      <ClientsList
        {...props}
        totalCount={totalCount}
        pageTitle={pageTitle}
        isOwnerList
        orderList={ORDER_LIST}
        orderKeys={ORDER_KEYS}
      />
    </>
  );
};

ClientList.propTypes = {
  classes: PropTypes.object,
  clientList: PropTypes.array,
  citiesList: PropTypes.array,
  tagsList: PropTypes.array,
  fetchClients: PropTypes.func,
  fetchTotalAndList: PropTypes.func,
  fetchMyTotal: PropTypes.func,
  totalListCount: PropTypes.number,
  totalCount: PropTypes.number,
  inProgress: PropTypes.bool,
  isDeleteActionInProgress: PropTypes.bool,
  listInProgress: PropTypes.bool,
  onDelete: PropTypes.func,
  onDeleteClientFilter: PropTypes.func,
  dropTotalCount: PropTypes.func,
  paramsList: PropTypes.object,
  fetchTagsList: PropTypes.string.isRequired,
};

export default withStyles(styles)(ClientList);
