import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines */
/* eslint-disable import/max-dependencies */
import { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DeleteClientDialog, DeleteClientFilterDialog, DeleteClientFromFavoriteDialog, } from 'realtor/components/dialogs';
import { get, isEmpty } from 'lodash';
import PageHeader from 'realtor/components/pages/ClientsPages/ClientsListPage/layouts/PageHeader/PageHeader';
import CustomSwitch from 'realtor/components/common/CustomSwitch/CustomSwitch';
import { getFilterParamsFromQuery } from 'realtor/components/layears/ClipObjectFiltersCount/helpers/searchUrl';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './styles';
import ClientsList from '../../layears/ClientsList/ClientsList';
import Pagination from '../../layears/Pagination/Pagination';
import { CircularProgressBox } from '../../layears/CircularProgressBox/CircularProgressBox';
import ClientsControlPanel from '../../layears/ClientsControlPanel/ClientsControlPanel';
import FiltersBar from '../../layears/FiltersBar/FiltersBar';
import { NoClientsFound, NoClients } from './layouts/index';
import i18n from '../../../../../common/i18n';
import { OrderKeys } from '../types';
import { useGetAppConfigurationQuery } from '../../../../modules/appConfiguration/appConfigurationService';
const DEFAULT_LIMITS = [10, 30];
const DEFAULT_OFFSET = 0;
const DEFAULT_PAGINATION = {
    limit: DEFAULT_LIMITS[0],
    offset: DEFAULT_OFFSET,
};
const DEFAULT_FILTERS = {
    search: '',
    showClientsWithoutParams: false,
    showOnlyFavorites: false,
};
// eslint-disable-next-line max-statements
const ClientList = ({ classes, totalCount, clientList, isOwnerList, pageTitle, fetchClients, orderKeys, orderList, tagsList, fetchTagsList, onDelete, onDeleteClientFilter, onAddToFavorite, onRemoveFromFavorite, inProgress, isCreateButtonDisabled, listInProgress, totalListCount, }) => {
    const history = useHistory();
    const { search } = useLocation();
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [filterBarState, setFilterBarState] = useState({});
    const [listFetchParams, setListFetchParams] = useState({
        pagination: DEFAULT_PAGINATION,
        filters: DEFAULT_FILTERS,
        paramsFilter: {},
        order: OrderKeys.FROM_NEWEST_TO_OLDER,
    });
    const { data: appParams } = useGetAppConfigurationQuery();
    const getFetchParams = (params) => {
        const tagId = params.filters.tag ? params.filters.tag.id : undefined;
        const { tag, ...filters } = params.filters;
        return {
            ...params.pagination,
            ...filters,
            ...params.paramsFilter,
            ...orderList[params.order],
            tagId,
        };
    };
    const handleFilterChange = (filters) => {
        setIsFiltersOpen(false);
        setListFetchParams({
            ...listFetchParams,
            pagination: {
                ...listFetchParams.pagination,
                offset: DEFAULT_OFFSET,
            },
            paramsFilter: filters,
        });
    };
    useEffect(() => {
        if (!search) {
            fetchClients(getFetchParams(listFetchParams), appParams);
        }
    }, [listFetchParams]);
    useEffect(() => {
        if (!isOwnerList && search) {
            const params = getFilterParamsFromQuery(search);
            setListFetchParams({
                ...listFetchParams,
                paramsFilter: params,
            });
            setFilterBarState(params);
            handleFilterChange(params);
            if (search) {
                history.replace({
                    pathname: window.location.pathname,
                    state: history.location.state,
                });
            }
        }
    }, [search]);
    const [clientDeleteDialog, setClientDeleteDialog] = useState({
        clientToDelete: null,
        isDeleteDialogOpen: false,
    });
    const [clientToDeleteFromFavorite, setClientToDeleteFromFavorite] = useState({
        clientToDeleteFromFavorite: null,
        isDeleteFromFavoriteDialogOpen: false,
    });
    const [clientFilterDeleteDialog, setClientFilterDeleteDialog] = useState({
        filterId: null,
        clientId: null,
        isFilterDeleteDialogOpen: false,
    });
    const handleFiltersToggle = () => {
        setIsFiltersOpen(!isFiltersOpen);
    };
    const handleLimitChange = (limit) => {
        const pagination = {
            limit,
            offset: 0,
        };
        setListFetchParams({
            ...listFetchParams,
            pagination,
        });
    };
    const handlePaginationBack = () => {
        const { pagination: { offset, limit }, } = listFetchParams;
        const pagination = {
            ...listFetchParams.pagination,
            offset: offset - limit,
        };
        setListFetchParams({
            ...listFetchParams,
            pagination,
        });
    };
    const handlePaginationForward = () => {
        const { pagination: { offset, limit }, } = listFetchParams;
        const pagination = {
            ...listFetchParams.pagination,
            offset: offset + limit,
        };
        setListFetchParams({
            ...listFetchParams,
            pagination,
        });
    };
    const handleSearchChange = (params) => {
        setListFetchParams({
            ...listFetchParams,
            pagination: {
                ...listFetchParams.pagination,
                offset: DEFAULT_OFFSET,
            },
            filters: {
                ...listFetchParams.filters,
                search: params.search,
                tag: get(params, 'tag', null),
            },
        });
    };
    const handleDeleteClient = (client) => {
        setClientDeleteDialog({
            clientToDelete: client,
            isDeleteDialogOpen: true,
        });
    };
    const handleDeleteClientFilter = (clientId, filterId) => {
        setClientFilterDeleteDialog({
            filterId,
            clientId,
            isFilterDeleteDialogOpen: true,
        });
    };
    const handleDeleteClientFromFavoriteFilter = (client) => {
        setClientToDeleteFromFavorite({
            isDeleteFromFavoriteDialogOpen: true,
            clientToDeleteFromFavorite: client,
        });
    };
    const handleDeleteClientFromFavoriteCancel = () => {
        setClientToDeleteFromFavorite({
            isDeleteFromFavoriteDialogOpen: false,
            clientToDeleteFromFavorite: null,
        });
    };
    const handleDeleteClientFromFavoriteConfirm = () => {
        if (onRemoveFromFavorite) {
            onRemoveFromFavorite(clientToDeleteFromFavorite.clientToDeleteFromFavorite);
            setClientToDeleteFromFavorite({
                isDeleteFromFavoriteDialogOpen: false,
                clientToDeleteFromFavorite: null,
            });
        }
    };
    const handleDeleteClientConfirm = () => {
        onDelete(clientDeleteDialog.clientToDelete, getFetchParams(listFetchParams));
        setClientDeleteDialog({
            clientToDelete: null,
            isDeleteDialogOpen: false,
        });
    };
    const handleDeleteClientFilterConfirm = () => {
        const newState = {
            filterId: null,
            clientId: null,
            isFilterDeleteDialogOpen: false,
        };
        onDeleteClientFilter(clientFilterDeleteDialog.clientId, clientFilterDeleteDialog.filterId, getFetchParams(listFetchParams));
        setClientFilterDeleteDialog(newState);
    };
    const handleDeleteClientCancel = () => {
        setClientDeleteDialog({
            clientToDelete: null,
            isDeleteDialogOpen: true,
        });
    };
    const handleDeleteClientFilterCancel = () => {
        setClientFilterDeleteDialog({
            filterId: null,
            clientId: null,
            isFilterDeleteDialogOpen: false,
        });
    };
    const handleShowClientsWithoutParamsChange = () => {
        const showClientsWithoutParams = !listFetchParams.filters.showClientsWithoutParams;
        const filters = {
            ...listFetchParams.filters,
            showClientsWithoutParams,
        };
        setListFetchParams({
            ...listFetchParams,
            pagination: {
                ...listFetchParams.pagination,
                offset: DEFAULT_OFFSET,
            },
            filters,
        });
    };
    const handleShowOnlyFavoritesChange = () => {
        const showOnlyFavorites = !listFetchParams.filters.showOnlyFavorites;
        const filters = {
            ...listFetchParams.filters,
            showOnlyFavorites,
        };
        setListFetchParams({
            ...listFetchParams,
            filters,
            pagination: {
                ...listFetchParams.pagination,
                offset: DEFAULT_OFFSET,
            },
        });
        setIsFiltersOpen(false);
    };
    const handleFilterReset = () => {
        setListFetchParams({
            ...listFetchParams,
            paramsFilter: {},
            pagination: {
                ...listFetchParams.pagination,
                offset: DEFAULT_OFFSET,
            },
        });
        setIsFiltersOpen(false);
    };
    const handleOrderChange = (order) => {
        setListFetchParams({
            ...listFetchParams,
            order,
            pagination: {
                ...listFetchParams.pagination,
                offset: DEFAULT_OFFSET,
            },
        });
    };
    const renderHeader = () => (_jsx(PageHeader, { classes: classes, totalCount: totalCount, title: pageTitle, isCreateButtonDisabled: isCreateButtonDisabled }));
    const renderEmptyClients = () => _jsx(NoClients, { classes: classes });
    const renderNoClientsFound = () => _jsx(NoClientsFound, { classes: classes });
    const renderProgress = () => _jsx(CircularProgressBox, {});
    const renderPagination = () => (_jsx(Pagination, { limitsList: DEFAULT_LIMITS, onLimitChange: handleLimitChange, limit: listFetchParams.pagination.limit, offset: listFetchParams.pagination.offset, total: totalListCount, onPaginationBack: handlePaginationBack, onPaginationForward: handlePaginationForward }));
    const renderClientsList = () => (_jsxs(_Fragment, { children: [_jsx(ClientsList, { onDeleteFilter: handleDeleteClientFilter, clients: clientList, onDelete: handleDeleteClient, paramsList: appParams.objectFilters, onSelectTag: handleSearchChange, isOwnerList: isOwnerList, onRemoveFromFavorite: handleDeleteClientFromFavoriteFilter, onAddToFavorite: onAddToFavorite }), renderPagination()] }));
    const renderWithParamsSwitcher = () => (_jsx(CustomSwitch, { checked: listFetchParams.filters.showClientsWithoutParams, label: i18n.t('CLIENTS_WITHOUT_PARAMS'), onChange: handleShowClientsWithoutParamsChange, name: "showClientsWithoutParams", asButton: true }));
    const renderOnlyFavoriteSwitcher = () => (_jsx(CustomSwitch, { checked: listFetchParams.filters.showOnlyFavorites, label: i18n.t('ONLY_FAVORITES'), onChange: handleShowOnlyFavoritesChange, name: "showOnlyFavorites", asButton: true }));
    const renderTopControls = () => {
        const isClientsListNotEmpty = Boolean(clientList.length);
        return (_jsxs(Grid, { className: classes.controlPanelBox, container: true, children: [_jsx(Grid, { className: classes.controlPanelBox, style: { padding: '10px 0px' }, item: true, container: true, md: 6, xs: 12, children: isOwnerList ? renderWithParamsSwitcher() : renderOnlyFavoriteSwitcher() }), _jsx(Grid, { item: true, md: 6, xs: 12, children: isClientsListNotEmpty && renderPagination() })] }));
    };
    const renderClientsContent = () => {
        if (listInProgress) {
            return renderProgress();
        }
        const content = clientList.length ? renderClientsList() : renderNoClientsFound();
        return (_jsxs(_Fragment, { children: [renderTopControls(), content] }));
    };
    const contentStyle = isFiltersOpen ? classes.contentBoxWithOpenedFilter : classes.contentBox;
    const isDataLoadInProgress = inProgress || totalCount === null;
    const { paramsFilter, order, filters: { showClientsWithoutParams }, } = listFetchParams;
    const renderContent = () => (_jsxs(_Fragment, { children: [_jsxs("div", { className: contentStyle, children: [renderHeader(), _jsxs(Paper, { className: classes.paper, elevation: 0, children: [_jsx(ClientsControlPanel, { onSearchChange: handleSearchChange, onFiltersToggle: handleFiltersToggle, isFiltersButtonActive: !isEmpty(paramsFilter), orderList: orderKeys, currentOrder: order, onOrderChange: handleOrderChange, fetchTagsList: fetchTagsList, tagsList: tagsList, isFiltersButtonDisabled: showClientsWithoutParams, selectedTag: listFetchParams.filters.tag }), totalCount ? renderClientsContent() : renderEmptyClients()] })] }), _jsx(DeleteClientDialog, { client: clientDeleteDialog.clientToDelete, isDeleteDialogOpen: clientDeleteDialog.isDeleteDialogOpen, onDeleteConfirm: handleDeleteClientConfirm, onDialogClose: handleDeleteClientCancel }), _jsx(DeleteClientFromFavoriteDialog, { client: clientToDeleteFromFavorite.clientToDeleteFromFavorite, isDeleteDialogOpen: clientToDeleteFromFavorite.clientToDeleteFromFavorite, onDeleteConfirm: handleDeleteClientFromFavoriteConfirm, onDialogClose: handleDeleteClientFromFavoriteCancel }), _jsx(DeleteClientFilterDialog, { client: clientDeleteDialog.clientToDelete, isDeleteDialogOpen: clientFilterDeleteDialog.isFilterDeleteDialogOpen, onDeleteConfirm: handleDeleteClientFilterConfirm, onDialogClose: handleDeleteClientFilterCancel }), _jsx(FiltersBar, { filter: filterBarState, setFilters: setFilterBarState, onFiltersToggle: handleFiltersToggle, isFiltersOpen: isFiltersOpen, filtersList: appParams.objectFilters, paramsList: appParams.objectFilters, onConfirm: handleFilterChange, onReset: handleFilterReset, citiesList: appParams.citiesList, exchangeRates: appParams.exchangeRates })] }));
    return isDataLoadInProgress ? renderProgress() : renderContent();
};
export default withStyles(styles)(ClientList);
