import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import style from './style';
import CityField from './CityField';
import DistrictField from './DistrictField';
import { CityService } from '../../../../services/CityService';
const DEFAULT_GRID_PARAMS = {
    sm: 12,
    md: 6,
    lg: 6,
    xl: 4,
    xs: 4,
};
const Location = ({ cityError, onDistrictChange, selectedDistrict, regions, cityId, districtError, classes, cityHeader, districtHeader, gridParams, onCityChange, }) => {
    const [districtsList, setDistrictsList] = useState([]);
    useEffect(() => {
        if (regions && cityId) {
            const city = CityService.getCityFormRegions(regions, cityId);
            setDistrictsList(city.districts);
        }
    }, [regions, cityId]);
    const getGridParams = () => gridParams || DEFAULT_GRID_PARAMS;
    const handleCityChange = (location) => {
        let districtsList = [];
        if (location) {
            const region = regions.find(({ name }) => name === location.region);
            const city = region?.cities.find(({ name }) => name === location.city);
            if (location.withDistricts) {
                districtsList = get(city, 'districts', []);
            }
        }
        else {
            onDistrictChange(null);
        }
        setDistrictsList(districtsList);
        return onCityChange(location);
    };
    const showDistrictsList = cityId && !!districtsList.length;
    return (_jsxs(_Fragment, { children: [_jsx(Grid, { className: classes.locationTextField, item: true, ...getGridParams(), children: regions && (_jsx(CityField, { regions: regions, cityId: cityId, onCityChange: handleCityChange, error: cityError, cityHeader: cityHeader })) }), showDistrictsList && (_jsx(Grid, { className: classes.locationTextField, item: true, ...getGridParams(), children: _jsx(DistrictField, { districtsList: districtsList, onDistrictChange: onDistrictChange, selectedDistrict: selectedDistrict, error: districtError, districtHeader: districtHeader }) }))] }));
};
export default withStyles(style)(Location);
