export class UpdateRealEstateRequestDataBuilder {
  static build({ realEstateObjectDomain }) {
    return {
      ...realEstateObjectDomain.toServerEntity(),
      photos: realEstateObjectDomain.photos.map((photo, index) => ({
        ...photo,
        order: index + 1,
      })),
    };
  }
}
