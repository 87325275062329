import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { get, set } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import i18n from '../../../../../common/i18n';
import style from './style';
const DISTRICT_NAME = 'district';
const DistrictField = ({ districtsList, selectedDistrict, districtHeader, error, classes, onDistrictChange, }) => {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const getDistrictOptions = (districtsList) => districtsList.map((district) => ({
        id: district.id,
        name: district.name,
    }));
    useEffect(() => {
        if (!selectedDistrict) {
            return setValue(null);
        }
        const options = getDistrictOptions(districtsList);
        const selectedOption = options.find((opt) => opt.id === selectedDistrict);
        if (districtsList && districtsList.length && selectedOption && selectedOption.name !== get(value, 'name')) {
            return setValue({
                id: selectedOption.id,
                name: selectedOption.name,
            });
        }
    }, [districtsList, selectedDistrict]);
    const getFieldLabel = () => districtHeader ? _jsx("h3", { children: districtHeader }) : _jsx("label", { id: "district", children: i18n.t('DISTRICT') });
    const handleInputValueChange = (inputValue) => setInputValue(inputValue);
    const options = getDistrictOptions(districtsList);
    const label = getFieldLabel();
    return (_jsxs(FormControl, { fullWidth: true, children: [label, _jsx(Autocomplete, { value: value, onChange: (event, newValue) => onDistrictChange(newValue), className: classes.districtAutocomplete, inputValue: inputValue, onInputChange: (event, newInputValue) => {
                    handleInputValueChange(newInputValue);
                }, options: options, fullWidth: true, getOptionLabel: (option) => option.name, getOptionSelected: (option, value) => option.id === value.id, renderInput: (params) => {
                    set(params, 'inputProps.autoComplete', 'chrome-off');
                    return _jsx(TextField, { variant: "outlined", ...params, helperText: error, name: DISTRICT_NAME, error: !!error });
                } }), !!error && _jsx(FormHelperText, { error: !!error, children: error })] }));
};
export default withStyles(style)(DistrictField);
