export default (theme) => ({
  tag: {
    color: '#385898',
    cursor: 'pointer',
    fontSize: '14px',
  },
  tagsBlock: {
    paddingBottom: theme.spacing(1),
  },
});
