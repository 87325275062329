import React, { Fragment, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { CircularProgressBox } from 'realtor/components/layears/CircularProgressBox/CircularProgressBox';
import { Paper } from '@material-ui/core';
import styles from './styles';
import i18n from '../../../../../../common/i18n';
import RealtorPageHeader from '../../../layears/RealtorPageHeader/RealtorPageHeader';
import CreateSingleFilterForm from '../../../forms/CreateSingleFilterForm/CreateSingleFilterForm';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';
import { FullScreenLoader } from '../../../../../components/FullScreenLoader/FullScreenLoader';
import { useGetAppConfigurationQuery } from '../../../../../allAccess/services/appConfigurationService';

export const UpdateClientFilterPage = ({
  clientId,
  filterId,
  fetchClientFilter,
  updateClientFilter,
  classes,
  clientFilter,
  inProgress,
  updateInProgress,
}) => {
  const { data: appConfigurations } = useGetAppConfigurationQuery();

  useEffect(() => {
    setPageTitle(i18n.t('UPDATE_FILTER'));
    fetchClientFilter(clientId, filterId, appConfigurations);
  }, [clientId, filterId]);

  const handleFormConfirm = (formData) => updateClientFilter(clientId, filterId, formData, appConfigurations);

  const renderForm = () => (
    <Paper className={classes.paper} elevation={0}>
      <div>
        <CreateSingleFilterForm
          onConfirm={handleFormConfirm}
          filter={clientFilter.toFormEntity()}
          confirmButtonText={i18n.t('SAVE')}
          disableTypeSelect={clientFilter && Boolean(clientFilter.objectType)}
          appConfigurations={appConfigurations}
        />
      </div>
    </Paper>
  );

  const renderHeader = () => (
    <RealtorPageHeader>
      <div className={classes.headerBox}>
        <h1>{i18n.t('UPDATE_FILTER')}</h1>
      </div>
    </RealtorPageHeader>
  );

  const renderLoader = () => <CircularProgressBox />;

  const renderContent = () => {
    const showForm = !inProgress && clientFilter;

    return showForm ? renderForm() : renderLoader();
  };

  return (
    <>
      {renderHeader()}
      <Paper className={classes.paper} elevation={0}>
        {updateInProgress && <FullScreenLoader />}
        {renderContent()}
      </Paper>
    </>
  );
};

UpdateClientFilterPage.propTypes = {
  fetchClientFilter: PropTypes.func.isRequired,
  updateClientFilter: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  filterId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  clientFilter: PropTypes.object,
  inProgress: PropTypes.bool,
  updateInProgress: PropTypes.bool,
};

export default withStyles(styles)(UpdateClientFilterPage);
