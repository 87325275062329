import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputAdornment, TextField } from '@material-ui/core';
import { NumberFormatCustom } from 'realtor/components/common/PriceNumberMask/PriceNumberMask';
import i18n from '../../../../../common/i18n';

export const LandAreaFilter = ({ filterErrors, filter, onInputChange, isTitleLabel }) => {
  const value = filter.landArea;

  const style = value ? { paddingLeft: '4px' } : {};

  const renderTitle = (title) => (isTitleLabel ? <label>{title}</label> : <h3>{title}</h3>);

  return (
    <FormControl margin="none" helperText={filterErrors.landArea} error={filterErrors.landArea}>
      {renderTitle(`${i18n.t('LAND_AREA')}:`)}
      <TextField
        placeholder={i18n.t('LAND_AREA_PLACEHOLDER')}
        variant="outlined"
        margin="none"
        required
        fullWidth
        id="landArea"
        name="landArea"
        onChange={onInputChange}
        value={value}
        inputProps={{
          min: 0,
          style,
        }}
        error={!!filterErrors.livingArea}
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: value && <InputAdornment position="start" />,
        }}
      />
      <FormHelperText>{filterErrors.livingArea}</FormHelperText>
    </FormControl>
  );
};

LandAreaFilter.propTypes = {
  filterErrors: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  isTitleLabel: PropTypes.bool,
};
