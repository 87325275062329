import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable */
/* tslint:disable */
import { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
// @ts-ignore
import { get } from 'lodash';
import 'react-s-alert/dist/s-alert-default.css';
// @ts-ignore
import styles from './styles';
// @ts-ignore
import AuthPageLayer from '../../layears/AuthPageLayer/AuthPageLayer';
// @ts-ignore
import FormHeaderLayer from '../../../../../common/components/layers/FormHeaderLayer/FormHeaderLayer';
// @ts-ignore
import AuthFooterLayer from '../../layears/AuthFooterLayer/AuthFooterLayer';
// @ts-ignore
import RegistrationConfirmForm from '../../forms/RegistrationConfirmForm/RegistrationConfirmForm';
// @ts-ignore
import { mapConfirmRegistrationErrors } from '../../../helpers/errorsMapper';
// @ts-ignore
import i18n from '../../../../../common/i18n';
import { useGetAppConfigurationQuery } from '../../../services/appConfigurationService';
export const ConfirmRegistrationPage = ({ onConfirmRegistration, error, classes, inProgress, onDropConfirmRegistrationErrors, }) => {
    const { data: appConfigurations } = useGetAppConfigurationQuery();
    const [errorsLocal, setErrorsLocal] = useState({});
    useEffect(() => {
        onConfirmRegistration({
            confirmationCode: '9379-992',
        });
    }, []);
    useEffect(() => {
        const errorCode = get(error, 'code');
        const validationErrors = errorCode ? mapConfirmRegistrationErrors(appConfigurations.errorCodes, error) : {};
        setErrorsLocal(validationErrors);
    }, [error]);
    const renderHeader = () => (_jsxs(FormHeaderLayer, { title: i18n.t('REGISTRATION'), children: [`${i18n.t('ALREADY_HAVE_ACCOUNT')} `, _jsx(Link, { to: "/auth", component: RouterLink, children: i18n.t('SIGN_IN') })] }));
    return (_jsxs(AuthPageLayer, { children: [renderHeader(), _jsx("hr", { size: 1, className: classes.hr }), _jsx(RegistrationConfirmForm, { onConfirmRegistration: onConfirmRegistration, inProgress: inProgress, errors: errorsLocal, onDropErrors: onDropConfirmRegistrationErrors }), _jsx(AuthFooterLayer, { confirmText: i18n.t('REGISTRATION_CONFIRM_TEXT') })] }));
};
export default withStyles(styles)(ConfirmRegistrationPage);
