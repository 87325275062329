import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const TagsList = ({ tags, classes, onTagClick }) => {
  const tagsList = tags
    ? tags.map((tag) => (
        <span key={tag.id} onClick={() => onTagClick({ tag })} className={classes.tag}>{`#${tag.title} `}</span>
      ))
    : null;

  return tagsList ? <div className={classes.tagsBlock}>{tagsList}</div> : null;
};

TagsList.propTypes = {
  tags: PropTypes.array,
  classes: PropTypes.object,
  onTagClick: PropTypes.func,
};

export default withStyles(styles)(TagsList);
