export default (theme) => ({
  tagsBlock: {
    position: 'relative',
  },
  tagsBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  tagsInfiniteScroll: {
    maxWidth: '92%',
    height: '230px',
    width: '420px',
    overflow: 'auto',
    position: 'absolute',
    background: '#fff',
    zIndex: 2,
    /* eslint-disable-next-line */
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 16px 32px, rgba(0, 0, 0, 0.1) 0px 8px 16px, rgba(0, 0, 0, 0.1) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 0px 1px',
    boxSizing: 'border-box',
    borderRadius: theme.spacing(0.5),
  },
  tagsInfiniteScrollItem: {
    padding: `0 ${theme.spacing(2)}px`,
    marginRight: 0,
    '&:hover': {
      cursor: 'pointer',
      background: 'rgba(0, 108, 132, 0.1)',
    },
  },
  tagsInfiniteScrollBox: {
    display: 'flex',
    flexDirection: 'column',
    '&first-child': {
      width: '100%',
    },
  },
  searchTagsInputButton: {
    position: 'absolute',
    right: '0px',
    top: theme.spacing(0.5),
  },
  tagsNotFoundBox: {
    color: '#767676',
  },
  tagsNotFoundItem: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  tagsNotFoundAddTag: {
    '&:hover': {
      background: 'rgba(0, 108, 132, 0.1)',
      cursor: 'pointer',
    },
    color: 'rgb(0, 108, 132)',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.1em',
  },
});
