import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import RealtorPageHeader from 'realtor/components/layears/RealtorPageHeader/RealtorPageHeader';
import i18n from '../../../../../../../../common/i18n';

const PageHeader = ({ classes, totalCount, title, isCreateButtonDisabled }) => (
  <RealtorPageHeader>
    <div className={classes.headerBox}>
      <div>
        <h1>{title}</h1>
        <span>{`${totalCount} ${i18n.t('CLIENTS_COUNT')}`}</span>
      </div>
      {!isCreateButtonDisabled && (
        <Button component={RouterLink} to="/clients/create" className={classes.addClientButton} variant="contained">
          {i18n.t('ADD_CLIENT')}
        </Button>
      )}
    </div>
  </RealtorPageHeader>
);

PageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  totalCount: PropTypes.number,
  title: PropTypes.string,
  isCreateButtonDisabled: PropTypes.bool,
};

export default PageHeader;
