import cn from 'classnames';
import React, { Fragment } from 'react';
import { Button, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import ShareObjectButton from 'realtor/components/layears/RealEstateObjectContent/components/ShareObjectButton/ShareObjectButton';
import { withStyles } from '@material-ui/core/styles';
import i18n from '../../../../../../../common/i18n';

const styles = (theme) => ({
  shareButton: {
    background: '#F5841F',
    borderRadius: theme.spacing(3),
    color: '#fff',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14)',
    fontWeight: 700,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    opacity: 0.8,
    transition: '0.5s',
    '&:hover': {
      cursor: 'pointer',
      background: '#F5841F',
      opacity: 1,
      transition: '0.5s',
    },
  },
  editButton: {
    background: '#337ab7',
    marginRight: '20px',
    '&:hover': {
      background: '#337ab7',
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
    '@media (max-width: 900px)': {
      marginBottom: '60px',
    },
  },
  closeButton: {
    background: '#ffffff',
    border: '1px solid #3694D1',
    color: '#3694D1',
    marginRight: '20px',
    '&:hover': {
      background: '#ffffff',
    },
  },
});

export const ControlButtons = ({ classes, onEditRealEstate, onGoBack, onShareObjectsDialogOpen, isMyObject }) => {
  const closeButtonClasses = cn(classes.shareButton, classes.closeButton);
  const editButtonClasses = cn(classes.shareButton, classes.editButton);

  return (
    <>
      <Divider />
      <div className={classes.actionButtons}>
        <Button className={closeButtonClasses} variant="contained" onClick={onGoBack}>
          {i18n.t('CLOSE')}
        </Button>
        {isMyObject && (
          <Button className={editButtonClasses} variant="contained" onClick={onEditRealEstate}>
            {i18n.t('EDIT')}
          </Button>
        )}
        <ShareObjectButton onShareObjectsDialogOpen={onShareObjectsDialogOpen} />
      </div>
    </>
  );
};

ControlButtons.propTypes = {
  onShareObjectsDialogOpen: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onEditRealEstate: PropTypes.func.isRequired,
  isMyObject: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControlButtons);
