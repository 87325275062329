import { connect } from 'react-redux';
import CreateRealEstateObjectPage from 'realtor/components/pages/RealEstateObjectPages/CreateRealEstateObjectPage/CreateRealEstateObjectPage';
import { createNewTag, createRealEstateObject, dropCreatedTag, fetchTagsList } from 'realtor/actions';

const mapStateToProps = ({ tag, realEstateObject }) => ({
  inProgress: false,
  tag,
  objectMutationInProgress: realEstateObject.objectMutationInProgress,
});

const mapDispatchToProps = {
  createRealEstateObject,
  fetchTagsList,
  createNewTag,
  dropCreatedTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRealEstateObjectPage);
