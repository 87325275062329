export class TelephoneNumber {
  props = {
    id: null,
    telephoneNumber: null,
  };

  set id(id) {
    this.props.id = id;
  }

  get id() {
    return this.props.id;
  }

  set telephoneNumberFromFormData(telephoneNumber) {
    this.props.telephoneNumber = this._getTelephoneNumberFromFormDataString(telephoneNumber);
  }

  set telephoneNumberFromContract(telephoneNumber) {
    this.props.telephoneNumber = telephoneNumber;
  }

  _getTelephoneNumberFromFormDataString = (telephoneNumber) =>
    telephoneNumber.replace('(', '').replace(')', '').replace('+', '').split(' ').join('');

  getTelephoneNumberFromContract = () => this.props.telephoneNumber;

  getTelephoneNumberView = () => {
    const t = this.props.telephoneNumber.split('');
    return `+${t[0]}${t[1]} (${t[2]}${t[3]}${t[4]}) ${t[5]}${t[6]}${t[7]}-${t[8]}${t[9]}-${t[10]}${t[11]}`;
  };

  getViberUrl = () => `viber://chat?number=%2B${this.props.telephoneNumber}`;

  getTelegramUrl = () => `https://t.me/+${this.props.telephoneNumber}`;

  getTelephoneNumberStringForForm = () => {
    const t = this.props.telephoneNumber.split('');

    return `+${t[0]}${t[1]}(${t[2]}${t[3]}${t[4]}) ${t[5]}${t[6]}${t[7]} ${t[8]}${t[9]} ${t[10]}${t[11]}`;
  };

  getTelephoneNumberObjectForForm = () => ({
    id: this.props.id,
    telephoneNumber: this.getTelephoneNumberStringForForm(),
  });

  getCallLink = () => `tel:+${this.props.telephoneNumber}`;

  getFullProps = () => this.props;
}
