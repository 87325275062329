import { BaseValidator } from './BaseValidator';

export class CommercialFilterValidator extends BaseValidator {
  // eslint-disable-next-line max-statements
  validate() {
    const errors = {};

    errors.area = this.validateArea();

    errors.floor = this.validateFloor();

    errors.squarePrice = this.validateSquarePrice();

    errors.totalPrice = this.validateTotalPrice();

    return errors;
  }
}
