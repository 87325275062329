import { connect } from 'react-redux';

import {
  fetchClients,
  fetchTotalAndList,
  deleteAndFetchList,
  deleteClientFilterAndFetchList,
  fetchTagsList,
  fetchMyTotal,
  dropTotalCount,
} from '../../actions';
import ClientListPage from '../../components/pages/ClientsPages/ClientsListPage/ClientListPage';

const mapStateToProps = (state) => {
  const { clientList, tag, client, clientFilter } = state;

  return {
    clientList: clientList.list,
    totalListCount: clientList.totalListCount,
    totalCount: clientList.totalCount,
    inProgress: clientList.inProgress,
    listInProgress: clientList.listInProgress,
    tagsList: tag.tagsList,
    isDeleteActionInProgress: client.inProgress || clientFilter.inProgress,
  };
};

const mapDispatchToProps = {
  fetchClients,
  fetchTotalAndList,
  onDelete: deleteAndFetchList,
  onDeleteClientFilter: deleteClientFilterAndFetchList,
  fetchTagsList,
  fetchMyTotal,
  dropTotalCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientListPage);
