import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import i18n from '../../../../i18n';

const PasswordField = ({ onFieldChange, onIsPasswordVisibleChange, value, error }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleClickShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
    onIsPasswordVisibleChange(!isPasswordVisible);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl fullWidth margin="normal">
      <label htmlFor="outlined-adornment-password">{i18n.t('PASSWORD')}</label>
      <OutlinedInput
        required
        name="password"
        type={isPasswordVisible ? 'text' : 'password'}
        id="password"
        onChange={onFieldChange}
        value={value}
        error={!!error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

PasswordField.propTypes = {
  onFieldChange: PropTypes.func.isRequired,
  onIsPasswordVisibleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
};

export default PasswordField;
