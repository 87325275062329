import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { getSubtypeList } from 'realtor/helpers/clientFilterParams';
import i18n from '../../../../../../common/i18n';
import styles from '../styles';
import SelectButtons from '../../../common/SelectButtons/SelectButtons';
import { BaseParams } from './BaseParams';

export class HouseParams extends BaseParams {
  constructor(props) {
    super(props);
    this.props = props;
  }

  renderSubtype() {
    const { filtersList, realEstateObject, onSelect, errors } = this.props;
    const realEstateTypeCollection = getSubtypeList(realEstateObject, filtersList);

    return (
      <SelectButtons
        list={realEstateTypeCollection}
        onSelect={onSelect}
        selectedValue={realEstateObject.objectSubtype}
        name="objectSubtype"
        header={i18n.t('FILTER_REAL_ESTATE_SUBTYPE')}
        isMulti={false}
        error={errors.objectSubtype}
      />
    );
  }

  renderFullParamsList() {
    const { classes } = this.props;

    return (
      <>
        {this.renderParamsOnlyForBuyService()}
        <Grid sm={12} container>
          {this.renderSubtype()}
        </Grid>
        <Grid item sm={6} xs={12} md={12} lg={12}>
          {this.renderCitiesFilter()}
        </Grid>
        <Grid sm={12} container>
          {this.renderRoomsCount()}
        </Grid>
        <Grid sm={12} container>
          {this.renderMaterial()}
        </Grid>
        <Grid sm={12} container className={classes.rangeParamsBox}>
          {this.renderTotalArea()}
          {this.renderKitchenArea()}
        </Grid>
        <Grid sm={12} container className={classes.rangeParamsBox}>
          {this.renderLivingArea()}
          {this.renderLandArea()}
        </Grid>
        <Grid sm={12} container>
          {this.renderCondition()}
        </Grid>
        <Grid sm={12} container className={classes.rangeParamsBox}>
          {this.renderFlooring()}
        </Grid>
        <Grid sm={12} container>
          {this.renderCurrency()}
        </Grid>
        <Grid sm={12} container className={classes.rangeParamsBox}>
          {this.renderTotalPrice()}
          {this.renderSquarePrice()}
        </Grid>
        <Grid sm={12} container>
          {this.renderTitle()}
        </Grid>
        <Grid sm={12} container>
          {this.virtualTourLink()}
        </Grid>
        {this.renderDescription()}
        {this.renderNotes()}
      </>
    );
  }

  render() {
    const { realEstateObject } = this.props;
    return (
      <Grid container>
        <Grid sm={12}>{this.renderServiceType()}</Grid>
        {realEstateObject.objectServiceType && this.renderFullParamsList()}
      </Grid>
    );
  }
}

export default withStyles(styles)(HouseParams);

HouseParams.propTypes = {
  realEstateObject: PropTypes.object,
  filtersList: PropTypes.object,
  citiesList: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onMultiSelectAll: PropTypes.func.isRequired,
};
