import { get } from 'lodash';
import ObjectResource from '../resourses/Object.resource';
import ShareResource from '../resourses/Share.resource';

export const FETCH_MY_OBJECTS_START = 'FETCH_MY_OBJECTS_START';
export const FETCH_MY_OBJECTS_SUCCESS = 'FETCH_MY_OBJECTS_SUCCESS';
export const FETCH_MY_OBJECTS_FAIL = 'FETCH_MY_OBJECTS_FAIL';
export const DROP_OBJECTS_LIST = 'DROP_OBJECTS_LIST';

export const FETCH_SHARE_TOKEN_START = 'FETCH_SHARE_TOKEN_START';
export const FETCH_SHARE_TOKEN_SUCCESS = 'FETCH_SHARE_TOKEN_SUCCESS';
export const FETCH_SHARE_TOKEN_FAIL = 'FETCH_SHARE_TOKEN_FAIL';

const fetchMyObjectsStart = () => ({ type: FETCH_MY_OBJECTS_START });

const fetchMyObjectsFail = (error) => ({
  type: FETCH_MY_OBJECTS_FAIL,
  error,
});

const fetchMyObjectsSuccess = (data) => ({
  type: FETCH_MY_OBJECTS_SUCCESS,
  data,
});

const dropObjectsList = (data) => ({
  type: DROP_OBJECTS_LIST,
  data,
});

const fetchShareTokenStart = () => ({
  type: FETCH_SHARE_TOKEN_START,
});

const fetchShareTokenSuccess = (data) => ({
  type: FETCH_SHARE_TOKEN_SUCCESS,
  data,
});

const fetchShareTokenFail = (error) => ({
  type: FETCH_SHARE_TOKEN_FAIL,
  error,
});

export const clearObjectsList = () => (dispatch) => dispatch(dropObjectsList());

export const fetchMyObjects = (params) => (dispatch) => {
  dispatch(fetchMyObjectsStart());
  ObjectResource.fetchMy({
    params,
  })
    .then((response) => {
      dispatch(fetchMyObjectsSuccess(response));
    })
    .catch((err) => {
      dispatch(fetchMyObjectsFail(get(err, 'response.data.error', null)));
    });
};

export const fetchAnyObjects = (params) => (dispatch) => {
  dispatch(fetchMyObjectsStart());
  ObjectResource.fetchAny({
    params,
  })
    .then((response) => {
      dispatch(fetchMyObjectsSuccess(response));
    })
    .catch((err) => {
      dispatch(fetchMyObjectsFail(get(err, 'response.data.error', null)));
    });
};

export const fetchSharedObjectsByToken = (params, token) => (dispatch) => {
  dispatch(fetchMyObjectsStart());
  ObjectResource.fetchObjectsByHash(params, token)
    .then((response) => {
      dispatch(fetchMyObjectsSuccess(response));
    })
    .catch((err) => {
      dispatch(fetchMyObjectsFail(get(err, 'response.data.error', null)));
    });
};

export const fetchShareToken = () => (dispatch) => {
  dispatch(fetchShareTokenStart());
  ShareResource.fetchObjectListShareToken()
    .then((response) => {
      dispatch(fetchShareTokenSuccess(response));
    })
    .catch((err) => {
      dispatch(fetchShareTokenFail(get(err, 'response.data.error', null)));
    });
};
