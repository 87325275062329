import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable */
/* tslint:disable */
import { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import { get } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import styles from './styles';
import AuthPageLayer from '../../layears/AuthPageLayer/AuthPageLayer';
import LoginForm from '../../forms/LoginForm/LoginForm';
import i18n from '../../../../i18n';
import FormHeaderLayer from '../../../../../common/components/layers/FormHeaderLayer/FormHeaderLayer';
import AuthFooterLayer from '../../layears/AuthFooterLayer/AuthFooterLayer';
import { mapLoginErrors } from '../../../helpers/errorsMapper';
import { useGetAppConfigurationQuery } from '../../../services/appConfigurationService';
const LoginPage = ({ inProgress, error, classes, login, dropAuthorizationErrors }) => {
    const { data: appConfigurations } = useGetAppConfigurationQuery();
    const [localErrors, setLocalErrors] = useState({});
    useEffect(() => {
        const errorCode = get(error, 'code');
        const validationErrors = errorCode ? mapLoginErrors(appConfigurations.errorCodes, error) : {};
        setLocalErrors(validationErrors);
    }, [error]);
    const renderHeader = () => (_jsxs(FormHeaderLayer, { title: i18n.t('ENTER'), children: [`${i18n.t('DONT_NAVE_ACCOUNT')} `, _jsx(Link, { to: "/registration", component: RouterLink, children: i18n.t('REGISTRATION') })] }));
    return (_jsxs(AuthPageLayer, { children: [renderHeader(), _jsx("hr", { size: 1, className: classes.hr }), _jsx(LoginForm, { login: login, inProgress: inProgress, errors: localErrors, onDropErrors: dropAuthorizationErrors }), _jsx(AuthFooterLayer, { confirmText: i18n.t('AUTHORIZATION_CONFIRM_TEXT') })] }));
};
export default withStyles(styles)(LoginPage);
