import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ClientListParamsControlButtons = ({ clientId, filterId, onDeleteFilter }) => {
  const editLink = `/clients/${clientId}/filter/${filterId}/update`;

  const handleDeleteClientFilter = (e) => {
    e.stopPropagation();
    return onDeleteFilter(clientId, filterId);
  };

  return (
    <>
      <IconButton component={Link} to={editLink}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleDeleteClientFilter}>
        <DeleteOutlineIcon />
      </IconButton>
    </>
  );
};

ClientListParamsControlButtons.propTypes = {
  clientId: PropTypes.number.isRequired,
  filterId: PropTypes.number.isRequired,
  onDeleteFilter: PropTypes.func.isRequired,
};

export default ClientListParamsControlButtons;
