import { GarageParking, LandPlot, NullRealEstateObject } from 'realtor/domain/entities/RealEstateObject';
import { Commercial } from 'realtor/domain/entities/RealEstateObject/Commercial';
import { Apartment } from 'realtor/domain/entities/RealEstateObject/Apartment';
import { House } from 'realtor/domain/entities/RealEstateObject/House';
import { orderBy } from 'lodash';

export const realEstateObjectToDomainFactory = (realEstateObject, realEstateTypes) => {
  switch (realEstateObject.objectType) {
    case realEstateTypes.GARAGE_PARKING:
      return new GarageParking(realEstateObject);
    case realEstateTypes.LAND_PLOT:
      return new LandPlot(realEstateObject);
    case realEstateTypes.COMMERCIAL:
      return new Commercial(realEstateObject);
    case realEstateTypes.APARTMENT:
      return new Apartment(realEstateObject);
    case realEstateTypes.HOUSE_OR_COUNTRY_HOUSE:
      return new House(realEstateObject);
    default:
      return new NullRealEstateObject(realEstateObject);
  }
};

export const buildRealEstateObjectFromServer = (realEstateObjectServerData, realEstateTypes) => {
  const realEstateObject = {
    ...realEstateObjectServerData,
    objectType: realEstateObjectServerData.type,
    objectSubtype: realEstateObjectServerData.subtype,
    objectMaterial: realEstateObjectServerData.material,
    objectCondition: realEstateObjectServerData.condition,
    photos: orderBy(realEstateObjectServerData.photos, 'order', 'asc'),
  };

  const realEstateObjectsWithPartialServiceType = [
    realEstateTypes.COMMERCIAL,
    realEstateTypes.LAND_PLOT,
    realEstateTypes.GARAGE_PARKING,
  ];

  if (realEstateObjectsWithPartialServiceType.includes(realEstateObject.objectType)) {
    realEstateObject.objectPartialServiceType = realEstateObject.objectServiceType;
    delete realEstateObject.objectServiceType;
  }

  return realEstateObjectToDomainFactory(realEstateObject, realEstateTypes);
};
