import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  LandAreaPriceRangeFilter,
  FlooringRangeFilter,
  AreaRangeFilter,
  SquarePriceRangeFilter,
  KitchenAreaRangeFilter,
  TotalPriceRangeFilter,
  FloorRangeFilter,
  LivingAreaRangeFilter,
  LandAreaRangeFilter,
} from 'realtor/components/layears/RangeFilters';
import {
  RoomsCountFilter,
  CurrencyFilter,
  CitiesFilter,
  PartialServiceTypeFilter,
  ServiceTypeFilter,
  MaterialFilter,
  ConditionFilter,
  MortgageFilter,
  LastFloorFilter,
  SubtypeFilter,
  IgnoreLastFloorFilter,
} from '../Filters';

// eslint-disable-next-line max-statements
export const ObjectFiltersByType = ({
  filtersList,
  filter,
  onSelectButtonChange,
  onSelectButtonMultipleChange,
  onMultiSelectAll,
  onFilterChange,
  filterErrors,
  citiesList,
  onCityChange,
  onDistrictChange,
}) => {
  const renderServiceTypeFilter = () => (
    <ServiceTypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />
  );

  const renderSubtypeFilter = () => (
    <SubtypeFilter
      filtersList={filtersList}
      filter={filter}
      onFilterChange={onSelectButtonMultipleChange}
      onMultiSelectAll={onMultiSelectAll}
      isMulti
    />
  );

  const renderMortgageFilter = () => <MortgageFilter filter={filter} onFilterChange={onFilterChange} />;

  const renderLastFloorFilter = () => <LastFloorFilter filter={filter} onFilterChange={onFilterChange} />;

  const renderPartialServiceTypeFilter = () => (
    <PartialServiceTypeFilter filtersList={filtersList} filter={filter} onFilterChange={onSelectButtonChange} />
  );

  const renderRoomsCount = () => (
    <RoomsCountFilter
      filtersList={filtersList}
      filter={filter}
      onFilterChange={onSelectButtonMultipleChange}
      onMultiSelectAll={onMultiSelectAll}
      isMulti
    />
  );

  const renderMaterial = () => (
    <MaterialFilter
      filtersList={filtersList}
      filter={filter}
      onFilterChange={onSelectButtonMultipleChange}
      onMultiSelectAll={onMultiSelectAll}
      isMulti
    />
  );

  const renderCondition = () => (
    <ConditionFilter
      filtersList={filtersList}
      filter={filter}
      onFilterChange={onSelectButtonMultipleChange}
      onMultiSelectAll={onMultiSelectAll}
      isMulti
    />
  );

  const renderIgnoreLastFloorFilter = () => <IgnoreLastFloorFilter filter={filter} onFilterChange={onFilterChange} />;

  const renderCurrency = () => (
    <CurrencyFilter filtersList={filtersList} filter={filter} onFilterChange={onFilterChange} />
  );

  const renderTotalPriceRange = () => (
    <TotalPriceRangeFilter onSelect={onMultiSelectAll} filter={filter} errors={filterErrors} />
  );

  const renderSquarePriceRange = () => (
    <SquarePriceRangeFilter onSelect={onMultiSelectAll} filter={filter} errors={filterErrors} />
  );

  const renderLandAreaRangeFilter = () => (
    <LandAreaRangeFilter onSelect={onMultiSelectAll} filter={filter} errors={filterErrors} />
  );

  const landAreaPriceRange = () => (
    <LandAreaPriceRangeFilter onSelect={onMultiSelectAll} filter={filter} errors={filterErrors} />
  );

  const renderArea = () => <AreaRangeFilter filter={filter} onSelect={onMultiSelectAll} errors={filterErrors} />;

  const renderFlooringRange = () => (
    <FlooringRangeFilter filter={filter} onSelect={onMultiSelectAll} errors={filterErrors} />
  );

  const renderFloorRange = () => <FloorRangeFilter onSelect={onMultiSelectAll} filter={filter} errors={filterErrors} />;

  const renderKitchenAreaRange = () => (
    <KitchenAreaRangeFilter onSelect={onMultiSelectAll} filter={filter} errors={filterErrors} />
  );

  const renderLivingAreaRange = () => (
    <LivingAreaRangeFilter onSelect={onMultiSelectAll} filter={filter} errors={filterErrors} />
  );

  const renderCitiesFilter = () => (
    <CitiesFilter
      citiesList={citiesList}
      filter={filter}
      onCityChange={onCityChange}
      onDistrictChange={onDistrictChange}
    />
  );

  switch (filter.objectType) {
    case filtersList.objectType.APARTMENT:
      return (
        <>
          {renderServiceTypeFilter()}
          {renderSubtypeFilter()}
          {renderRoomsCount()}
          {renderCurrency()}
          {renderTotalPriceRange()}
          {renderSquarePriceRange()}
          {renderArea()}
          {renderMortgageFilter()}
          {renderFloorRange()}
          {renderIgnoreLastFloorFilter()}
          {renderLastFloorFilter()}
          {renderKitchenAreaRange()}
          {renderLivingAreaRange()}
          {renderCondition()}
          {renderMaterial()}
          {renderCitiesFilter()}
        </>
      );
    case filtersList.objectType.HOUSE_OR_COUNTRY_HOUSE:
      return (
        <>
          {renderServiceTypeFilter()}
          {renderSubtypeFilter()}
          {renderRoomsCount()}
          {renderCurrency()}
          {renderTotalPriceRange()}
          {renderSquarePriceRange()}
          {renderArea()}
          {renderLandAreaRangeFilter()}
          {renderMortgageFilter()}
          {renderFlooringRange()}
          {renderCondition()}
          {renderMaterial()}
          {renderCitiesFilter()}
        </>
      );
    case filtersList.objectType.COMMERCIAL:
      return (
        <>
          {renderPartialServiceTypeFilter()}
          {renderSubtypeFilter()}
          {renderCurrency()}
          {renderTotalPriceRange()}
          {renderSquarePriceRange()}
          {renderArea()}
          {renderFloorRange()}
          {renderIgnoreLastFloorFilter()}
          {renderLastFloorFilter()}
          {renderCondition()}
          {renderMaterial()}
          {renderCitiesFilter()}
        </>
      );
    case filtersList.objectType.LAND_PLOT:
      return (
        <>
          {renderPartialServiceTypeFilter()}
          {renderCurrency()}
          {renderTotalPriceRange()}
          {renderArea()}
          {landAreaPriceRange()}
          {renderCitiesFilter()}
        </>
      );
    case filtersList.objectType.GARAGE_PARKING:
      return (
        <>
          {renderPartialServiceTypeFilter()}
          {renderSubtypeFilter()}
          {renderCurrency()}
          {renderTotalPriceRange()}
          {renderArea()}
          {renderCitiesFilter()}
        </>
      );
    default:
      return <></>;
  }
};

export default ObjectFiltersByType;

ObjectFiltersByType.propTypes = {
  filtersList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  filterErrors: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onDistrictChange: PropTypes.func.isRequired,
  onSelectButtonChange: PropTypes.func.isRequired,
  onSelectButtonMultipleChange: PropTypes.func.isRequired,
  onMultiSelectAll: PropTypes.func.isRequired,
  citiesList: PropTypes.array.isRequired,
};
