import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from 'realtor/components/pages/ClientsPages/CreateClientPage/styles';
import RealtorPageHeader from 'realtor/components/layears/RealtorPageHeader/RealtorPageHeader';
import ProfileData from 'realtor/components/pages/ProfilePages/ProfilePage/components/ProfileData/ProfileData';
import AvatarBlock from 'realtor/components/pages/ProfilePages/ProfilePage/components/AvatartBlock/AvatarBlock';
import UpdateProfileForm from 'realtor/components/forms/UpdateUserProfileForm/UpdateProfileForm';
import i18n from '../../../../../../common/i18n';
import { setPageTitle } from '../../../../../../common/helpers/pageTitle';
import { FullScreenLoader } from '../../../../../components/FullScreenLoader/FullScreenLoader';

export const ProfilePage = ({ updateClientProfile, userProfile, logout, updateInProgress }) => {
  const myProfilePageTitle = i18n.t('MY_PROFILE_PAGE_TITLE');
  const editProfilePageTitle = i18n.t('EDIT_MY_PROFILE_PAGE_TITLE');
  const [isEditMode, setIsEditMode] = useState(false);
  const [pageTitle, setPageHeader] = useState(myProfilePageTitle);

  useEffect(() => {
    setPageTitle(pageTitle);
  }, [pageTitle]);

  const handleToggleEdit = () => {
    const isEditModeToSave = !isEditMode;

    setIsEditMode(isEditModeToSave);
    setPageHeader(isEditModeToSave ? editProfilePageTitle : myProfilePageTitle);
  };

  const renderHeader = () => (
    <RealtorPageHeader>
      <div>
        <h1>{pageTitle}</h1>
      </div>
    </RealtorPageHeader>
  );

  const handleSubmit = (profileData) => {
    updateClientProfile(profileData);
  };

  return (
    <div>
      {renderHeader()}
      <AvatarBlock userProfile={userProfile} />
      {updateInProgress && <FullScreenLoader />}
      {isEditMode && userProfile ? (
        <UpdateProfileForm
          profile={userProfile.props}
          organizationsList={[]}
          onCloseEdit={handleToggleEdit}
          onSubmit={handleSubmit}
        />
      ) : (
        <ProfileData userProfile={userProfile} logout={logout} onToggleEdit={handleToggleEdit} />
      )}
    </div>
  );
};

ProfilePage.propTypes = {
  userProfile: PropTypes.object,
  updateInProgress: PropTypes.bool,
  updateClientProfile: PropTypes.func,
  logout: PropTypes.func,
};

export default withStyles(styles)(ProfilePage);
