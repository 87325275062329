import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@material-ui/core/Button';
// @ts-ignore
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';
import styles from './styles';
import i18n from '../../../../../common/i18n';
const SelectButtons = ({ name, onSelect, selectedValue, list, onSelectAll, classes, isMulti, disabled, title, header, error, }) => {
    const handleSelectAll = () => {
        const selectedValues = new Set();
        list.forEach(({ value }) => {
            selectedValues.add(value);
        });
        return () => onSelectAll(name, selectedValues);
    };
    const handleSelect = (value) => {
        const isAllSelected = selectedValue && list.length === selectedValue.size;
        if (selectedValue && selectedValue.size === 1 && selectedValue.has(value)) {
            return handleSelectAll();
        }
        return () => onSelect(name, value, isAllSelected);
    };
    const renderButton = ({ title, value }) => {
        const isActive = isMulti ? selectedValue && selectedValue.has(value) : value === selectedValue;
        const isAllSelected = Boolean(selectedValue && list.length === selectedValue.size);
        const buttonClasses = cn({
            [classes.selectButton]: true,
            [classes.activeSelectButton]: isActive && !isAllSelected,
        });
        return (_jsx(Button, { className: buttonClasses, variant: "contained", onClick: handleSelect(value), disabled: disabled, children: title }));
    };
    const renderAllSelectedButton = () => {
        const isActive = selectedValue && list.length === selectedValue.size;
        const buttonClasses = cn({
            [classes.selectButton]: true,
            [classes.activeSelectButton]: isActive,
        });
        return (_jsx(Button, { className: buttonClasses, variant: "contained", onClick: handleSelectAll(), disabled: false, children: i18n.t('ALL_PARAMETERS') }));
    };
    let actualError = false;
    if (error) {
        actualError = error;
    }
    if (disabled) {
        actualError = i18n.t('CANT_BE_CHANGED');
    }
    return (_jsxs("div", { className: classes.selectButtonBox, children: [header && _jsx("h3", { children: header }), title && _jsxs("label", { children: [" ", title, " "] }), _jsxs("div", { className: classes.selectButtonBlock, children: [isMulti && renderAllSelectedButton(), list.map(renderButton)] }), actualError && _jsx(FormHelperText, { className: classes.errorText, children: actualError })] }));
};
export default withStyles(styles)(SelectButtons);
