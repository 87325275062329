import React from 'react';
import PropTypes from 'prop-types';
import SelectButtons from 'realtor/components/common/SelectButtons/SelectButtons';
import i18n from '../../../../../common/i18n';

export const PartialServiceTypeFilter = ({ filtersList, filter, onFilterChange }) => {
  const filterListName = 'objectPartialServiceType';
  const filterName = 'objectServiceType';
  const buttonTitlePrefix = 'FILTER_REAL_ESTATE_SERVICE_TYPE_';
  const header = `${i18n.t('PARAMS_FILTER_SERVICE_TYPE')}:`;

  const titles = Object.keys(filtersList[filterListName]);

  const realEstateTypeCollection = titles.map((title) => ({
    title: i18n.t(`${buttonTitlePrefix}${title}`),
    value: filtersList[filterListName][title],
  }));

  return (
    <SelectButtons
      list={realEstateTypeCollection}
      onSelect={onFilterChange}
      selectedValue={filter[filterName]}
      name={filterName}
      header={header}
    />
  );
};

PartialServiceTypeFilter.propTypes = {
  filtersList: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
