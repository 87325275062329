import axios from '../../../common/axios';

class TagResource {
  /**
   * Get tags list
   */
  getList(params) {
    return axios.get('/v1/realtor/tags', { params }).then((res) => res.data);
  }

  createTag(tagName) {
    return axios.post('/v1/realtor/tags', { title: tagName }).then((res) => res.data);
  }
}

export default new TagResource();
