import { connect } from 'react-redux';
import { App } from '../components/App';
import { login } from '../allAccess/actions/authorization';
import auth from '../../common/sevices/auth';
import confirmRegistrationService from '../../common/sevices/confirmRegistrationService';
import { isAppInitialized } from '../realtor/selectors/App/isAppInitialized';
import { redirectTo } from '../actions/redirect';

const mapStateToProps = (state) => {
  const { login, user, redirect } = state;

  return {
    ...login,
    isAuthorized: auth.isUserAuthorized(),
    isConfirmationTokenExist: confirmRegistrationService.isUserConfirmTokenExist(),
    isAppInitialized: isAppInitialized(state),
    inProgress: user.inProgress,
    redirectPath: redirect.path,
  };
};

const mapDispatchToProps = {
  login,
  redirectTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
