import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-s-alert/dist/s-alert-default.css';
import { Container } from '@material-ui/core';
import { useGetAppConfigurationQuery } from '../../../allAccess/services/appConfigurationService';
import style from './styles';
import RealEstateObjectContent from '../../../realtor/components/layears/RealEstateObjectContent/RealEstateObjectContent';
import { setPageTitle } from '../../../../common/helpers/pageTitle';

export const ShareObjectPage = ({ objectHash, fetchObjectByHash, realEstateObject, inProgress, classes }) => {
  const { data: appConfigurations } = useGetAppConfigurationQuery();
  useEffect(() => {
    fetchObjectByHash(objectHash);
  }, [objectHash]);

  useEffect(() => {
    if (realEstateObject && realEstateObject.props) {
      setPageTitle(realEstateObject.props.title);
    }
  }, [realEstateObject]);

  return (
    realEstateObject && (
      <div className={classes.root}>
        <Container className={classes.content}>
          <RealEstateObjectContent
            realEstateObject={realEstateObject.props}
            filters={appConfigurations.objectFilters}
            inProgress={inProgress}
            hideControlButtons
          />
        </Container>
      </div>
    )
  );
};

export default withStyles(style)(ShareObjectPage);

ShareObjectPage.propTypes = {
  objectHash: PropTypes.string.isRequired,
  fetchObjectByHash: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  realEstateObject: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
