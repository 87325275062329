import { connect } from 'react-redux';
import ConfirmRegistrationPage from '../components/pages/ConfirmRegistrationPage/ConfirmRegistrationPage';
import { confirmRegistration, dropConfirmRegistrationErrors } from '../actions/confirmRegistration';

const mapStateToProps = ({ confirmRegistration }) => {
  const { inProgress, error } = confirmRegistration;
  return {
    inProgress,
    error,
  };
};

const mapDispatchToProps = {
  onConfirmRegistration: confirmRegistration,
  onDropConfirmRegistrationErrors: dropConfirmRegistrationErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmRegistrationPage);
